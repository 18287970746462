import Avatar from 'app/components/Avatar'
import FilesPicker from 'app/components/files/FilesPicker'
import useImageUpload from 'app/components/files/useImageUpload'
import EditableField from 'app/components/forms/EditableField'
import HStack from 'app/components/layout/HStack'

export default function ProfileEditableAvatar ({ organizationId }) {
  const { uploadFile, fileLocalURL } = useImageUpload({ name: 'avatarFileId', organizationId })

  return (
    <EditableField
      label='Image'
      renderEdit={() => (
        <HStack justifyContent='center'>
          <FilesPicker onFilesSelected={uploadFile} type='image/*' style={{ alignItems: 'center' }}>
            <Avatar appearance='editable' size='giant' source={fileLocalURL} shape='rounded' />
          </FilesPicker>
        </HStack>
      )}
      renderView={() => (
        <HStack justifyContent='center'>
          <Avatar appearance='editable' size='giant' source={fileLocalURL} shape='rounded' />
        </HStack>
      )}
    />
  )
}
