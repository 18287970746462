import { useParams } from 'react-router'
import ScreenContent from 'app/components/layout/ScreenContent'
import { useItem } from 'app/api/items'
import FormSubmissionsList from './FormSubmissionsList'

export default function FormSubmissionsScreen () {
  const { itemId, organizationId } = useParams()
  const { item } = useItem(organizationId, itemId)

  if (!item) return null

  return (
    <ScreenContent
      title='Forms'
      description={item.title}
    >
      <FormSubmissionsList item={item} />
    </ScreenContent>
  )
}
