import ScreenContent from 'app/components/layout/ScreenContent'
import { TwoColumns, LeftColumn, RightColumn } from 'app/components/layout/TwoColumns'
import CreateItemForm from './create/CreateItemForm'
import { useParams, useNavigate } from 'react-router'
import { RelatedItemsEditProvider } from './related/RelatedItemsContext'
import RelatedItemsEditMode from './related/RelatedItemsEditMode'
import { useItem, useUpdate } from 'app/api/items'
import Form from 'app/components/forms/Form'
import CardFrame from 'app/components/cards/CardFrame'
import ScrollContent from 'app/components/layout/ScrollContent'
import { useNotifications } from 'app/components/notifications/NotificationsContext'
import { useTranslation } from 'react-i18next'

export default function EditItemScreen () {
  const { organizationId, itemId } = useParams()
  const { item } = useItem(organizationId, itemId)
  const update = useUpdate(organizationId)
  const { notify } = useNotifications()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onSubmit = async (values) => {
    await update(itemId, values)
    notify(t('Item updated'), t('Item #{{itemId}} was updated', { itemId: item.itemId }), 'success')
    navigate(`/org/${organizationId}`)
  }

  if (!item) {
    return null
  }

  return (
    <ScreenContent
      title='Publish'
      description={t('Edit {{type}} #{{itemId}}', { type: t(item.type), itemId: item.itemId })}
    >
      <Form defaultValues={item}>
        <RelatedItemsEditProvider name='relatedItemIds'>
          <TwoColumns
            LeftColumn={
              <LeftColumn title='Content'>
                <ScrollContent fadeTop={false}>
                  <CardFrame>
                    <CreateItemForm
                      type={item.type}
                      onSubmit={onSubmit}
                      organizationId={organizationId}
                      submitLabel={t('Update')}
                    />
                  </CardFrame>
                </ScrollContent>
              </LeftColumn>
            }
            RightColumn={
              <RightColumn title='Related'>
                <RelatedItemsEditMode organizationId={organizationId} />
              </RightColumn>
            }
          />
        </RelatedItemsEditProvider>
      </Form>
    </ScreenContent>
  )
}
