import Text from 'app/i18n/Text'
import { useMember } from 'app/api/members'
import EditableField from 'app/components/forms/EditableField'
import { EditableSection } from 'app/components/forms/EditableSection'
import Form from 'app/components/forms/Form'
import HStack from 'app/components/layout/HStack'
import NotifySelect from './NotifySelect'
import EditableSectionHeader from 'app/components/forms/EditableSectionHeader'

export default function NotificationsConfigSection ({ organizationId, userId }) {
  const { member, update } = useMember(organizationId, userId)

  if (!member) return null

  return (
    <EditableSection>
      <Form defaultValues={member}>
        <EditableSectionHeader
          title='Notifications'
          onSave={member => update(member)}
        />
        <HStack>
          <EditableField
            label='Email'
            renderView={() => <Text>{member.notifyEmail}</Text>}
            renderEdit={(() => <NotifySelect name='notifyEmail' />)}
          />
          <EditableField
            label='Browser'
            renderView={() => <Text>{member.notifyBrowser}</Text>}
            renderEdit={(() => <NotifySelect name='notifyBrowser' />)}
          />
        </HStack>
      </Form>
    </EditableSection>
  )
}
