import { Navigate } from 'react-router'
import { useCurrentOrganization } from 'app/api/users'
import { useIsMobile } from './mediaQueries'
import AppMobileLayout from './OrganizationMobileLayout'
import AppWebLayout from './OrganizationWebLayout'
import VerifyUserSetup from 'app/components/VerifyUserSetup'
import CheckDeviceNotifications from 'app/modules/members/components/CheckDeviceNotifications'
import VerifyInvitationAccepted from 'app/modules/members/invitations/VerifyInvitationAccepted'
import Loader from 'app/components/Loader'

export default function OrganizationLayout () {
  const isMobile = useIsMobile()
  const { organization } = useCurrentOrganization()

  if (organization === undefined) return <Loader />

  if (!organization) {
    return <Navigate replace to='/select-organization' />
  }

  return (
    <VerifyUserSetup>
      <VerifyInvitationAccepted>
        <CheckDeviceNotifications />
        {isMobile ? <AppMobileLayout /> : <AppWebLayout />}
      </VerifyInvitationAccepted>
    </ VerifyUserSetup>
  )
}
