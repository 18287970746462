import { useStyleSheet } from '@ui-kitten/components'
import { StyleSheet, View } from 'react-native'

const themedStyles = StyleSheet.create({
  container: {
    height: 250,
    backgroundColor: 'color-basic-200',
    borderColor: 'color-basic-300',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: 12,
    borderWidth: 1,
    overflow: 'hidden'
  }
})

export default function CoverContainer ({ children }) {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      {children}
    </View>
  )
}
