const { StyleSheet, View } = require('react-native')

const styles = StyleSheet.create({
  stack: {
    flexDirection: 'column',
    flexShrink: 1,
    flexGrow: 1
  }
})

export default function Stack ({ children, minWidth, maxWidth, paddingHorizontal, paddingVertical, gap = 12, alignItems }) {
  return (
    <View style={[styles.stack, { minWidth, maxWidth, paddingHorizontal, paddingVertical, gap, alignItems }]}>
      {children}
    </View>
  )
}
