import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import CardPressable from 'app/components/cards/CardPressable'
import CardHeader from 'app/components/cards/CardHeader'
import ItemMenu from 'app/modules/items/components/ItemMenu'
import EventCardContent from './EventCardContent'
import CardSummary from 'app/modules/items/components/CardSummary'
import EventStatusLabel from './EventStatusLabel'
import ItemMenuLink from 'app/modules/items/components/ItemMenuLink'

export default function EventCard ({ item }) {
  const [mouseOver, setMouseOver] = useState(false)
  const navigate = useNavigate()

  return (
    <CardPressable
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onPress={() => navigate(`/org/${item.organizationId}/items/${item.itemId}`)}
    >
      <CardHeader
        showButtonMenu={mouseOver}
        menu={<ItemMenu
          item={item}
          options={(
            <ItemMenuLink
              to={`/org/${item.organizationId}/events/${item.itemId}/attendance`}
              title='Attendance'
              icon='people-outline'
            />
          )}
        />}
      >
        <CardSummary item={item} statusLabel={<EventStatusLabel isDone={item.content.attending} />} />
      </CardHeader>
      <EventCardContent organizationId={item.organizationId} item={item} />
    </CardPressable>
  )
}
