import { StyleSheet, View } from 'react-native'
import DateTimeBox from 'app/modules/items/events/DateTimeBox'
import { Text, useStyleSheet } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'
import CardButtons from 'app/components/cards/CardButtons'
import ButtonAsync from 'app/components/buttons/ButtonAsync'
import { useEventAttendance } from 'app/api/events'
import { useState } from 'react'
import Icon from 'app/components/Icon'
import ImageUploaded from 'app/components/ImageUploaded'
import { useIsMobile } from 'app/components/layout/mediaQueries'
import { useTranslation } from 'react-i18next'

const themedStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 26,
    flexGrow: 1
  },
  containerMobile: {
    flexDirection: 'column'
  },
  place: {
    flexDirection: 'column',
    gap: 24,
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: 'color-basic-200',
    flexGrow: 1,
    minHeight: 118
  },
  mapImage: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'color-basic-400'
  },
  placeText: {
    color: 'color-basic-600'
  },
  pin: {
    fill: 'color-basic-600'
  },
  startEnd: {
    flexDirection: 'column',
    gap: 4,
    alignItems: 'center'
  },
  startEndMobile: {
    flexDirection: 'row',
    width: '100%'
  }
})

export default function EventContent ({ item: { content, id, organizationId } }) {
  const styles = useStyleSheet(themedStyles)
  const [isAttending, setIsAttending] = useState(content.attending)
  const attending = useEventAttendance(organizationId, id)
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  const buttonSize = isMobile ? 'small' : 'medium'

  const select = async (attendance) => {
    await attending(attendance)
    setIsAttending(attendance)
  }

  return (
    <Stack gap={18}>
      <View style={[styles.container, isMobile && styles.containerMobile]}>
        <View style={[styles.startEnd]}>
          <DateTimeBox date={new Date(content.start)} label='Start' />
          {content.end && (
            <>
              <Icon name='arrow-downward-outline' size={24} />
              <DateTimeBox date={new Date(content.end)} label='End' />
            </>
          )}
        </View>
        <View style={styles.place}>
          {content.mapFileId
            ? (
              <ImageUploaded
                organizationId={organizationId}
                fileId={content.mapFileId}
                style={styles.mapImage}
              />
              )
            : (
              <Text category='s1' style={styles.placeText}>{content.location}</Text>
              )}
        </View>
      </View>
      <CardButtons>
        <ButtonAsync
          status={isAttending === false ? 'info' : 'primary'}
          onPress={() => select(false)}
          accessoryRight={props => <Icon name='close' {...props} />}
          size={buttonSize}
        >
          {t('I\'m not going')}
        </ButtonAsync>
        <ButtonAsync
          status={isAttending === true ? 'info' : 'primary'}
          onPress={() => select(true)}
          accessoryRight={props => <Icon name='checkmark' {...props} />}
          size={buttonSize}
        >
          {t('I\'m going')}
        </ButtonAsync>
      </CardButtons>
    </Stack >
  )
}
