import { useParams } from 'react-router'
import ScreenContent from 'app/components/layout/ScreenContent'
import { useItem } from 'app/api/items'
import EventAttendanceList from './EventAttendanceList'

export default function EventAttendanceScreen () {
  const { itemId, organizationId } = useParams()
  const { item } = useItem(organizationId, itemId)

  if (!item) return null

  return (
    <ScreenContent
      title='Events'
      description={item.title}
    >
      <EventAttendanceList item={item} />
    </ScreenContent>
  )
}
