import { Divider, Text, useStyleSheet } from '@ui-kitten/components'
import { StyleSheet, View } from 'react-native'
import CardContent from './CardContent'

const themedStyles = StyleSheet.create({
  container: {
    backgroundColor: 'color-basic-200',
    borderColor: 'color-basic-400',
    borderWidth: 1,
    borderRadius: 4,
    overflow: 'hidden'
  }
})

export default function CardInner ({ children, title }) {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      <CardContent>
        <Text category='s1'>{title}</Text>
      </CardContent>
      <Divider />
      <CardContent>
        {children}
      </CardContent>
    </View>
  )
}
