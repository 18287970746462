import { ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg'

export default function FormatHeadingIcon ({ size = 24, color = '#fff' }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <G clipPath="url(#clip0_1397_10505)">
        <Path d="M9 4V7H14V19H17V7H22V4H9ZM3 12H6V19H9V12H12V9H3V12Z" fill={color} />
      </G>
      <Defs>
        <ClipPath id="clip0_1397_10505">
          <Rect width="24" height="24" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
