import { Input } from '@ui-kitten/components'
import { useRef } from 'react'
import { StyleSheet, View } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 10,
    display: 'flex'
  },
  inputContainer: {
    minWidth: 0,
    flexShrink: 1
  },
  input: {
    minWidth: 0,
    textAlign: 'center'
  }
})

export default function PinInput ({ value, onChange, status }) {
  const itemRefs = Array(value.length).fill(null).map(() => useRef(null))

  const onNumberChange = (index, digit) => {
    if (!digit.match(/\d/)) return

    const updatedPin = [...value]
    updatedPin[index] = digit || ' '
    onChange(updatedPin.join(''))
    itemRefs[index + 1]?.current?.focus()
  }

  return (
    <View style={styles.container}>
      {[...value].map((digit, index) => (
        <Input
          ref={itemRefs[index]}
          style={styles.inputContainer}
          textStyle={styles.input}
          key={index}
          onKeyPress={e => onNumberChange(index, e.nativeEvent.key)}
          value={digit !== ' ' ? digit : ''}
          status={status}
        />
      ))}
    </View>
  )
}
