import React from 'react'
import { StyleSheet, View, Image } from 'react-native'
import { useStyleSheet } from '@ui-kitten/components'
import CardContent from 'app/components/cards/CardContent'
import FilesPicker from 'app/components/files/FilesPicker'
import ButtonRemove from 'app/components/buttons/ButtonRemove'
import UploadArea from 'app/components/files/UploadArea'
import useImageUpload from './useImageUpload'

const themedStyles = StyleSheet.create({
  container: {
    backgroundColor: 'color-basic-200',
    flexGrow: 1,
    width: '100%',
    height: '100%'
  },
  fileUploadArea: {
    flexDirection: 'row',
    flexGrow: 1
  },
  icon: {
    fill: 'color-basic-600'
  },
  text: {
    color: 'color-basic-600'
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  image: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  removeButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 1
  }
})

export default function ImageEdit ({ name, organizationId }) {
  const styles = useStyleSheet(themedStyles)
  const { fileLocalURL, uploadFile, removeFile } = useImageUpload({ name, organizationId })

  return (
    <View style={styles.container}>
      {fileLocalURL
        ? (
          <View style={styles.imageContainer}>
            <Image
              source={{ uri: fileLocalURL }}
              style={styles.image}
            />
            <ButtonRemove onPress={removeFile} style={styles.removeButton} />
          </View>
          )
        : (
          <CardContent>
            <View style={styles.fileUploadArea}>
              <FilesPicker onFilesSelected={uploadFile} >
                <UploadArea text='Drop or click here to upload an image' />
              </FilesPicker>
            </View>
          </CardContent>
          )}
    </View>
  )
}
