import { Pressable, StyleSheet } from 'react-native'
import { useStyleSheet } from '@ui-kitten/components'
import { cloneElement, useState } from 'react'

const themedStyles = StyleSheet.create({
  button: {
    borderRadius: 50,
    width: 'auto'
  },
  buttonHover: {
    backgroundColor: 'color-basic-300'
  },
  icon: {
    fill: 'color-basic-600'
  },
  iconHover: {
    fill: 'color-info-500'
  }
})

export default function ButtonCircle ({ onPress, icon }) {
  const styles = useStyleSheet(themedStyles)
  const [hover, setHover] = useState(false)

  return (
    <Pressable
      onPress={onPress}
      style={[styles.button, hover && styles.buttonHover]}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      {cloneElement(icon, { fill: hover ? styles.iconHover.fill : styles.icon.fill })}
    </Pressable>
  )
}
