import InfiniteList from 'app/components/InfiniteList'
import { useSearch } from 'app/api/items'
import ItemResultCard from './ItemResultCard'

export default function ItemsInfiniteSearch ({ organizationId, query }) {
  const { isLoading, setSize, items, mutate, size } = useSearch(organizationId, query)

  return (
    <InfiniteList
      isLoading={isLoading}
      setSize={setSize}
      mutate={mutate}
      pages={items}
      size={size}
      renderItem={item => <ItemResultCard item={item} />}
    />
  )
}
