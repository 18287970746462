import { useFileLocalUrl } from 'app/api/files'
import Avatar from 'app/components/Avatar'

export default function MemberAvatar ({ member, size = 'medium' }) {
  const avatarLocalURL = useFileLocalUrl(member.organizationId, member.avatarFileId)

  return (
    <Avatar source={avatarLocalURL} size={size} />
  )
}
