import { Text as TextBase } from '@ui-kitten/components'
import { Trans } from 'react-i18next'

export default function Text ({ children, ...props }) {
  return (
    <TextBase {...props}>
      <Trans>
        {children}
      </Trans>
    </TextBase>
  )
}
