import React from 'react'
import { Text, useStyleSheet } from '@ui-kitten/components'
import { StyleSheet } from 'react-native'
import CardImage from 'app/components/cards/CardImage'
import useLocalDateFormat from 'app/i18n/useLocalDateFormat'
import { useTranslation } from 'react-i18next'

const themedStyles = StyleSheet.create({
  text: {
    color: 'color-primary-500',
    textTransform: 'uppercase'
  }
})

export default function EventCardContent ({ item }) {
  const styles = useStyleSheet(themedStyles)
  const date = new Date(item.content.start)
  const format = useLocalDateFormat()
  const { t } = useTranslation()

  return (
    <CardImage item={item}>
      <Text category="c1" appearance='hint'>{t('Created by')}  {item.createdBy.user.name} {item.createdBy.user.surname}</Text>
      <Text category="s2" style={styles.text} >
        {format(date, 'EEEE, MMMM d yyyy')} {format(date, 'hh:mm a')}
      </Text>
    </CardImage>
  )
}
