import NotificationsList from './NotificationsList'
import { createContext, useContext, useState } from 'react'

const NotificationsContext = createContext({
  notifications: [],
  notify: (title, description, type) => { }
})

export function NotificationsProvider ({ children }) {
  const [notifications, setNotifications] = useState({})

  const notify = (title, description, type = 'success') => {
    const key = Date.now()
    setNotifications({
      [key]: { title, description, type },
      ...notifications
    })
    setTimeout(() => {
      setNotifications(notifications => {
        delete notifications[key]
        return { ...notifications }
      })
    }, 5000)
  }

  return (
    <NotificationsContext.Provider value={{
      notifications,
      notify
    }}>
      <NotificationsList notifications={notifications} />
      {children}
    </NotificationsContext.Provider>
  )
}

export function useNotifications () {
  return useContext(NotificationsContext)
}
