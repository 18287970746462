import { StyleSheet, View } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    padding: 24,
    gap: 5
  }
})

export default function CardContent ({ children }) {
  return (
    <View style={styles.container}>
      {children}
    </View>
  )
}
