import { Radio, RadioGroup } from '@ui-kitten/components'
import { Controller, useFormContext } from 'react-hook-form'

export default function RadioInput ({ name, defaultValue, options, rules, ...props }) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error }
      }) => (
        <RadioGroup
          onChange={index => onChange(options[index])}
          selectedIndex={options.indexOf(value)}
        >
          {options.map((option, i) => (
            <Radio
              key={i}
              status={error ? 'danger' : 'basic'}
              {...props}
            >
              {option}
            </Radio>
          ))}
        </RadioGroup>
      )}
    />
  )
}
