import ScreenContent from 'app/components/layout/ScreenContent'
import FeedList from 'app/modules/items/feed/FeedList'
import { useParams } from 'react-router-native'
import ArticleCard from 'app/modules/items/articles/ArticleCard'

export default function ArticlesScreen () {
  const { organizationId } = useParams()

  return (
    <ScreenContent title='Articles' description='All articles'>
      <FeedList
        organizationId={organizationId}
        section='article'
        renderItem={item => <ArticleCard item={item} />}
      />
    </ScreenContent>
  )
}
