import MessageCard from 'app/components/cards/MessageCard'
import { StyleSheet, View } from 'react-native'
import { useTransition } from 'app/components/animations/spring'
import AnimatedView from 'app/components/animations/AnimatedView'
import { useIsMobile } from 'app/components/layout/mediaQueries'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'stretch',
    position: 'absolute',
    right: 16,
    bottom: 16,
    minWidth: 300,
    zIndex: 1000
  },
  containerMobile: {
    left: 16,
    top: 60,
    bottom: 'auto'
  }
})

export default function NotificationsList ({ notifications }) {
  const isMobile = useIsMobile()
  const transitions = useTransition(Object.keys(notifications), {
    from: {
      opacity: 0,
      top: -10
    },
    enter: {
      opacity: 1,
      top: 0,
      marginBottom: 8
    },
    leave: {
      opacity: 0,
      height: 0,
      marginBottom: 0,
      top: 20
    }
  })

  return (
    <View style={[styles.container, isMobile && styles.containerMobile]}>
      {transitions((style, key) => {
        const { title, description, type } = notifications[key] || {}

        return (
          <AnimatedView key={key} style={style}>
            <MessageCard
              title={title}
              description={description}
              type={type}
            />
          </AnimatedView>
        )
      })}
    </View>
  )
}
