import { useStyleSheet } from '@ui-kitten/components'
import { Linking, Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import Logo from 'app/components/images/Logo'
import CircleRetro from 'app/components/images/CircleRetro'
import { useIsMobile } from './mediaQueries'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Outlet } from 'react-router-native'
import { websiteUrl } from 'app/config'

const themedStyles = StyleSheet.create({
  screen: {
    backgroundColor: 'color-primary-100',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    maxWidth: Platform.OS === 'web' ? '100vw' : '100%'
  },
  container: {
    flexDirection: 'row',
    alignItems: 'stretch',
    alignContent: 'center',
    flexGrow: 1,
    maxWidth: '100%'
  },
  left: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'color-basic-200',
    paddingHorizontal: 30,
    paddingVertical: 30,
    flexBasis: 400
  },
  leftMobile: {
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    overflow: 'scroll'
  },
  content: {
    maxWidth: 400,
    width: '100%',
    flexShrink: 1
  },
  contentMobile: {
  },
  right: {
    backgroundColor: 'color-primary-100',
    flexGrow: 1,
    maxWidth: 720,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  rightMobile: {
    height: 200,
    flexGrow: 0
  },
  bigImage: {
    position: 'absolute'
  },
  logo: {
    position: 'absolute',
    bottom: 40,
    right: 40,
    flexGrow: 1
  },
  logoMobile: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    right: '-25%',
    width: '100%',
    height: '100%'
  }
})

export default function SplitScreenLayout () {
  const styles = useStyleSheet(themedStyles)
  const isMobile = useIsMobile()

  return (
    <View style={styles.screen}>
      <SafeAreaView style={[styles.container, { flexDirection: isMobile ? 'column-reverse' : 'row' }]}>
        <View style={[styles.left, isMobile && styles.leftMobile]}>
          <View style={styles.content}>
            <Outlet />
          </View>
        </View>
        <View style={[styles.right]}>
          <View style={styles.bigImage}>
            <CircleRetro />
          </View>
          <View style={[styles.logo, isMobile && styles.logoMobile]}>
            <TouchableOpacity
              onPress={() => Linking.openURL(websiteUrl)}
            >
              <Logo width={isMobile ? 100 : 190} />
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    </View>
  )
}
