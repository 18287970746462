import { StyleSheet, View } from 'react-native'
import TextInput from 'app/components/forms/TextInput'
import { theme } from 'app/theme'
import { Button } from '@ui-kitten/components'
import ButtonRemove from 'app/components/buttons/ButtonRemove'
import FormFieldTypeText from './FormFieldTypeText'
import FormFieldTypeSelectEdit from './FormFieldTypeSelectEdit'
import FilesEdit from 'app/components/files/FilesEdit'
import Icon from 'app/components/Icon'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    gap: 4,
    backgroundColor: theme['color-basic-100']
  },
  header: {
    flexDirection: 'row',
    gap: 2,
    flexGrow: 1,
    alignItems: 'center'
  },
  summary: {
    flexDirection: 'column',
    flexGrow: 1
  },
  actions: {
    flexDirection: 'row',
    gap: 2,
    alignItems: 'center'
  },
  titleText: {
    fontSize: 16,
    fontWeight: 'bold',
    marginHorizontal: -6
  },
  title: {
    padding: 0,
    borderWidth: 0,
    marginHorizontal: -2
  },
  description: {
    borderWidth: 0,
    marginHorizontal: -2,
    padding: 0
  },
  descriptionText: {
    fontSize: 14,
    padding: 0,
    marginHorizontal: -6,
    color: theme['color-basic-700']
  }
})

function FormFieldContent ({ field, index, organizationId }) {
  switch (field.type) {
    case 'text':
      return (
        <FormFieldTypeText
          name={`content.fields.${index}.placeholder`}
          validation={field.validation}
          disabled
        />
      )
    case 'select':
      return (
        <FormFieldTypeSelectEdit
          name={`content.fields.${index}.options`}
          maxSelections={field.maxSelections}
        />
      )
    case 'file':
      return (
        <FilesEdit
          organizationId={organizationId}
          name={`content.fields.${index}.fileId`}
          disabled
        />
      )
  }
}

export default function FormFieldEdit ({ total, index, onRemove, onMoveUp, onMoveDown, field }) {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.summary}>
          <TextInput
            textStyle={styles.titleText}
            style={styles.title}
            name={`content.fields.${index}.label`}
            placeholder='A label'
            size='small'
            rules={{ required: t('Field #{{index}} label is required', { index: index + 1 }) }}
          />
          <TextInput
            name={`content.fields.${index}.description`}
            placeholder='Write a short description about this field'
            rules={{ required: t('Field #{{index}} description is required', { index: index + 1 }) }}
            size='small'
            textStyle={styles.descriptionText}
            style={styles.description}
          />
        </View>
        {index > 0 && (
          <Button
            appearance='ghost'
            status='basic'
            size='small'
            onPress={onMoveUp}
            accessoryLeft={(props) => <Icon {...props} name='arrow-upward-outline' />}
          />
        )}
        {index < total - 1 && (
          <Button
            appearance='ghost'
            status='basic'
            size='small'
            onPress={onMoveDown}
            accessoryLeft={(props) => <Icon {...props} name='arrow-downward-outline' />}
          />
        )}
        <ButtonRemove onPress={onRemove} size={18} />
      </View>
      <FormFieldContent field={field} index={index} />
    </View >
  )
}
