import { Button, useStyleSheet } from '@ui-kitten/components'
import { StyleSheet, View } from 'react-native'
import Stack from 'app/components/layout/Stack'
import FormItem from 'app/components/forms/FormItem'
import Form from 'app/components/forms/Form'
import { useMemberInvite } from 'app/api/members'
import ButtonSubmit from 'app/components/forms/ButtonSubmit'
import TextInputMultiple from 'app/components/forms/TextInputMultiple'
import { REGEX_EMAIL_VALIDATION } from 'app/validations'
import Modal from 'app/components/Modal'
import { useNotifications } from 'app/components/notifications/NotificationsContext'
import { useTranslation } from 'react-i18next'
import RoleSelect from 'app/modules/members/components/RoleSelect'

const themedStyles = StyleSheet.create({
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 10,
    marginTop: 20
  },
  roles: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10
  }
})

export default function InviteMembersModal ({ onDismiss, organizationId }) {
  const styles = useStyleSheet(themedStyles)
  const { i18n } = useTranslation()
  const invite = useMemberInvite(organizationId, i18n.language)
  const { notify } = useNotifications()
  const { t } = useTranslation()

  const onSubmit = async ({ emails, role }) => {
    await invite(emails.filter(email => email), role)
    notify(t('Invitations sent'), t('Your invitations have been sent successfully'), 'success')
    onDismiss()
  }

  return (
    <Modal
      onDismiss={onDismiss}
      title={t('Invite new members to your Circl')}
      description={t('Write one or more emails below to invite people to this organization')}
    >
      <Stack>
        <Form defaultValues={{ emails: [], role: 'member' }}>
          <FormItem label='Role'>
            <View style={styles.roles}>
              <RoleSelect />
            </View>
          </FormItem>
          <Stack>
            <FormItem label='Email(s)'>
              <TextInputMultiple
                max={5}
                name='emails'
                placeholder='user@email.com'
                rules={{ required: true, pattern: REGEX_EMAIL_VALIDATION }}
              />
            </FormItem>
            <View style={styles.buttons}>
              <Button status='basic' onPress={onDismiss}>{t('Cancel')}</Button>
              <ButtonSubmit status='primary' onSubmit={onSubmit}>{t('Invite')}</ButtonSubmit>
            </View>
          </Stack>
        </Form>
      </Stack >
    </Modal >
  )
}
