import { StyleSheet, View } from 'react-native'
import LottieView from 'lottie-react-native'

const styles = StyleSheet.create({
  container: {
    gap: 12,
    alignItems: 'center',
    minHeight: 200,
    justifyContent: 'center'
  }
})

export default function Loader () {
  return (
    <View style={styles.container}>
      <View style={{ width: '35%', height: '35%' }}>
        <LottieView
          source={require('../components/animations/loader.json')}
          autoPlay
          loop
        />
      </View>
    </View>
  )
}
