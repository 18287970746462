import { useUserProfile } from 'app/api/users'
import { useNavigate, useParams } from 'react-router'
import { useMember } from 'app/api/members'
import MemberAvatar from 'app/modules/members/components/MemberAvatar'
import { Pressable } from 'react-native'

export default function CurrentMemberInfo () {
  const { organizationId } = useParams()
  const { user } = useUserProfile()
  const { member } = useMember(organizationId, 'me')
  const navigate = useNavigate()

  if (!user || !member) return null

  return (
    <Pressable
      onPress={() => navigate(`/org/${organizationId}/profile`)}
    >
      <MemberAvatar member={member} />
    </Pressable>
  )
}
