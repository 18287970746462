import VerifyUserSetup from 'app/components/VerifyUserSetup'
import SplitScreenLayout from './SplitScreenLayout'

export default function SignedInLayout () {
  return (
    <VerifyUserSetup>
      <SplitScreenLayout />
    </ VerifyUserSetup>
  )
}
