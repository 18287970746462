import { createContext, useState, useContext } from 'react'
import { useFormContext } from 'react-hook-form'

const RelatedItemsContext = createContext({
  relatedItemIds: [],
  addRelatedItemId: () => { },
  removeRelatedItemId: () => { }
})

export function RelatedItemsEditProvider ({ children, name }) {
  const [relatedItemIds, setRelatedItemIds] = useState([])
  const { setValue } = useFormContext()

  const addRelatedItemId = id => {
    setRelatedItemIds(relatedItemIds => {
      const uniqueIds = new Set(relatedItemIds)
      uniqueIds.add(Number(id))
      const updatedIds = [...uniqueIds]
      setValue(name, updatedIds)
      return updatedIds
    })
  }

  const removeRelatedItemId = id => {
    setRelatedItemIds(relatedItemIds => {
      const uniqueIds = new Set(relatedItemIds)
      uniqueIds.delete(Number(id))
      const updatedIds = [...uniqueIds]
      setValue(name, updatedIds)
      return updatedIds
    })
  }

  return (
    <RelatedItemsContext.Provider value={{ relatedItemIds, addRelatedItemId, removeRelatedItemId }}>
      {children}
    </RelatedItemsContext.Provider>
  )
}

export function useRelatedItemsEdit () {
  return useContext(RelatedItemsContext)
}
