import { Button, ProgressBar, Text } from '@ui-kitten/components'
import { useOrganization } from 'app/api/organizations'
import HStack from 'app/components/layout/HStack'
import Stack from 'app/components/layout/Stack'
import Icon from 'app/components/Icon'
import { openURL } from 'expo-linking'
import { useIsMobile } from 'app/components/layout/mediaQueries'
import { useTranslation } from 'react-i18next'

const bytesToMb = bytes => Math.round(Number(BigInt(bytes) / BigInt(1024) / BigInt(1024)))

export default function OrganizationPlanUsage ({ organizationId }) {
  const { organization } = useOrganization(organizationId)
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  if (!organization) return null

  return (
    <Stack gap={12}>
      <HStack justifyContent='space-between' alignItems='center'>
        <Text category='h6'>{t('Plan Usage')}</Text>
        <Button
          status='basic'
          accessoryRight={props => <Icon name='diagonal-arrow-right-up-outline' {...props} />}
          appearance='outline'
          size={isMobile ? 'tiny' : 'small'}
          onPress={() => openURL('https://incircl.app')}
        >
          {t('Upgrade')}
        </Button>
      </HStack>
      <Stack gap={24}>
        <Stack>
          <HStack>
            <Text category='s2'>{organization.totalMembers}</Text>
            <Text category='s2' appearance='hint'>/</Text>
            <Text category='s2'>{organization.maxMembers}</Text>
            <Text category='s2' appearance='hint'>{t('members')}</Text>
          </HStack>
          <ProgressBar progress={organization.totalMembers / organization.maxMembers} />
        </Stack>
        <Stack>
          <HStack>
            <Text category='s2'>{bytesToMb(organization.usedStorage)}</Text>
            <Text category='s2' appearance='hint'>/</Text>
            <Text category='s2'>{bytesToMb(organization.maxStorage)}</Text>
            <Text category='s2' appearance='hint'>{t('MB storage')}</Text>
          </HStack>
          <ProgressBar progress={BigInt(organization.usedStorage) / BigInt(organization.maxStorage)} />
        </Stack>
      </Stack>
    </Stack>
  )
}
