import ScreenContent from 'app/components/layout/ScreenContent'
import { LeftColumn, RightColumn, TwoColumns } from 'app/components/layout/TwoColumns'
import FeedList from 'app/modules/items/feed/FeedList'
import { useParams } from 'react-router-native'
import FeedItemCard from 'app/modules/items/feed/FeedItemCard'
import RelatedList from 'app/modules/items/related/RelatedList'
import { useCurrentOrganization, useUserProfile } from 'app/api/users'
import { useTranslation } from 'react-i18next'
import Loader from 'app/components/Loader'

export default function FeedScreen () {
  const { organizationId } = useParams()
  const { organization } = useCurrentOrganization()
  const { user } = useUserProfile()
  const { t } = useTranslation()

  if (!organization) {
    return <Loader />
  }

  return (
    <ScreenContent
      title={t('Welcome {{userName}} 👋', { userName: user?.name || '' })}
      description={t('Latest updates from {{name}}', { name: organization?.name || '' })}
    >
      <TwoColumns
        LeftColumn={
          <LeftColumn
            title='Latest'
          >
            <FeedList
              organizationId={organizationId}
              renderItem={item => <FeedItemCard item={item} />}
            />
          </LeftColumn>
        }
        RightColumn={
          <RightColumn
            title='Pinned items'
          >
            <RelatedList
              organizationId={organizationId}
              filter={{ pinned: true }}
            />
          </RightColumn>
        }
      />
    </ScreenContent>
  )
}
