import { SessionProvider } from 'app/components/SessionContext'
import SWRProvider from 'app/components/SWRProvider'
import Container from 'app/components/layout/Container'
import AppRoutes from 'app/AppRoutes'
import { Router } from 'app/multiplatform/router'
import { SafeAreaProvider } from 'react-native-safe-area-context'

export default function App () {
  return (
    <SafeAreaProvider>
      <SessionProvider>
        <SWRProvider>
          <Container>
            <Router>
              <AppRoutes />
            </Router>
          </Container>
        </SWRProvider>
      </SessionProvider>
    </SafeAreaProvider>
  )
}
