import React from 'react'
import ImageUploaded from 'app/components/ImageUploaded'
import Stack from 'app/components/layout/Stack'
import { StyleSheet, View } from 'react-native'
import { useIsMobile } from 'app/components/layout/mediaQueries'

const styles = StyleSheet.create({
  image: {
    borderRadius: 4,
    width: '230px',
    height: '160px',
    marginRight: 16
  },
  imageMobile: {
    width: '100%',
    marginBottom: 16
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  containerMobile: {
    flexDirection: 'column'
  }
})

export default function CardImage ({ item, children }) {
  const isMobile = useIsMobile()

  return (
    <View style={[styles.container, isMobile && styles.containerMobile]}>
      {item.content.image && (
        <ImageUploaded
          organizationId={item.organizationId}
          fileId={item.content.image}
          style={[styles.image, isMobile && styles.imageMobile]} />
      )}
      <Stack gap={5}>
        {children}
      </Stack>
    </View>
  )
}
