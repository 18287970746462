import { Button } from '@ui-kitten/components'
import { useTranslation } from 'react-i18next'

export default function ButtonPill ({ children, ...props }) {
  const { t } = useTranslation()

  return (
    <Button style={{ borderRadius: 25 }} {...props}>
      {t(children)}
    </Button>
  )
}
