import { G, Mask, Path, Svg } from 'react-native-svg'

export default function CircleRetro () {
  return (
    <Svg width="720" height="1024" viewBox="0 0 720 1024" fill="none" xmlns="http://www.w3.org/2000/Svg">
      <Mask id="Path-1-inside-1_1115_7768" fill="white">
        <Path d="M501 -26.9999C412.361 -26.9999 325.09 -5.13966 246.917 36.6445C168.744 78.4287 102.083 138.847 52.8378 212.548C3.59261 286.248 -26.7164 370.957 -35.4046 459.169C-44.0927 547.381 -30.8918 636.375 3.0289 718.266C36.9496 800.158 90.5431 872.421 159.062 928.653C227.581 984.885 308.91 1023.35 395.846 1040.64C482.782 1057.94 572.641 1053.52 657.463 1027.79C742.286 1002.06 819.453 955.808 882.13 893.131L653.452 664.452C628.381 689.523 597.514 708.024 563.585 718.316C529.656 728.609 493.713 730.374 458.938 723.457C424.164 716.54 391.632 701.154 364.225 678.661C336.817 656.168 315.38 627.263 301.812 594.507C288.243 561.75 282.963 526.152 286.438 490.868C289.913 455.583 302.037 421.699 321.735 392.219C341.433 362.739 368.098 338.572 399.367 321.858C430.636 305.144 465.544 296.4 501 296.4L501 -26.9999Z" />
      </Mask>
      <Path d="M501 -26.9999C412.361 -26.9999 325.09 -5.13966 246.917 36.6445C168.744 78.4287 102.083 138.847 52.8378 212.548C3.59261 286.248 -26.7164 370.957 -35.4046 459.169C-44.0927 547.381 -30.8918 636.375 3.0289 718.266C36.9496 800.158 90.5431 872.421 159.062 928.653C227.581 984.885 308.91 1023.35 395.846 1040.64C482.782 1057.94 572.641 1053.52 657.463 1027.79C742.286 1002.06 819.453 955.808 882.13 893.131L653.452 664.452C628.381 689.523 597.514 708.024 563.585 718.316C529.656 728.609 493.713 730.374 458.938 723.457C424.164 716.54 391.632 701.154 364.225 678.661C336.817 656.168 315.38 627.263 301.812 594.507C288.243 561.75 282.963 526.152 286.438 490.868C289.913 455.583 302.037 421.699 321.735 392.219C341.433 362.739 368.098 338.572 399.367 321.858C430.636 305.144 465.544 296.4 501 296.4L501 -26.9999Z" stroke="#222B45" strokeWidth="4" mask="url(#Path-1-inside-1_1115_7768)" />
      <Path d="M501 394.42C565.938 394.42 618.58 447.062 618.58 512C618.58 576.938 565.938 629.58 501 629.58C436.063 629.58 383.42 576.938 383.42 512C383.42 447.062 436.063 394.42 501 394.42Z" stroke="#222B45" strokeWidth="2" />
      <G opacity="0.9">
        <Mask id="Path-3-inside-2_1115_7768" fill="white">
          <Path d="M491 -26.9999C402.361 -26.9999 315.09 -5.13966 236.917 36.6445C158.744 78.4287 92.0831 138.847 42.8378 212.548C-6.40739 286.248 -36.7164 370.957 -45.4046 459.169C-54.0927 547.381 -40.8918 636.375 -6.9711 718.266C26.9496 800.158 80.5431 872.421 149.062 928.653C217.581 984.885 298.91 1023.35 385.846 1040.64C472.782 1057.94 562.641 1053.52 647.463 1027.79C732.286 1002.06 809.453 955.808 872.13 893.131L643.452 664.452C618.381 689.523 587.514 708.024 553.585 718.316C519.656 728.609 483.713 730.374 448.938 723.457C414.164 716.54 381.632 701.154 354.225 678.661C326.817 656.168 305.38 627.263 291.812 594.507C278.243 561.75 272.963 526.152 276.438 490.868C279.913 455.583 292.037 421.699 311.735 392.219C331.433 362.739 358.098 338.572 389.367 321.858C420.636 305.144 455.544 296.4 491 296.4L491 -26.9999Z" />
        </Mask>
        <Path d="M491 -26.9999C402.361 -26.9999 315.09 -5.13966 236.917 36.6445C158.744 78.4287 92.0831 138.847 42.8378 212.548C-6.40739 286.248 -36.7164 370.957 -45.4046 459.169C-54.0927 547.381 -40.8918 636.375 -6.9711 718.266C26.9496 800.158 80.5431 872.421 149.062 928.653C217.581 984.885 298.91 1023.35 385.846 1040.64C472.782 1057.94 562.641 1053.52 647.463 1027.79C732.286 1002.06 809.453 955.808 872.13 893.131L643.452 664.452C618.381 689.523 587.514 708.024 553.585 718.316C519.656 728.609 483.713 730.374 448.938 723.457C414.164 716.54 381.632 701.154 354.225 678.661C326.817 656.168 305.38 627.263 291.812 594.507C278.243 561.75 272.963 526.152 276.438 490.868C279.913 455.583 292.037 421.699 311.735 392.219C331.433 362.739 358.098 338.572 389.367 321.858C420.636 305.144 455.544 296.4 491 296.4L491 -26.9999Z" stroke="#222B45" strokeWidth="4" mask="url(#Path-3-inside-2_1115_7768)" />
        <Path d="M491 394.42C555.938 394.42 608.58 447.062 608.58 512C608.58 576.938 555.938 629.58 491 629.58C426.063 629.58 373.42 576.938 373.42 512C373.42 447.062 426.063 394.42 491 394.42Z" stroke="#222B45" strokeWidth="2" />
      </G>
      <G opacity="0.8">
        <Mask id="Path-5-inside-3_1115_7768" fill="white">
          <Path d="M481 -26.9999C392.361 -26.9999 305.09 -5.13966 226.917 36.6445C148.744 78.4287 82.0831 138.847 32.8378 212.548C-16.4074 286.248 -46.7164 370.957 -55.4046 459.169C-64.0927 547.381 -50.8918 636.375 -16.9711 718.266C16.9496 800.158 70.5431 872.421 139.062 928.653C207.581 984.885 288.91 1023.35 375.846 1040.64C462.782 1057.94 552.641 1053.52 637.463 1027.79C722.286 1002.06 799.453 955.808 862.13 893.131L633.452 664.452C608.381 689.523 577.514 708.024 543.585 718.316C509.656 728.609 473.713 730.374 438.938 723.457C404.164 716.54 371.632 701.154 344.225 678.661C316.817 656.168 295.38 627.263 281.812 594.507C268.243 561.75 262.963 526.152 266.438 490.868C269.913 455.583 282.037 421.699 301.735 392.219C321.433 362.739 348.098 338.572 379.367 321.858C410.636 305.144 445.544 296.4 481 296.4L481 -26.9999Z" />
        </Mask>
        <Path d="M481 -26.9999C392.361 -26.9999 305.09 -5.13966 226.917 36.6445C148.744 78.4287 82.0831 138.847 32.8378 212.548C-16.4074 286.248 -46.7164 370.957 -55.4046 459.169C-64.0927 547.381 -50.8918 636.375 -16.9711 718.266C16.9496 800.158 70.5431 872.421 139.062 928.653C207.581 984.885 288.91 1023.35 375.846 1040.64C462.782 1057.94 552.641 1053.52 637.463 1027.79C722.286 1002.06 799.453 955.808 862.13 893.131L633.452 664.452C608.381 689.523 577.514 708.024 543.585 718.316C509.656 728.609 473.713 730.374 438.938 723.457C404.164 716.54 371.632 701.154 344.225 678.661C316.817 656.168 295.38 627.263 281.812 594.507C268.243 561.75 262.963 526.152 266.438 490.868C269.913 455.583 282.037 421.699 301.735 392.219C321.433 362.739 348.098 338.572 379.367 321.858C410.636 305.144 445.544 296.4 481 296.4L481 -26.9999Z" stroke="#222B45" strokeWidth="4" mask="url(#Path-5-inside-3_1115_7768)" />
        <Path d="M481 394.42C545.938 394.42 598.58 447.062 598.58 512C598.58 576.938 545.938 629.58 481 629.58C416.063 629.58 363.42 576.938 363.42 512C363.42 447.062 416.063 394.42 481 394.42Z" stroke="#222B45" strokeWidth="2" />
      </G>
      <G opacity="0.7">
        <Mask id="Path-7-inside-4_1115_7768" fill="white">
          <Path d="M471 -26.9999C382.361 -26.9999 295.09 -5.13966 216.917 36.6445C138.744 78.4287 72.0831 138.847 22.8378 212.548C-26.4074 286.248 -56.7164 370.957 -65.4046 459.169C-74.0927 547.381 -60.8918 636.375 -26.9711 718.266C6.94961 800.158 60.5431 872.421 129.062 928.653C197.581 984.885 278.91 1023.35 365.846 1040.64C452.782 1057.94 542.641 1053.52 627.463 1027.79C712.286 1002.06 789.453 955.808 852.13 893.131L623.452 664.452C598.381 689.523 567.514 708.024 533.585 718.316C499.656 728.609 463.713 730.374 428.938 723.457C394.164 716.54 361.632 701.154 334.225 678.661C306.817 656.168 285.38 627.263 271.812 594.507C258.243 561.75 252.963 526.152 256.438 490.868C259.913 455.583 272.037 421.699 291.735 392.219C311.433 362.739 338.098 338.572 369.367 321.858C400.636 305.144 435.544 296.4 471 296.4L471 -26.9999Z" />
        </Mask>
        <Path d="M471 -26.9999C382.361 -26.9999 295.09 -5.13966 216.917 36.6445C138.744 78.4287 72.0831 138.847 22.8378 212.548C-26.4074 286.248 -56.7164 370.957 -65.4046 459.169C-74.0927 547.381 -60.8918 636.375 -26.9711 718.266C6.94961 800.158 60.5431 872.421 129.062 928.653C197.581 984.885 278.91 1023.35 365.846 1040.64C452.782 1057.94 542.641 1053.52 627.463 1027.79C712.286 1002.06 789.453 955.808 852.13 893.131L623.452 664.452C598.381 689.523 567.514 708.024 533.585 718.316C499.656 728.609 463.713 730.374 428.938 723.457C394.164 716.54 361.632 701.154 334.225 678.661C306.817 656.168 285.38 627.263 271.812 594.507C258.243 561.75 252.963 526.152 256.438 490.868C259.913 455.583 272.037 421.699 291.735 392.219C311.433 362.739 338.098 338.572 369.367 321.858C400.636 305.144 435.544 296.4 471 296.4L471 -26.9999Z" stroke="#222B45" strokeWidth="4" mask="url(#Path-7-inside-4_1115_7768)" />
        <Path d="M471 394.42C535.938 394.42 588.58 447.062 588.58 512C588.58 576.938 535.938 629.58 471 629.58C406.063 629.58 353.42 576.938 353.42 512C353.42 447.062 406.063 394.42 471 394.42Z" stroke="#222B45" strokeWidth="2" />
      </G>
      <G opacity="0.6">
        <Mask id="Path-9-inside-5_1115_7768" fill="white">
          <Path d="M461 -26.9999C372.361 -26.9999 285.09 -5.13966 206.917 36.6445C128.744 78.4287 62.0831 138.847 12.8378 212.548C-36.4074 286.248 -66.7164 370.957 -75.4046 459.169C-84.0927 547.381 -70.8918 636.375 -36.9711 718.266C-3.05039 800.158 50.5431 872.421 119.062 928.653C187.581 984.885 268.91 1023.35 355.846 1040.64C442.782 1057.94 532.641 1053.52 617.463 1027.79C702.286 1002.06 779.453 955.808 842.13 893.131L613.452 664.452C588.381 689.523 557.514 708.024 523.585 718.316C489.656 728.609 453.713 730.374 418.938 723.457C384.164 716.54 351.632 701.154 324.225 678.661C296.817 656.168 275.38 627.263 261.812 594.507C248.243 561.75 242.963 526.152 246.438 490.868C249.913 455.583 262.037 421.699 281.735 392.219C301.433 362.739 328.098 338.572 359.367 321.858C390.636 305.144 425.544 296.4 461 296.4L461 -26.9999Z" />
        </Mask>
        <Path d="M461 -26.9999C372.361 -26.9999 285.09 -5.13966 206.917 36.6445C128.744 78.4287 62.0831 138.847 12.8378 212.548C-36.4074 286.248 -66.7164 370.957 -75.4046 459.169C-84.0927 547.381 -70.8918 636.375 -36.9711 718.266C-3.05039 800.158 50.5431 872.421 119.062 928.653C187.581 984.885 268.91 1023.35 355.846 1040.64C442.782 1057.94 532.641 1053.52 617.463 1027.79C702.286 1002.06 779.453 955.808 842.13 893.131L613.452 664.452C588.381 689.523 557.514 708.024 523.585 718.316C489.656 728.609 453.713 730.374 418.938 723.457C384.164 716.54 351.632 701.154 324.225 678.661C296.817 656.168 275.38 627.263 261.812 594.507C248.243 561.75 242.963 526.152 246.438 490.868C249.913 455.583 262.037 421.699 281.735 392.219C301.433 362.739 328.098 338.572 359.367 321.858C390.636 305.144 425.544 296.4 461 296.4L461 -26.9999Z" stroke="#222B45" strokeWidth="4" mask="url(#Path-9-inside-5_1115_7768)" />
        <Path d="M461 394.42C525.938 394.42 578.58 447.062 578.58 512C578.58 576.938 525.938 629.58 461 629.58C396.063 629.58 343.42 576.938 343.42 512C343.42 447.062 396.063 394.42 461 394.42Z" stroke="#222B45" strokeWidth="2" />
      </G>
      <G opacity="0.5">
        <Mask id="Path-11-inside-6_1115_7768" fill="white">
          <Path d="M451 -26.9999C362.361 -26.9999 275.09 -5.13966 196.917 36.6445C118.744 78.4287 52.0831 138.847 2.83784 212.548C-46.4074 286.248 -76.7164 370.957 -85.4046 459.169C-94.0927 547.381 -80.8918 636.375 -46.9711 718.266C-13.0504 800.158 40.5431 872.421 109.062 928.653C177.581 984.885 258.91 1023.35 345.846 1040.64C432.782 1057.94 522.641 1053.52 607.463 1027.79C692.286 1002.06 769.453 955.808 832.13 893.131L603.452 664.452C578.381 689.523 547.514 708.024 513.585 718.316C479.656 728.609 443.713 730.374 408.938 723.457C374.164 716.54 341.632 701.154 314.225 678.661C286.817 656.168 265.38 627.263 251.812 594.507C238.243 561.75 232.963 526.152 236.438 490.868C239.913 455.583 252.037 421.699 271.735 392.219C291.433 362.739 318.098 338.572 349.367 321.858C380.636 305.144 415.544 296.4 451 296.4L451 -26.9999Z" />
        </Mask>
        <Path d="M451 -26.9999C362.361 -26.9999 275.09 -5.13966 196.917 36.6445C118.744 78.4287 52.0831 138.847 2.83784 212.548C-46.4074 286.248 -76.7164 370.957 -85.4046 459.169C-94.0927 547.381 -80.8918 636.375 -46.9711 718.266C-13.0504 800.158 40.5431 872.421 109.062 928.653C177.581 984.885 258.91 1023.35 345.846 1040.64C432.782 1057.94 522.641 1053.52 607.463 1027.79C692.286 1002.06 769.453 955.808 832.13 893.131L603.452 664.452C578.381 689.523 547.514 708.024 513.585 718.316C479.656 728.609 443.713 730.374 408.938 723.457C374.164 716.54 341.632 701.154 314.225 678.661C286.817 656.168 265.38 627.263 251.812 594.507C238.243 561.75 232.963 526.152 236.438 490.868C239.913 455.583 252.037 421.699 271.735 392.219C291.433 362.739 318.098 338.572 349.367 321.858C380.636 305.144 415.544 296.4 451 296.4L451 -26.9999Z" stroke="#222B45" strokeWidth="4" mask="url(#Path-11-inside-6_1115_7768)" />
        <Path d="M451 394.42C515.938 394.42 568.58 447.062 568.58 512C568.58 576.938 515.938 629.58 451 629.58C386.063 629.58 333.42 576.938 333.42 512C333.42 447.062 386.063 394.42 451 394.42Z" stroke="#222B45" strokeWidth="2" />
      </G>
      <G opacity="0.4">
        <Mask id="Path-13-inside-7_1115_7768" fill="white">
          <Path d="M441 -26.9999C352.361 -26.9999 265.09 -5.13966 186.917 36.6445C108.744 78.4287 42.0831 138.847 -7.16216 212.548C-56.4074 286.248 -86.7164 370.957 -95.4046 459.169C-104.093 547.381 -90.8918 636.375 -56.9711 718.266C-23.0504 800.158 30.5431 872.421 99.062 928.653C167.581 984.885 248.91 1023.35 335.846 1040.64C422.782 1057.94 512.641 1053.52 597.463 1027.79C682.286 1002.06 759.453 955.808 822.13 893.131L593.452 664.452C568.381 689.523 537.514 708.024 503.585 718.316C469.656 728.609 433.713 730.374 398.938 723.457C364.164 716.54 331.632 701.154 304.225 678.661C276.817 656.168 255.38 627.263 241.812 594.507C228.243 561.75 222.963 526.152 226.438 490.868C229.913 455.583 242.037 421.699 261.735 392.219C281.433 362.739 308.098 338.572 339.367 321.858C370.636 305.144 405.544 296.4 441 296.4L441 -26.9999Z" />
        </Mask>
        <Path d="M441 -26.9999C352.361 -26.9999 265.09 -5.13966 186.917 36.6445C108.744 78.4287 42.0831 138.847 -7.16216 212.548C-56.4074 286.248 -86.7164 370.957 -95.4046 459.169C-104.093 547.381 -90.8918 636.375 -56.9711 718.266C-23.0504 800.158 30.5431 872.421 99.062 928.653C167.581 984.885 248.91 1023.35 335.846 1040.64C422.782 1057.94 512.641 1053.52 597.463 1027.79C682.286 1002.06 759.453 955.808 822.13 893.131L593.452 664.452C568.381 689.523 537.514 708.024 503.585 718.316C469.656 728.609 433.713 730.374 398.938 723.457C364.164 716.54 331.632 701.154 304.225 678.661C276.817 656.168 255.38 627.263 241.812 594.507C228.243 561.75 222.963 526.152 226.438 490.868C229.913 455.583 242.037 421.699 261.735 392.219C281.433 362.739 308.098 338.572 339.367 321.858C370.636 305.144 405.544 296.4 441 296.4L441 -26.9999Z" stroke="#222B45" strokeWidth="4" mask="url(#Path-13-inside-7_1115_7768)" />
        <Path d="M441 394.42C505.938 394.42 558.58 447.062 558.58 512C558.58 576.938 505.938 629.58 441 629.58C376.063 629.58 323.42 576.938 323.42 512C323.42 447.062 376.063 394.42 441 394.42Z" stroke="#222B45" strokeWidth="2" />
      </G>
      <G opacity="0.3">
        <Mask id="Path-15-inside-8_1115_7768" fill="white">
          <Path d="M431 -26.9999C342.361 -26.9999 255.09 -5.13966 176.917 36.6445C98.7444 78.4287 32.0831 138.847 -17.1622 212.548C-66.4074 286.248 -96.7164 370.957 -105.405 459.169C-114.093 547.381 -100.892 636.375 -66.9711 718.266C-33.0504 800.158 20.5431 872.421 89.062 928.653C157.581 984.885 238.91 1023.35 325.846 1040.64C412.782 1057.94 502.641 1053.52 587.463 1027.79C672.286 1002.06 749.453 955.808 812.13 893.131L583.452 664.452C558.381 689.523 527.514 708.024 493.585 718.316C459.656 728.609 423.713 730.374 388.938 723.457C354.164 716.54 321.632 701.154 294.225 678.661C266.817 656.168 245.38 627.263 231.812 594.507C218.243 561.75 212.963 526.152 216.438 490.868C219.913 455.583 232.037 421.699 251.735 392.219C271.433 362.739 298.098 338.572 329.367 321.858C360.636 305.144 395.544 296.4 431 296.4L431 -26.9999Z" />
        </Mask>
        <Path d="M431 -26.9999C342.361 -26.9999 255.09 -5.13966 176.917 36.6445C98.7444 78.4287 32.0831 138.847 -17.1622 212.548C-66.4074 286.248 -96.7164 370.957 -105.405 459.169C-114.093 547.381 -100.892 636.375 -66.9711 718.266C-33.0504 800.158 20.5431 872.421 89.062 928.653C157.581 984.885 238.91 1023.35 325.846 1040.64C412.782 1057.94 502.641 1053.52 587.463 1027.79C672.286 1002.06 749.453 955.808 812.13 893.131L583.452 664.452C558.381 689.523 527.514 708.024 493.585 718.316C459.656 728.609 423.713 730.374 388.938 723.457C354.164 716.54 321.632 701.154 294.225 678.661C266.817 656.168 245.38 627.263 231.812 594.507C218.243 561.75 212.963 526.152 216.438 490.868C219.913 455.583 232.037 421.699 251.735 392.219C271.433 362.739 298.098 338.572 329.367 321.858C360.636 305.144 395.544 296.4 431 296.4L431 -26.9999Z" stroke="#222B45" strokeWidth="4" mask="url(#Path-15-inside-8_1115_7768)" />
        <Path d="M431 394.42C495.938 394.42 548.58 447.062 548.58 512C548.58 576.938 495.938 629.58 431 629.58C366.063 629.58 313.42 576.938 313.42 512C313.42 447.062 366.063 394.42 431 394.42Z" stroke="#222B45" strokeWidth="2" />
      </G>
      <G opacity="0.2">
        <Mask id="Path-17-inside-9_1115_7768" fill="white">
          <Path d="M421 -26.9999C332.361 -26.9999 245.09 -5.13966 166.917 36.6445C88.7444 78.4287 22.0831 138.847 -27.1622 212.548C-76.4074 286.248 -106.716 370.957 -115.405 459.169C-124.093 547.381 -110.892 636.375 -76.9711 718.266C-43.0504 800.158 10.5431 872.421 79.062 928.653C147.581 984.885 228.91 1023.35 315.846 1040.64C402.782 1057.94 492.641 1053.52 577.463 1027.79C662.286 1002.06 739.453 955.808 802.13 893.131L573.452 664.452C548.381 689.523 517.514 708.024 483.585 718.316C449.656 728.609 413.713 730.374 378.938 723.457C344.164 716.54 311.632 701.154 284.225 678.661C256.817 656.168 235.38 627.263 221.812 594.507C208.243 561.75 202.963 526.152 206.438 490.868C209.913 455.583 222.037 421.699 241.735 392.219C261.433 362.739 288.098 338.572 319.367 321.858C350.636 305.144 385.544 296.4 421 296.4L421 -26.9999Z" />
        </Mask>
        <Path d="M421 -26.9999C332.361 -26.9999 245.09 -5.13966 166.917 36.6445C88.7444 78.4287 22.0831 138.847 -27.1622 212.548C-76.4074 286.248 -106.716 370.957 -115.405 459.169C-124.093 547.381 -110.892 636.375 -76.9711 718.266C-43.0504 800.158 10.5431 872.421 79.062 928.653C147.581 984.885 228.91 1023.35 315.846 1040.64C402.782 1057.94 492.641 1053.52 577.463 1027.79C662.286 1002.06 739.453 955.808 802.13 893.131L573.452 664.452C548.381 689.523 517.514 708.024 483.585 718.316C449.656 728.609 413.713 730.374 378.938 723.457C344.164 716.54 311.632 701.154 284.225 678.661C256.817 656.168 235.38 627.263 221.812 594.507C208.243 561.75 202.963 526.152 206.438 490.868C209.913 455.583 222.037 421.699 241.735 392.219C261.433 362.739 288.098 338.572 319.367 321.858C350.636 305.144 385.544 296.4 421 296.4L421 -26.9999Z" stroke="#222B45" strokeWidth="4" mask="url(#Path-17-inside-9_1115_7768)" />
        <Path d="M421 394.42C485.938 394.42 538.58 447.062 538.58 512C538.58 576.938 485.938 629.58 421 629.58C356.063 629.58 303.42 576.938 303.42 512C303.42 447.062 356.063 394.42 421 394.42Z" stroke="#222B45" strokeWidth="2" />
      </G>
      <G opacity="0.1">
        <Mask id="Path-19-inside-10_1115_7768" fill="white">
          <Path d="M411 -26.9999C322.361 -26.9999 235.09 -5.13966 156.917 36.6445C78.7444 78.4287 12.0831 138.847 -37.1622 212.548C-86.4074 286.248 -116.716 370.957 -125.405 459.169C-134.093 547.381 -120.892 636.375 -86.9711 718.266C-53.0504 800.158 0.543052 872.421 69.062 928.653C137.581 984.885 218.91 1023.35 305.846 1040.64C392.782 1057.94 482.641 1053.52 567.463 1027.79C652.286 1002.06 729.453 955.808 792.13 893.131L563.452 664.452C538.381 689.523 507.514 708.024 473.585 718.316C439.656 728.609 403.713 730.374 368.938 723.457C334.164 716.54 301.632 701.154 274.225 678.661C246.817 656.168 225.38 627.263 211.812 594.507C198.243 561.75 192.963 526.152 196.438 490.868C199.913 455.583 212.037 421.699 231.735 392.219C251.433 362.739 278.098 338.572 309.367 321.858C340.636 305.144 375.544 296.4 411 296.4L411 -26.9999Z" />
        </Mask>
        <Path d="M411 -26.9999C322.361 -26.9999 235.09 -5.13966 156.917 36.6445C78.7444 78.4287 12.0831 138.847 -37.1622 212.548C-86.4074 286.248 -116.716 370.957 -125.405 459.169C-134.093 547.381 -120.892 636.375 -86.9711 718.266C-53.0504 800.158 0.543052 872.421 69.062 928.653C137.581 984.885 218.91 1023.35 305.846 1040.64C392.782 1057.94 482.641 1053.52 567.463 1027.79C652.286 1002.06 729.453 955.808 792.13 893.131L563.452 664.452C538.381 689.523 507.514 708.024 473.585 718.316C439.656 728.609 403.713 730.374 368.938 723.457C334.164 716.54 301.632 701.154 274.225 678.661C246.817 656.168 225.38 627.263 211.812 594.507C198.243 561.75 192.963 526.152 196.438 490.868C199.913 455.583 212.037 421.699 231.735 392.219C251.433 362.739 278.098 338.572 309.367 321.858C340.636 305.144 375.544 296.4 411 296.4L411 -26.9999Z" stroke="#222B45" strokeWidth="4" mask="url(#Path-19-inside-10_1115_7768)" />
        <Path d="M411 394.42C475.938 394.42 528.58 447.062 528.58 512C528.58 576.938 475.938 629.58 411 629.58C346.063 629.58 293.42 576.938 293.42 512C293.42 447.062 346.063 394.42 411 394.42Z" stroke="#222B45" strokeWidth="2" />
      </G>
    </Svg>
  )
}
