import CardMenuItem from 'app/components/cards/CardMenuItem'
import { StyleSheet, View } from 'react-native'
import ScreenContent from 'app/components/layout/ScreenContent'
import ScrollContent from 'app/components/layout/ScrollContent'
import { useIsMobile } from 'app/components/layout/mediaQueries'
// import GoalIcon from 'app/components/icons/GoalIcon'

const styles = StyleSheet.create({
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    gap: 40,
    marginTop: -180
  }
})

export default function PublishTypeSelectionScreen () {
  const isMobile = useIsMobile()

  return (
    <ScreenContent
      title='Publish'
      description='What would you like to publish?'
    >
      <ScrollContent>
        <View style={!isMobile && styles.center}>
          <View style={styles.grid}>
            <CardMenuItem name='Article' icon='book-open-outline' path='publish/create/article' />
            <CardMenuItem name='Event' icon='calendar-outline' path='publish/create/event' />
            <CardMenuItem name='Poll' icon='pie-chart-outline' path='publish/create/poll' />
            <CardMenuItem name='Form' icon='checkmark-square-outline' path='publish/create/form' />
            <CardMenuItem name='Files' icon='attach' path='publish/create/files' />
            <CardMenuItem name='Short Update' icon='loader-outline' path='publish/create/update' />
            {/* <CardMenuItem name='Award' icon='award-outline' path='publish/create/award' /> */}
            {/* <CardMenuItem name='Goal' icon={<GoalIcon />} path='publish/create/goal' /> */}
          </View>
        </View>
      </ScrollContent>
    </ScreenContent>
  )
}
