import { memo, useEffect, useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { theme } from 'app/theme'

const mapTheme = [
  {
    elementType: 'geometry',
    stylers: [{ color: '#f5f5f5' }]
  },
  {
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#f5f5f5' }]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#bdbdbd' }]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#e5e5e5' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#dadada' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }]
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{ color: '#e5e5e5' }]
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#c9c9c9' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }]
  }
]

const markerIcon = {
  path: 'M12 8C11.173 8 10.5 8.673 10.5 9.5C10.5 10.327 11.173 11 12 11C12.827 11 13.5 10.327 13.5 9.5C13.5 8.673 12.827 8 12 8ZM12 13C10.07 13 8.5 11.43 8.5 9.5C8.5 7.57 10.07 6 12 6C13.93 6 15.5 7.57 15.5 9.5C15.5 11.43 13.93 13 12 13ZM12 2C7.589 2 4 5.554 4 9.923C4 15.397 11.049 21.502 11.349 21.759C11.537 21.92 11.768 22 12 22C12.232 22 12.463 21.92 12.651 21.759C12.951 21.502 20 15.397 20 9.923C20 5.554 16.411 2 12 2Z',
  fillColor: theme['color-primary-500'],
  fillOpacity: 1,
  strokeWeight: 0,
  scale: 1.5
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme['color-basic-300']
  }
})

const MapView = memo(({
  zoom = 12,
  center,
  markerPosition
}) => {
  const ref = useRef()
  const mapRef = useRef()
  const geocoderRef = useRef()
  const { google } = window

  useEffect(() => {
    mapRef.current = new google.maps.Map(ref.current, {
      center: { lat: -34.397, lng: 150.644 },
      zoom,
      disableDefaultUI: true
    })
    mapRef.current.setOptions({ styles: mapTheme })
    geocoderRef.current = new google.maps.Geocoder()
  })

  useEffect(() => {
    if (center && markerPosition) {
      mapRef.current.setCenter(new google.maps.LatLng(
        markerPosition.lat() + 0.002,
        markerPosition.lng()
      ))
      const marker = new google.maps.Marker({
        map: mapRef.current,
        position: markerPosition,
        icon: markerIcon
      })
      marker.setMap(mapRef.current)
    }
  }, [center, markerPosition])

  return <View ref={ref} id="map" style={styles.container} />
})

MapView.displayName = 'MapView'

export default MapView
