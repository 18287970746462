import { light } from '@eva-design/eva'

export const theme = {
  ...light,
  'color-primary-100': '#F4F060',
  'color-primary-200': '#FAFAFA',
  'color-primary-300': '#798EFA',
  'color-primary-400': '#576FF6',
  'color-primary-500': '#223EF1',
  'color-primary-600': '#182ECF',
  'color-primary-700': '#1121AD',
  'color-primary-800': '#0A168B',
  'color-primary-900': '#060F73',
  'color-success-100': '#ECFACF',
  'color-success-200': '#D5F5A0',
  'color-success-300': '#AFE36D',
  'color-success-400': '#88C746',
  'color-success-500': '#56A316',
  'color-success-600': '#418C10',
  'color-success-700': '#2F750B',
  'color-success-800': '#205E07',
  'color-success-900': '#154E04',
  'color-info-100': '#D2DAFE',
  'color-info-200': '#A6B5FD',
  'color-info-300': '#798EFA',
  'color-info-400': '#576FF6',
  'color-info-500': '#223EF1',
  'color-info-600': '#182ECF',
  'color-info-700': '#1121AD',
  'color-info-800': '#0A168B',
  'color-info-900': '#060F73',
  'color-warning-100': '#FFF8CC',
  'color-warning-200': '#FFEF99',
  'color-warning-300': '#FFE466',
  'color-warning-400': '#FFD93F',
  'color-warning-500': '#FFC700',
  'color-warning-600': '#DBA600',
  'color-warning-700': '#B78600',
  'color-warning-800': '#936900',
  'color-warning-900': '#7A5400',
  'color-danger-100': '#FFE4D7',
  'color-danger-200': '#FFC3AF',
  'color-danger-300': '#FF9A87',
  'color-danger-400': '#FF7469',
  'color-danger-500': '#FF383B',
  'color-danger-600': '#DB283B',
  'color-danger-700': '#B71C39',
  'color-danger-800': '#931135',
  'color-danger-900': '#7A0A33',
  'color-primary-focus': '$color-primary-700',
  'color-primary-hover': '$color-basic-800',
  'color-primary-default': '$color-basic-1100',
  'color-primary-active': '$color-basic-600',
  'color-primary-disabled': '$color-basic-300',
  'background-basic-color-2': '$color-basic-100',
  'color-hint': '#8F9BB3',
  'color-background': '#F7F9FC'
}

export const popoverShadow = {
  shadowColor: theme['color-basic-400'],
  shadowRadius: 4,
  shadowOpacity: 0.8,
  shadowOffset: { width: 2, height: 2 }
}

export const containerShadow = {
  shadowColor: theme['color-basic-400'],
  shadowOpacity: 0.8,
  shadowRadius: 4,
  shadowOffset: {
    width: 0,
    height: 2
  }
}
