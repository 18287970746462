import { Tab, TabBar, Text } from '@ui-kitten/components'
import { useIsMobile } from './mediaQueries'
import { useState } from 'react'
import { theme } from 'app/theme'
import { useTranslation } from 'react-i18next'

const { StyleSheet, View } = require('react-native')

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 40,
    flexGrow: 1,
    flexShrink: 1
  },
  containerMobile: {
    flexDirection: 'column',
    flexShrink: 1,
    flexGrow: 1
  },
  left: {
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
    gap: 10,
    flexBasis: 700,
    maxWidth: 800
  },
  right: {
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 300,
    gap: 10,
    flexBasis: 400,
    marginHorizontal: 16,
    marginTop: -18
  },
  rightMobile: {
    marginTop: 0
  },
  rightHeader: {
    paddingBottom: 16
  },
  tabs: {
    marginHorizontal: 16,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: theme['color-basic-400'],
    zIndex: 1
  },
  tabContent: {
    top: -6,
    flexGrow: 1,
    flexShrink: 1
  }
})

export function TwoColumns ({ LeftColumn, RightColumn }) {
  const isMobile = useIsMobile()
  const [selected, setSelected] = useState(0)
  const { t } = useTranslation()

  if (isMobile) {
    return (
      <View style={styles.containerMobile}>
        <View style={styles.tabs}>
          <TabBar
            selectedIndex={selected}
            onSelect={index => setSelected(index)}
          >
            <Tab title={t(LeftColumn.props.title)} />
            <Tab title={t(RightColumn.props.title)} />
          </TabBar>
        </View>
        <View style={styles.tabContent}>
          {selected === 0 && LeftColumn}
          {selected === 1 && RightColumn}
        </View>
      </View>
    )
  }

  return (
    <View style={[styles.container, isMobile && styles.containerMobile]}>
      {LeftColumn}
      {RightColumn}
    </View>
  )
}

export function RightColumn ({ children, title }) {
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  return (
    <View style={[styles.right, isMobile && styles.rightMobile]}>
      {!isMobile && (
        <View style={styles.rightHeader}>
          <Text category='h6'>{t(title)}</Text>
        </View>
      )}
      {children}
    </View>
  )
}

export function LeftColumn ({ children }) {
  return (
    <View style={styles.left}>
      {children}
    </View>
  )
}
