import { Button } from '@ui-kitten/components'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 16
  }
})

export default function LanguageSwitch () {
  const { i18n } = useTranslation()

  return (
    <View style={styles.container}>
      <Button
        size='tiny'
        appearance={i18n.language === 'en' ? 'outline' : 'ghost'}
        onPress={() => i18n.changeLanguage('en')}>
        English
      </Button>
      <Button
        size='tiny'
        appearance={i18n.language === 'es' ? 'outline' : 'ghost'}
        onPress={() => i18n.changeLanguage('es')}>
        Español
      </Button>
    </View>
  )
}
