import { StyleSheet, View } from 'react-native'
import { Text } from '@ui-kitten/components'
import FormItemText from './FormFieldTypeText'
import FormItemSelect from './FormFieldTypeSelect'
import FilesEdit from 'app/components/files/FilesEdit'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    gap: 24
  },
  header: {
    flexDirection: 'column',
    gap: 2,
    flexGrow: 1
  }
})

function FormFieldContent ({ field, organizationId, value }) {
  switch (field.type) {
    case 'text':
      return (
        <FormItemText
          name={`fields.${field.id}.answer`}
          validation={field.validation}
          value={value}
        />
      )
    case 'select':
      return (
        <FormItemSelect
          name={`fields.${field.id}.answer`}
          field={field}
          value={value}
        />
      )
    case 'file':
      return (
        <FilesEdit
          name={`fields.${field.id}.answer`}
          organizationId={organizationId}
          defaultValue={value}
        />
      )
  }
}

export default function FormFieldSubmit ({ index, field, organizationId, value }) {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text category='h6'>{field.label}</Text>
        <Text category='p2' appearance='hint'>{field.description}</Text>
      </View>
      <FormFieldContent
        field={field}
        index={index}
        organizationId={organizationId}
        value={value}
      />
    </View >
  )
}
