import { Card, Text, useStyleSheet } from '@ui-kitten/components'
import Icon from 'app/components/Icon'
import Stack from 'app/components/layout/Stack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { useNavigate } from 'react-router'

const themedStyles = StyleSheet.create({
  icon: {
    fill: 'color-basic-500'
  },
  iconHighlight: {
    fill: 'color-info-500'
  },
  card: {
    flexBasis: '30%',
    flexGrow: 1
  }
})

export default function CardMenuItem ({ name, icon, path }) {
  const styles = useStyleSheet(themedStyles)
  const [mouseOver, setMouseOver] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Card
      style={styles.card}
      status={mouseOver ? 'info' : 'basic'}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onPress={() => navigate(`../${path}`)}
    >
      <Stack gap={10} alignItems='center'>
        {typeof icon === 'string'
          ? <Icon
          name={icon}
          size={50}
          animation='pulse'
          style={mouseOver ? styles.iconHighlight : styles.icon}
        />
          : React.cloneElement(icon, { style: mouseOver ? styles.iconHighlight : styles.icon })}
        <Text category='s1'>{t(name)}</Text>
      </Stack>
    </Card>
  )
}
