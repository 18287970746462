import CardContent from 'app/components/cards/CardContent'
import Stack from 'app/components/layout/Stack'
import ItemForm from 'app/modules/items/create/ItemForm'
import { Divider } from '@ui-kitten/components'
import CardButtons from 'app/components/cards/CardButtons'
import ButtonSubmit from 'app/components/forms/ButtonSubmit'
import AddFormField from './AddFormField'
import FormSettings from './FormSettings'
import FormFieldEdit from './FormFieldEdit'
import { useController } from 'react-hook-form'

export default function FormForm ({ onSubmit, organizationId, submitLabel = 'Publish' }) {
  const { field: { onChange, value: fields } } = useController({
    name: 'content.fields',
    defaultValue: [],
    rules: {
      validate: (fields) => {
        if (fields.length === 0) {
          return 'At least one field is required'
        }
        return true
      }
    }
  })

  const handleSubmit = item => {
    Object.entries(item.content.fields).forEach(([_, field]) => {
      if (field.type === 'select') {
        field.options = field.options.filter(option => option !== '')
      }
    })
    onSubmit(item)
  }

  const handleRemove = (index) => {
    onChange([...fields.filter((_, i) => i !== index)])
  }

  const handleAdd = (field) => {
    onChange([...fields, {
      id: Math.random().toString(36).substring(7),
      label: '',
      description: '',
      validation: '',
      required: true,
      ...field
    }])
  }

  const handleMove = (direction, index, field) => {
    const newItems = [...fields]
    newItems.splice(index, 1)
    newItems.splice(index + (direction === 'up' ? -1 : 1), 0, field)
    onChange(newItems)
  }

  return (
    <Stack gap={0}>
      <CardContent>
        <ItemForm />
      </CardContent>
      <Divider />
      <CardContent>
        <Stack gap={12}>
          {fields.map((field, i) => (
            <FormFieldEdit
              key={i}
              field={field}
              organizationId={organizationId}
              index={i}
              total={fields.length}
              onRemove={() => handleRemove(i)}
              onMoveUp={() => handleMove('up', i, field)}
              onMoveDown={() => handleMove('down', i, field)}
            />
          ))}
          <AddFormField onAddField={handleAdd} />
        </Stack>
      </CardContent>
      <CardContent>
        <FormSettings />
      </CardContent>
      <CardContent>
        <CardButtons>
          <ButtonSubmit status='primary' onSubmit={handleSubmit}>
          {submitLabel}
          </ButtonSubmit>
        </CardButtons>
      </CardContent>
    </Stack>
  )
}
