import { Text } from '@ui-kitten/components'
import { StyleSheet, View } from 'react-native'
import Avatar from 'app/components/Avatar'
import { useFileLocalUrl } from 'app/api/files'
import { Trans, useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: 8
  },
  row: {
    flexDirection: 'row',
    gap: 14
  },
  data: {
    flexDirection: 'column',
    gap: 2,
    justifyContent: 'center'
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4
  },
  number: {
    justifyContent: 'center'
  }
})

export default function ItemHeader ({ item }) {
  const { createdBy } = item
  const avatarLocalURL = useFileLocalUrl(createdBy.organizationId, createdBy.avatarFileId)
  const { t } = useTranslation()

  return (
    <View style={[styles.container]}>
      <View style={styles.row}>
        <Avatar source={avatarLocalURL} size='small' />
        <View style={[styles.data]}>
          <Text category='s2' style={styles.text}>
            {createdBy.user.name} {createdBy.user.surname}
            <Trans t={t}>
              <Text category='s2' appearance='hint'>posted an</Text> <Text category='s2'>{item.type}</Text>
            </Trans>
          </Text>
          <Text category="c1" appearance='hint'>{createdBy.title}</Text>
        </View>
      </View>
      <View style={styles.number}>
        <Text category='c1' appearance='hint'>#{item.itemId}</Text>
      </View>
    </View>
  )
}
