import { Divider } from '@ui-kitten/components'
import CardContent from 'app/components/cards/CardContent'
import Stack from 'app/components/layout/Stack'
import ItemForm from 'app/modules/items/create/ItemForm'
import CardButtons from 'app/components/cards/CardButtons'
import ButtonSubmit from 'app/components/forms/ButtonSubmit'
import FilesEdit from 'app/components/files/FilesEdit'
import { StyleSheet, View } from 'react-native'

const styles = StyleSheet.create({
  files: {
    marginTop: -12
  }
})

export default function FilesForm ({ onSubmit, organizationId, submitLabel = 'Publish' }) {
  return (
    <Stack>
      <CardContent>
        <ItemForm />
      </CardContent>
      <Divider />
      <View style={styles.files}>
        <FilesEdit name='content.files' organizationId={organizationId} />
      </View>
      <CardContent>
        <CardButtons>
          <ButtonSubmit status='primary' onSubmit={onSubmit}>
            {submitLabel}
          </ButtonSubmit>
        </CardButtons>
      </CardContent>
    </Stack>
  )
}
