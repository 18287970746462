import { useState, useContext } from 'react'
import { Divider, Text } from '@ui-kitten/components'
import Form from 'app/components/forms/Form'
import Stack from 'app/components/layout/Stack'
import ButtonSubmit from 'app/components/forms/ButtonSubmit'
import Alert from 'app/components/forms/Alert'
import TextInput from 'app/components/forms/TextInput'
import { useCreateOrganizations } from 'app/api/organizations'
import { Link } from 'app/components/Link'
import slug from 'slug'
import OrganizationRules from 'app/modules/organizations/OrganizationRules'
import { requestTokenWithRefreshToken } from 'app/api/oauth-client'
import { oauthClientId } from 'app/config'
import { SessionContext } from 'app/components/SessionContext'
import { useUserOrganizations } from 'app/api/users'
import { useTranslation } from 'react-i18next'

export default function CreateOrganizationScreen () {
  const { getToken, saveToken } = useContext(SessionContext)
  const [hasError, setHasError] = useState(false)
  const create = useCreateOrganizations()
  const [completed, setCompleted] = useState(false)
  const [organizationId, setOrganizationId] = useState('')
  const { mutate } = useUserOrganizations()
  const { t } = useTranslation()

  const onSubmit = async (organization) => {
    try {
      organization.id = slug(organization.name).substring(0, 26)
      await mutate(async organizations => {
        const newOrg = await create(organization)
        return [...organizations, newOrg]
      })
      setOrganizationId(organization.id)
      let token = await getToken()
      token = await requestTokenWithRefreshToken(token.refreshToken, oauthClientId)
      await saveToken(token)
      setCompleted(true)
    } catch (err) {
      if (err.status === 409) {
        setHasError(t('There\'s already an Organization with name \'{{name}}\'', { name: organization.name }))
      } else throw err
    }
  }

  if (completed) {
    return (
      <Stack>
        <Alert type='success'>
          {t('Your organization has been created')}
        </Alert>
        <Link
          to={`/org/${organizationId}`}
        >{t('Continue')}</Link>
      </Stack>
    )
  }

  return (
    <Form defaultValues={{ name: '', slogan: '' }}>
      <Stack alignItems='stretch' gap={20}>
        {hasError && (
          <Alert type='danger'>{hasError}</Alert>
        )}
        <Text category='h2'>{t('Create a new Circl')}</Text>
        <TextInput
          placeholder='Name'
          name='name'
          rules={{
            minLength: 5,
            required: true
          }}
        />
        <OrganizationRules fieldName='name' />
        <TextInput
          placeholder='Short description or slogan'
          name='slogan'
          rules={{
            maxLength: 50
          }}
        />
        <ButtonSubmit onSubmit={onSubmit}>
          {t('Create')}
        </ButtonSubmit>
        <Divider />
        <Link to='/'>{t('Back')}</Link>
      </Stack>
    </Form >
  )
}
