import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import CardPressable from 'app/components/cards/CardPressable'
import ItemSummary from 'app/modules/items/components/ItemSummary'
import PollContent from 'app/modules/items/view/PollContent'
import EventContent from 'app/modules/items/events/EventContent'
import FilesContent from 'app/modules/items/files/FilesContent'
import ReactionsBox from 'app/modules/items/reactions/ReactionsBox'
import CardHeader from 'app/components/cards/CardHeader'
import ItemMenu from 'app/modules/items/components/ItemMenu'
import FormFeedContent from 'app/modules/items/forms/FormFeedContent'
import ItemHeader from 'app/modules/items/components/ItemHeader'

function PreviewByType ({ item }) {
  switch (item.type) {
    case 'poll':
      return <PollContent item={item} />
    case 'event':
      return <EventContent item={item} />
    case 'files':
      return <FilesContent item={item} />
    case 'form':
      return <FormFeedContent item={item} />
    default:
      return null
  }
}

export default function FeedItemCard ({ item }) {
  const [mouseOver, setMouseOver] = useState(false)
  const navigate = useNavigate()

  return (
    <CardPressable
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onPress={() => navigate(`/org/${item.organizationId}/items/${item.itemId}`)}
    >
      <ItemHeader item={item} />
      <CardHeader
        showButtonMenu={mouseOver}
        menu={<ItemMenu item={item} />}
      >
        <ItemSummary item={item} numberOfLines={5} />
      </CardHeader>
      <PreviewByType item={item} />
      <ReactionsBox item={item} />
    </CardPressable>
  )
}
