import { getToken, getMessaging } from 'firebase/messaging'
import { firebaseApp, vapidKey } from 'app/notifications/firebase'

export const messaging = getMessaging(firebaseApp)

export class NotificationsClient {
  constructor () {
    this.serviceWorkerRegistration = navigator.serviceWorker.register('/service-worker.js')
  }

  static canPushNotifications () {
    return 'serviceWorker' in navigator && 'Notification' in window && 'PushManager' in window
  }

  async getToken () {
    return getToken(messaging, { vapidKey, serviceWorkerRegistration: await this.serviceWorkerRegistration })
  }

  async requestPermission () {
    const permission = await Notification.requestPermission()

    return permission === 'granted'
  }

  hasPermission () {
    return Notification.permission === 'granted'
  }
}
