import { MenuItem, Text, useStyleSheet } from '@ui-kitten/components'
import Avatar from 'app/components/Avatar'
import Icon from 'app/components/Icon'
import { View, StyleSheet } from 'react-native'
import CardPressable from 'app/components/cards/CardPressable'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { useFileLocalUrl } from 'app/api/files'
import { useMemberRemove } from 'app/api/members'
import CardHeader from 'app/components/cards/CardHeader'
import { useTranslation } from 'react-i18next'
import useLocalDateFormat from 'app/i18n/useLocalDateFormat'

const themedStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 18,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 1
  },
  icon: {
    fill: 'color-basic-600'
  },
  options: {
    width: 30
  },
  info: {
    flexGrow: 1,
    flexShrink: 1,
    gap: 4,
    flexDirection: 'column'
  },
  column: {
    flexDirection: 'column',
    gap: 4,
    paddingHorizontal: 10
  }
})

export default function MemberCard ({ organizationId, member }) {
  const styles = useStyleSheet(themedStyles)
  const [mouseOver, setMouseOver] = useState(false)
  const navigate = useNavigate()
  const avatarLocalURL = useFileLocalUrl(organizationId, member.avatarFileId)
  const remove = useMemberRemove(organizationId)
  const onPress = () => navigate(`/org/${organizationId}/members/${member.userId}`)
  const { t } = useTranslation()
  const format = useLocalDateFormat()

  return (
    <CardPressable
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onPress={onPress}
    >
      <CardHeader
        showButtonMenu={mouseOver}
        onPress={onPress}
        menu={
          <MenuItem
            appearance='danger'
            title={t('Remove')}
            accessoryLeft={<Icon name='trash-2-outline' />}
            onPress={() => remove(member.userId)}
          />
        }
      >
        <View style={styles.container}>
          <Avatar source={avatarLocalURL} />
          <View style={styles.info}>
            <Text category='c2' appearance='hint'>#{member.id.slice(-6).toUpperCase()}</Text>
            <Text category='s1' numberOfLines={1}>{member.acceptedAt ? member.user.name : t('Invited')}</Text>
            <Text category='c1' appearance='hint' numberOfLines={1} ellipsizeMode='clip'>{member.title || member.user.email}</Text>
          </View>
          <View style={styles.column}>
            <Text category='c1' appearance='hint'>{t('Added')}</Text>
            <Text category='label' appearance='hint'>{format(new Date(member.createdAt), 'MMM do Y')}</Text>
          </View>
        </View>
      </CardHeader>
    </CardPressable >
  )
}
