import { StyleSheet, View } from 'react-native'
import CardInner from 'app/components/cards/CardInner'
import { Text } from '@ui-kitten/components'
import ToggleInput from 'app/components/forms/ToggleInput'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

export default function FormSettings () {
  const { t } = useTranslation()
  return (
    <CardInner title={t('Form Settings')}>
      <View style={styles.row}>
        <Text category='p2'>{t('Allow to submit multiple times')}</Text>
        <ToggleInput name='content.isImmutable' defaultValue={false} />
      </View>
    </CardInner>
  )
}
