import useSWRInfinite from 'swr/infinite'
import useSWR, { useSWRConfig } from 'swr'
import { useApi } from './api'

const arrayToQueryString = (key, array = []) => array.map(item => `${key}[]=${item}`).join('&')

export function useList (organizationId, { types } = {}, take = 20) {
  const typesQuery = types ? arrayToQueryString('types', types) : ''
  const path = `v1/organizations/${organizationId}/items?${typesQuery}`
  const { data: items, ...props } = useSWRInfinite((pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null
    return `${path}&skip=${pageIndex * take}&take=${take}`
  })

  return {
    items,
    ...props
  }
}

export function useSearch (organizationId, query, take = 20) {
  const path = `v1/organizations/${organizationId}/items/search?query=${encodeURIComponent(query)}`
  const { data: items, ...props } = useSWRInfinite((pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null
    return `${path}&skip=${pageIndex * take}&take=${take}`
  })

  return {
    items,
    ...props
  }
}

export function useCreate (organizationId) {
  const send = useApi()

  return (type, item) => {
    return send('POST', `v1/organizations/${organizationId}/items`, { type, ...item })
  }
}

export function useUpdate (organizationId) {
  const send = useApi()

  return (itemId, item) => {
    return send('PATCH', `v1/organizations/${organizationId}/items/${itemId}`, item)
  }
}

export function useRemove (organizationId) {
  const path = `v1/organizations/${organizationId}/items`
  const send = useApi()
  const { mutate, cache } = useSWRConfig()

  return async (itemId) => {
    await send('DELETE', `${path}/${itemId}`)
    for (const key of cache.keys()) {
      if (key.includes(path)) {
        mutate(key)
      }
    }
  }
}

export function useItem (organizationId, itemId) {
  const path = `v1/organizations/${organizationId}/items/${itemId}`
  const { data: item, ...props } = useSWR(path)

  return {
    item,
    ...props
  }
}

export function useItems (organizationId, { itemIds, pinned }) {
  const itemIdsQuery = arrayToQueryString('itemIds', itemIds)
  const path = `v1/organizations/${organizationId}/items?${itemIdsQuery}&${pinned ? 'pinned=true' : ''}`
  const { data: items, ...props } = useSWR(path)

  return {
    items,
    ...props
  }
}

export function usePinnedItems (organizationId) {
  const { data: items, mutate, ...props } = useItems(organizationId, { pinned: true })
  const update = useUpdate(organizationId)

  const pin = async (itemId) => {
    const item = await update(itemId, { pinned: true })
    mutate(items => [...items, item])
    return item
  }

  const unpin = async (itemId) => {
    const item = await update(itemId, { pinned: false })
    mutate(items => items.filter(item => item.itemId !== itemId))
    return item
  }

  return {
    items,
    pin,
    unpin,
    mutate,
    ...props
  }
}

export function useReactions (organizationId, id) {
  const send = useApi()

  const add = (reaction) => {
    return send('POST', `v1/organizations/${organizationId}/items/${id}/reactions`, { reaction })
  }

  const remove = (reaction) => {
    return send('DELETE', `v1/organizations/${organizationId}/items/${id}/reactions/${reaction}`)
  }

  return {
    add,
    remove
  }
}
