import { Pressable, StyleSheet, View } from 'react-native'
import Icon from 'app/components/Icon'
import { Button, OverflowMenu } from '@ui-kitten/components'
import { popoverShadow, theme } from 'app/theme'
import { useState } from 'react'
import { useIsMobile } from 'app/components/layout/mediaQueries'
import ModalBox from 'app/components/ModalBox'
import { HasAccess } from 'app/modules/authentication/HasAccess'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 54,
    width: '100%'
  },
  menu: {
    ...popoverShadow
  },
  buttonHidden: {
    opacity: 0
  }
})

export default function CardHeader ({ children, showButtonMenu, menu, onPress }) {
  const [showMenu, setShowMenu] = useState(false)
  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      <Pressable onLongPress={() => setShowMenu(true)} onPress={onPress}>
        <View style={styles.container}>
          {children}
          {showMenu && (
            <ModalBox
              visible={true}
              onDismiss={() => setShowMenu(false)}
            >
              {menu}
            </ModalBox>
          )}
        </View>
      </Pressable>
    )
  }

  return (
    <View style={styles.container}>
      {children}
      <HasAccess scope='items:write'>
        <OverflowMenu
          style={styles.menu}
          anchor={() => (
            <Button
              style={showButtonMenu ? undefined : styles.buttonHidden}
              onPress={() => setShowMenu(true)}
              appearance='ghost'
              size='large'
              accessoryRight={<Icon name='more-vertical' fill={theme['color-basic-500']} />}
            />
          )}
          visible={showMenu}
          onBackdropPress={() => setShowMenu(false)}
          placement='bottom end'
        >
          {menu}
        </OverflowMenu>
      </HasAccess>
    </View>
  )
}
