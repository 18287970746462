import React from 'react'
import { useNavigate } from 'react-router'
import CardPressable from 'components/cards/CardPressable'
import ItemSummary from 'app/modules/items/components/ItemSummary'
import { Text } from '@ui-kitten/components'

export default function RelatedItemCard ({ item }) {
  const navigate = useNavigate()

  return (
    <CardPressable
      onPress={() => navigate(`/org/${item.organizationId}/items/${item.itemId}`)}
    >
      <Text category='c1' appearance='hint'>#{item.itemId}</Text>
      <ItemSummary item={item} />
    </CardPressable>
  )
}
