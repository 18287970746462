import { useRelatedItemsEdit } from './RelatedItemsContext'
import RelatedList from './RelatedList'
import RelatedEditEmptyBox from './RelatedEditEmptyBox'

export default function RelatedItemsEditMode ({ organizationId }) {
  const { relatedItemIds } = useRelatedItemsEdit()

  if (!relatedItemIds.length) {
    return (
      <RelatedEditEmptyBox />
    )
  }

  return (
    <RelatedList
      organizationId={organizationId}
      filter={{ itemIds: relatedItemIds }}
      title='See more'
      EmptyPlaceholder={<RelatedEditEmptyBox />}
    />
  )
}
