import { useState } from 'react'
import { Text } from '@ui-kitten/components'
import { requestPasswordChangeCode } from 'app/api/users'
import Form from 'app/components/forms/Form'
import ButtonSubmit from 'app/components/forms/ButtonSubmit'
import Alert from 'app/components/forms/Alert'
import Stack from 'app/components/layout/Stack'
import TextInput from 'app/components/forms/TextInput'
import { useTranslation } from 'react-i18next'

export default function RequestPasswordChangeForm ({ onCompleted }) {
  const [hasError, setHasError] = useState(false)
  const { t } = useTranslation()

  const onSubmit = async ({ email }) => {
    try {
      await requestPasswordChangeCode(email)
      onCompleted(email)
    } catch (err) {
      if (err.status === 404) {
        setHasError(t('We couldn\'t find a user with email {{email}}, are you sure it\'s right?', { email }))
      } else throw err
    }
  }

  return (
    <Form defaultValues={{ email: '' }}>
      <Stack alignItems='flex-start' gap={10}>
        <Text>
          {t('Enter your email, we\'ll send you a code to set a new password:')}
        </Text>
        {hasError && (
          <Alert type='danger'>{hasError}</Alert>
        )}
        <TextInput placeholder='Your email' name='email' rules={{ required: true }} />
      </Stack>
      <ButtonSubmit onSubmit={onSubmit}>
        {t('Send')}
      </ButtonSubmit>
    </Form>
  )
}
