import { StyleSheet, View } from 'react-native'
import { useIsMobile } from 'app/components/layout/mediaQueries'

const styles = StyleSheet.create({
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 12
  },
  buttonsMobile: {
    justifyContent: 'space-between'
  }
})

export default function CardButtons ({ children }) {
  const isMobile = useIsMobile()

  return (
    <View style={[styles.buttons, isMobile && styles.buttonsMobile]}>
      {children}
    </View>
  )
}
