import { Text } from '@ui-kitten/components'
import HStack from 'app/components/layout/HStack'
import Icon from 'app/components/Icon'
import { useTranslation } from 'react-i18next'

export default function StatusLabel ({ iconName, color, label, align = 'left' }) {
  const { t } = useTranslation()

  return (
    <HStack gap={4} justifyContent={align === 'left' ? 'flex-start' : 'flex-end'}>
      <Icon name={iconName} fill={color} />
      <Text category='c1' style={{ color }}>
        {t(label)}
      </Text>
    </HStack>
  )
}
