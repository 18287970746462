import { useTranslation } from 'react-i18next'
import { es, enUS } from 'date-fns/locale'
import { format } from 'date-fns'

const locales = { es, en: enUS }

export default function useLocalDateFormat () {
  const { i18n } = useTranslation()

  return (date, formatStr) => {
    return format(date, formatStr, { locale: locales[i18n.language] })
  }
}
