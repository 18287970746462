import { websiteUrl } from 'app/config'
import { useEffect } from 'react'
import { Platform } from 'react-native'
import { useNavigate } from 'react-router'

export default function AuthHome () {
  const navigate = useNavigate()

  useEffect(() => {
    if (Platform.OS === 'web') {
      window.location.replace(websiteUrl)
      return
    }

    navigate('/sign-in')
  }, [])

  return null
}
