import { Controller, useFormContext } from 'react-hook-form'
import RichTextEditor from './RichTextEditor'
import { useTranslation } from 'react-i18next'

export default function RichTextInput ({ name, placeholder, rules, ...props }) {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error }
      }) => (
        <RichTextEditor
          onTextChange={onChange}
          initialValue={value}
          placeholder={t(placeholder)}
          status={error ? 'danger' : 'basic'}
          {...props}
        />
      )}
    />
  )
}
