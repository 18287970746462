import { Text } from '@ui-kitten/components'
import { Pressable, StyleSheet } from 'react-native'
import { theme } from 'app/theme'
import { emojiMap } from './emojis'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4
  },
  reaction: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: theme['color-basic-300'],
    padding: 4,
    paddingHorizontal: 8
  },
  reacted: {
    borderColor: theme['color-primary-500'],
    color: theme['color-primary-500']
  }
})

export default function ButtonReaction ({ reaction, count, reacted, onReactionAdd, onReactionRemove }) {
  const onPress = () => {
    if (reacted) {
      onReactionRemove(reaction)
    } else {
      onReactionAdd(reaction)
    }
  }

  return (
    <Pressable
      style={[styles.reaction, reacted && styles.reacted]}
      onPress={onPress}
    >
      <Text category='c1'>{emojiMap[reaction]}</Text>
      <Text category='c1' style={reacted && styles.reacted}>{count}</Text>
    </Pressable>
  )
}
