import { Navigate } from 'react-router'
import Text from 'app/i18n/Text'
import SelectOrganizationList from 'app/modules/organizations/SelectOrganizationList'
import Stack from 'app/components/layout/Stack'
import { useUserMembers } from 'app/api/users'

export default function SelectOrganizationScreen () {
  const { members } = useUserMembers()

  if (!members) return null

  if (members.length === 0) {
    return <Navigate replace to='/create-organization' />
  }

  return (
    <Stack gap={30}>
      <Text category='h2'>Select your organization</Text>
      <SelectOrganizationList members={members} />
    </Stack>
  )
}
