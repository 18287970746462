import Icon from 'app/components/Icon'
import TextInputMultiple from 'app/components/forms/TextInputMultiple'
import { theme } from 'app/theme'

export default function FormFieldTypeSelectEdit ({ name, maxSelections }) {
  return (
    <TextInputMultiple
      min={2}
      max={10}
      placeholder='Option'
      name={name}
      rules={{ required: 'Option cannot be empty' }}
      accessoryLeft={() => maxSelections === 1
        ? (
        <Icon name='radio-button-off-outline' fill={theme['color-basic-500']} />
          )
        : (
        <Icon name='square-outline' fill={theme['color-basic-500']} />
          )}
    />
  )
}
