import Stack from 'app/components/layout/Stack'
import HStack from 'app/components/layout/HStack'
import EditableSectionHeader from 'app/components/forms/EditableSectionHeader'
import { EditableSection } from 'app/components/forms/EditableSection'
import Form from 'app/components/forms/Form'
import { useMember } from 'app/api/members'
import ProfileEditableAvatar from './ProfileEditableAvatar'

export default function MemberSelfUpdate ({ organizationId, userId }) {
  const { member, update } = useMember(organizationId, userId)

  if (!member) return null

  return (
    <Stack gap={24}>
      <EditableSection>
        <Form defaultValues={member}>
          <EditableSectionHeader
            title='Member Details'
            onSave={update}
          />
          <Stack>
            <HStack justifyContent='space-between'>
              <ProfileEditableAvatar organizationId={organizationId} />
            </HStack>
          </Stack>
        </Form>
      </EditableSection>
    </Stack>
  )
}
