import React from 'react'
import { StyleSheet } from 'react-native'
import { ListItem, Text } from '@ui-kitten/components'
import Icon from 'app/components/Icon'
import HStack from 'app/components/layout/HStack'
import Label from 'app/components/Label'

const ChevronIcon = () => (
  <Icon
    style={styles.icon}
    fill='#8F9BB3'
    name='arrow-ios-forward-outline'
  />
)

export const OrganizationCard = ({ member: { organization, acceptedAt }, onPress }) => (
  <ListItem
    style={styles.container}
    title={(
      <HStack paddingHorizontal={8} alignItems='center'>
        <Text category='s2'>{organization.name}</Text>
        {!acceptedAt && (
          <Label>Invited</Label>
        )}
      </HStack>
    )}
    description={organization.slogan}
    accessoryRight={ChevronIcon}
    onPress={onPress}
  />
)

const styles = StyleSheet.create({
  icon: {
    width: 20,
    height: 20
  },
  container: {
    minHeight: 77
  }
})
