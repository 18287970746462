import { useApi } from './api'
import useSWRInfinite from 'swr/infinite'
import useSWR, { useSWRConfig } from 'swr'

export function useFormSubmit (organizationId, formId, userId) {
  const path = `v1/organizations/${organizationId}/forms/${formId}/users/${userId}/submissions`
  const send = useApi()
  const { mutate, cache } = useSWRConfig()

  return async (values) => {
    const result = await send('POST', path, values)
    for (const key of cache.keys()) {
      if (key.includes(path)) {
        mutate(key, [result])
      }
    }
  }
}

export function useSubmissions (organizationId, formId, take = 10) {
  const path = `v1/organizations/${organizationId}/forms/${formId}/submissions`
  const { data: submissions, ...props } = useSWRInfinite((pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null
    return `${path}?skip=${pageIndex * take}&take=${take}`
  })

  return {
    submissions,
    ...props
  }
}

export function useUserSubmissions (organizationId, formId, userId) {
  const path = `v1/organizations/${organizationId}/forms/${formId}/users/${userId}/submissions`
  const { data: submissions, ...props } = useSWR(path)

  return {
    submissions,
    ...props
  }
}
