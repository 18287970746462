import { Modal as ModalKitten, useStyleSheet } from '@ui-kitten/components'
import { StyleSheet, View } from 'react-native'
import CardFrame from './cards/CardFrame'
import CardContent from './cards/CardContent'

const themedStyles = StyleSheet.create({
  backdrop: {
    backgroundColor: 'rgba(143, 155, 179, 0.25)'
  },
  container: {
    margin: 12
  }
})

export default function ModalBox ({ visible, onDismiss, children }) {
  const styles = useStyleSheet(themedStyles)

  return (
    <ModalKitten
      visible={visible}
      backdropStyle={styles.backdrop}
      onBackdropPress={onDismiss}
    >
      <View style={styles.container}>
        <CardFrame shadow={false}>
          <CardContent>
            {children}
          </CardContent>
        </CardFrame>
      </View>
    </ModalKitten >
  )
}
