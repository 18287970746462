import { StyleSheet, View } from 'react-native'
import { items } from './items'
import CardMenuItem from 'app/components/cards/CardMenuItem'
import ScreenContent from 'app/components/layout/ScreenContent'
import ScrollContent from 'app/components/layout/ScrollContent'
import { Divider } from '@ui-kitten/components'
import OrganizationBlock from 'app/components/layout/OrganizationBlock'
import Stack from 'app/components/layout/Stack'
import { HasAccess } from 'app/modules/authentication/HasAccess'

const styles = StyleSheet.create({
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    gap: 40,
    marginHorizontal: 16
  }
})

export default function MenuScreen () {
  return (
    <ScreenContent
      title='Menu'
      description='What would you like to see?'
    >
      <ScrollContent>
        <Stack gap={16}>
          <View style={styles.grid}>
            {items.map(item => (
              <CardMenuItem
                key={item.name}
                name={item.name}
                icon={item.icon}
                path={item.path}
              />
            ))}
            <HasAccess scope='members:read'>
              <CardMenuItem
                name='Members'
                icon='people-outline'
                path='members'
              />
            </HasAccess>
          </View>
          <Divider />
          <OrganizationBlock />
        </Stack>
      </ScrollContent>
    </ScreenContent>
  )
}
