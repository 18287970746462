import { useFileUpload } from 'app/api/files'
import { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'

export default function useImageUpload ({ organizationId, name }) {
  const { upload, remove, getFileURL } = useFileUpload(organizationId)
  const [fileLocalURL, setFileLocalURL] = useState(null)
  const { field: { onChange, value } } = useController({ name, defaultValue: null })

  const uploadFile = async (file) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      setFileLocalURL(e.target.result)
    }
    reader.readAsDataURL(file[0])

    const fileUpload = await upload(file[0], file[0].name)
    onChange(fileUpload.id)
  }

  const removeFile = async () => {
    if (value) {
      await remove(value)
      onChange(null)
      setFileLocalURL(null)
    }
  }

  useEffect(() => {
    const fetchFileURL = async () => {
      if (value && !fileLocalURL) {
        const fileURL = await getFileURL(value)
        setFileLocalURL(fileURL)
      }
    }
    fetchFileURL()
  }, [value, fileLocalURL])

  return {
    uploadFile,
    removeFile,
    fileLocalURL
  }
}
