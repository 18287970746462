import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { items } from './items'
import { StyleSheet, View } from 'react-native'
import MenuButton from './MenuButton'
import { HasAccess } from 'app/modules/authentication/HasAccess'

const itemsWithFeed = items.concat()

itemsWithFeed.unshift({
  name: 'Feed',
  icon: 'message-square-outline',
  path: 'feed'
})

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1
  },
  menu: {
    padding: 6,
    flexDirection: 'column',
    alignItems: 'center',
    gap: 6
  },
  button: {
    height: 60
  }
})

export default function LeftMenu () {
  const navigate = useNavigate()
  const { organizationId } = useParams()

  const onPress = (path) => {
    navigate(`/org/${organizationId}/${path}`)
  }

  return (
    <View style={styles.container}>
      <View style={styles.menu}>
        {itemsWithFeed.map((item, index) => (
          <MenuButton
            key={index}
            item={item}
            onPress={() => onPress(item.path)}
          />
        ))}
        <HasAccess scope='members:read'>
          <MenuButton
            item={{ icon: 'people-outline', path: 'members' }}
            onPress={() => onPress('members')}
          />
        </HasAccess>
      </View>
      <View style={styles.menu}>
        <MenuButton
          item={{ name: 'Help', icon: 'question-mark-circle-outline', path: 'help' }}
        />
        <HasAccess scope='organizations:write'>
          <MenuButton
            item={{ name: 'Settings', icon: 'settings-outline', path: 'settings' }}
            onPress={() => onPress('setup')}
          />
        </HasAccess>
      </View>
    </View>
  )
}
