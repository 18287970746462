import React, { useState } from 'react'
import { TouchableWithoutFeedback } from 'react-native'
import TextInput from 'app/components/forms/TextInput'
import Icon from 'app/components/Icon'
import { useFormContext } from 'react-hook-form'
import { passwordValidations } from 'app/validations'

const EyeIcon = ({ onPress, off, ...props }) => (
  <TouchableWithoutFeedback onPress={onPress}>
    <Icon name={off ? 'eye-off' : 'eye'} {...props} />
  </TouchableWithoutFeedback>
)

export default function PasswordInput ({ name, placeholder, compareTo, ...props }) {
  const [secureTextEntry, setSecureTextEntry] = useState(true)
  const { getValues } = useFormContext()
  let validate = passwordValidations

  if (compareTo) {
    validate = value => value === getValues('password')
  }

  return (
    <TextInput
      name={name}
      rules={{
        validate,
        required: true
      }}
      placeholder={placeholder}
      accessoryRight={<EyeIcon
        off={secureTextEntry}
        onPress={() => setSecureTextEntry(!secureTextEntry)}
      />}
      secureTextEntry={secureTextEntry}
      {...props}
    />
  )
}
