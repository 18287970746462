import { useApi } from './api'

export function usePollVote (organizationId, pollId) {
  const send = useApi()
  const path = `v1/organizations/${organizationId}/polls/${pollId}/votes`

  return optionIndex => {
    return send('POST', path, { optionIndex })
  }
}
