import { Text } from '@ui-kitten/components'
import { StyleSheet, View } from 'react-native'
import { popoverShadow } from 'app/theme'
import FormStatusLabel from './FormStatusLabel'
import FormSubmissions from './FormSubmissions'
import { HasAccess } from 'app/modules/authentication/HasAccess'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 54,
    flexGrow: 1
  },
  info: {
    flexGrow: 1,
    gap: 4,
    flexDirection: 'column'
  },
  column: {
    flexDirection: 'column',
    gap: 4,
    paddingHorizontal: 10
  },
  menu: {
    ...popoverShadow
  },
  buttonHidden: {
    opacity: 0
  }
})

export default function FormSummary ({ item }) {
  return (
    <View style={styles.container}>
      <View style={styles.info}>
        <FormStatusLabel isDone={item.content.submitted} />
        <Text category='h6'>{item.title}</Text>
        <Text category="c1" appearance='hint' numberOfLines={2}>{item.description}</Text>
      </View>
      <HasAccess scope='*:submissions:read'>
        <View style={styles.column}>
          <FormSubmissions item={item} />
        </View>
      </HasAccess>
    </View>
  )
}
