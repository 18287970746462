import { Button, Input, MenuItem, OverflowMenu } from '@ui-kitten/components'
import { StyleSheet, View } from 'react-native'
import Icon from 'app/components/Icon'
import { useNavigate, useParams } from 'react-router'
import { useRef, useState } from 'react'
import { theme } from 'app/theme'
import { useIsMobile } from 'app/components/layout/mediaQueries'
import { useTranslation } from 'react-i18next'
import { HasAccess } from 'app/modules/authentication/HasAccess'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 0,
    flexGrow: 1,
    alignItems: 'center',
    maxWidth: 590,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme['color-basic-400']
  },
  containerMobile: {
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  input: {
    flexGrow: 1,
    flexShrink: 1,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
    borderWidth: 0
  },
  inputText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  button: {
    borderWidth: 0,
    height: 40,
    minWidth: 150,
    borderRadius: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderColor: theme['color-basic-400'],
    justifyContent: 'space-between',
    backgroundColor: theme['color-basic-100']
  }
})

const sections = ['Items', 'Members']
const placeholders = {
  Items: 'Find articles, files and other stuff',
  Members: 'Find members'
}

export default function SearchBar () {
  const { organizationId, query = '' } = useParams()
  const [value, setValue] = useState(query)
  const navigate = useNavigate()
  const timeout = useRef()
  const [showMenu, setShowMenu] = useState(false)
  const [section, setSection] = useState(sections[0])
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  const search = (value, section) => navigate(`/org/${organizationId}/search/${section.toLowerCase()}/${value}`)

  const onChange = (value) => {
    setValue(value)
    if (timeout.current) clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      search(value, section)
    }, 500)
  }

  const onSectionSelect = (index) => {
    const newSection = sections[index - 1]
    setSection(newSection)
    setShowMenu(false)
    search(value, newSection)
  }

  return (
    <View style={[styles.container, isMobile && styles.containerMobile]}>
      <Input
        value={value}
        placeholder={t(placeholders[section])}
        onChangeText={onChange}
        accessoryLeft={props => (
          <Icon name='search' {...props} />
        )}
        style={styles.input}
        textStyle={styles.inputText}
      />
      <HasAccess scope='members:read'>
        <OverflowMenu
          visible={showMenu}
          onBackdropPress={() => setShowMenu(false)}
          onSelect={onSectionSelect}
          placement='bottom end'
          anchor={() => (
            <Button
              style={styles.button}
              appearance='outline'
              status='basic'
              accessoryRight={<Icon name='chevron-down' />}
              onPress={() => setShowMenu(true)}
            >
              {t(section)}
            </Button>
          )}
        >
          {sections.map(section => (
            <MenuItem
              key={section}
              title={t(section)}
            />
          ))}
        </OverflowMenu>
      </HasAccess>
    </View >

  )
}
