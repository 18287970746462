import { useState } from 'react'
import { Text, Tooltip } from '@ui-kitten/components'
import HStack from 'app/components/layout/HStack'
import { useFormContext } from 'react-hook-form'
import Icon from 'app/components/Icon'

const Rule = ({ isValid, description, short }) => {
  const [visible, setVisible] = useState(false)

  return (
    <Tooltip
      anchor={() =>
        <Text status={isValid ? 'success' : 'danger'} onPress={() => setVisible(true)} category='c2'>
          {short}
        </Text>
      }
      visible={visible}
      onBackdropPress={() => setVisible(false)}
    >
      {description}
    </Tooltip>
  )
}

export default function OrganizationRules ({ fieldName = '' }) {
  const { formState: { errors } } = useFormContext()

  if (!errors[fieldName]) return null

  return (
    <HStack alignItems='center' gap={5}>
      <Icon name='alert-triangle-outline' fill='#8F9BB3' />
      <Rule isValid={!errors[fieldName] || errors[fieldName].type !== 'minLength'} short='Min 5' description='Has a minimum of 5 characters' />
    </HStack>
  )
}
