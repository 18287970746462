import Constants from 'expo-constants'
import { isDevice, osName } from 'expo-device'

const getLocalApiUrl = () => {
  if (!isDevice) {
    if (osName === 'iOS') {
      return 'http://localhost:3000'
    } else {
      return 'http://10.0.2.2:3000'
    }
  }
  return ''
}

export const {
  oauthClientId = 'localoauthclientid',
  apiUrl = getLocalApiUrl(),
  googleApiKey = 'AIzaSyB5YzkGlaMNkD1e0O0_ZpE7UmeXfZ9PqUc',
  maxFileSize = 1048576,
  websiteUrl = 'http://localhost:3001'
} = Constants.expoConfig.extra
