const REGEX_EMAIL_VALIDATION = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const passwordValidations = {
  validLength: password => password.length >= 8,
  hasLowerCase: password => password.toUpperCase() !== password,
  hasUpperCase: password => password.toLowerCase() !== password,
  hasNumber: password => /\d/.test(password),
  hasSpecialChar: password => /[ `!@#$%^&*()_+\-=\]{};':"\\//|,.<>?~]/.test(password)
}

const ITEM_ID_MATCHER = /(item|article|event|poll|files)#(\d+)/

export const REGEX_NUMBER_VALIDATION = /^\d+$/

export const REGEX_PHONE_VALIDATION = /^\d{8,}$/

export {
  REGEX_EMAIL_VALIDATION,
  passwordValidations,
  ITEM_ID_MATCHER
}
