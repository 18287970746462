import { Calendar, Input, NativeDateService, Text } from '@ui-kitten/components'
import { Button } from 'app/i18n'
import { StyleSheet, View } from 'react-native'
import { useState } from 'react'
import Stack from 'app/components/layout/Stack'
import Icon from 'app/components/Icon'
import { format, subHours } from 'date-fns'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  input: {
    width: 30,
    textAlign: 'center'
  },
  time: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12
  },
  selected: {
    backgroundColor: 'color-basic-400'
  }
})

export default function DateTimePicker ({ date, onDone }) {
  const [pm, setPm] = useState(format(date, 'aaa') === 'pm')
  const [tmpDate, setTmpDate] = useState(new Date(pm ? subHours(date, 12) : date))
  const [hours, setHours] = useState(format(tmpDate, 'KK'))
  const [minutes, setMinutes] = useState(format(tmpDate, 'mm'))
  const { t, i18n } = useTranslation()

  const onPress = () => {
    tmpDate.setHours(pm ? Number(hours) + 12 : Number(hours))
    tmpDate.setMinutes(Number(minutes))
    onDone(tmpDate)
  }

  const i18nConfig = {
    dayNames: {
      short: [t('Sun'), t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat')],
      long: [t('Sunday'), t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday')]
    },
    monthNames: {
      short: [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'), t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')],
      long: [
        t('January'),
        t('February'),
        t('March'),
        t('April'),
        t('May'),
        t('June'),
        t('July'),
        t('August'),
        t('September'),
        t('October'),
        t('November'),
        t('December')
      ]
    }
  }

  return (
    <Stack>
      <Calendar
        date={tmpDate}
        onSelect={setTmpDate}
        min={new Date()}
        dateService={new NativeDateService(i18n.language, { i18n: i18nConfig })}
      />
      <View style={styles.time}>
        <Icon name='clock-outline' size={24} />
        <Input
          size='large'
          maxLength={2}
          textStyle={styles.input}
          value={hours}
          onChangeText={text => setHours(text)}
        />
        <Text category='h2'>:</Text>
        <Input
          size='large'
          maxLength={2}
          textStyle={styles.input}
          value={minutes}
          onChangeText={text => setMinutes(text)}
        />
        <Button onPress={() => setPm(false)} appearance={pm ? 'ghost' : 'outline'}>
          AM
        </Button>
        <Button onPress={() => setPm(true)} appearance={pm ? 'outline' : 'ghost'}>
          PM
        </Button>
      </View>
      <Button onPress={onPress}>Set</Button>
    </Stack>
  )
}
