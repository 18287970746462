import { useUserSubmissions } from 'app/api/forms'
import { useSession } from 'app/components/SessionContext'
import FormSubmit from './FormSubmit'

export default function FormSubmitted ({ item }) {
  const { accessToken } = useSession()
  const { submissions } = useUserSubmissions(item.organizationId, item.id, accessToken.sub)

  if (!submissions) return null

  return (
    <FormSubmit
      item={item}
      submission={submissions[0]}
    />
  )
}
