import { StyleSheet } from 'react-native'
import Icon from 'app/components/Icon'
import ButtonAsync from './ButtonAsync'
import { useStyleSheet } from '@ui-kitten/components'
import { useState } from 'react'

const themedStyles = StyleSheet.create({
  icon: {
    fill: 'color-basic-500'
  },
  iconOver: {
    fill: 'color-danger-500'
  }
})

export default function ButtonRemove ({ onPress, size, ...props }) {
  const styles = useStyleSheet(themedStyles)
  const [over, setOver] = useState(false)

  return (
    <ButtonAsync
      onPress={onPress}
      onMouseEnter={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      appearance='ghost'
      status='basic'
      size='tiny'
      {...props}
    >
      <Icon
        name='trash-2-outline'
        fill={over ? styles.iconOver.fill : styles.icon.fill}
        size={size}
      />
    </ButtonAsync>
  )
}
