import FormForm from 'app/modules/items/forms/FormForm'
import ArticleForm from './ArticleForm'
import PollForm from './PollForm'
import EventForm from 'app/modules/items/events/EventForm'
import FilesForm from 'app/modules/items/files/FilesForm'
import UpdateForm from 'app/modules/items/updates/UpdateForm'

export default function CreateItemForm ({ type, onSubmit, organizationId, submitLabel }) {
  switch (type) {
    case 'article':
      return <ArticleForm onSubmit={onSubmit} organizationId={organizationId} submitLabel={submitLabel}/>
    case 'poll':
      return <PollForm onSubmit={onSubmit} submitLabel={submitLabel}/>
    case 'event':
      return <EventForm onSubmit={onSubmit} organizationId={organizationId} submitLabel={submitLabel}/>
    case 'files':
      return <FilesForm onSubmit={onSubmit} organizationId={organizationId} submitLabel={submitLabel}/>
    case 'form':
      return <FormForm onSubmit={onSubmit} organizationId={organizationId} submitLabel={submitLabel}/>
    case 'update':
      return <UpdateForm onSubmit={onSubmit} organizationId={organizationId} submitLabel={submitLabel}/>
  }
}
