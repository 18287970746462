import { Pressable } from 'react-native'
import DateTimeBox from './DateTimeBox'
import ModalBox from 'app/components/ModalBox'
import { useState } from 'react'
import { useController } from 'react-hook-form'
import DateTimePicker from './DateTimePicker'

export default function DateTimeInput ({ name, defaultValue, rules, label }) {
  const [showCalendar, setShowCalendar] = useState(false)
  const { field: { onChange, value } } = useController({ name, defaultValue, rules })
  const valueDate = !(value instanceof Date) ? new Date(value) : value

  const onDone = date => {
    setShowCalendar(false)
    onChange(date)
  }

  return (
    <>
      <Pressable
        onPress={() => setShowCalendar(true)}
      >
        <DateTimeBox date={valueDate} label={label} />
      </Pressable>
      <ModalBox
        visible={showCalendar}
        onDismiss={() => setShowCalendar(false)}
      >
        <DateTimePicker date={valueDate} onDone={onDone} />
      </ModalBox>
    </>
  )
}
