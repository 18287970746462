import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin'
import { ITEM_ID_MATCHER } from 'app/validations'

export default function RelatedItemsAutolLinkPlugin () {
  const matchItemId = (text) => {
    const match = ITEM_ID_MATCHER.exec(text)
    if (match === null) {
      return null
    }
    const fullMatch = match[0]

    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch
      // attributes: { rel: 'noreferrer', target: '_blank' }, // Optional link attributes
    }
  }

  return (
    <AutoLinkPlugin matchers={[matchItemId]} />
  )
}
