import { ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg'

export default function FormatQuoteIcon ({ size = 24, color = '#fff' }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <G clipPath="url(#clip0_1397_10508)">
        <Path d="M6 17H9L11 13V7H5V13H8L6 17ZM14 17H17L19 13V7H13V13H16L14 17Z" fill={color} />
      </G>
      <Defs>
        <ClipPath id="clip0_1397_10508">
          <Rect width="24" height="24" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
