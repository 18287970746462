import { Platform } from 'react-native'
import { useApi } from './api'
import useSWR from 'swr'

export function useFileUpload (organizationId) {
  const send = useApi()

  const upload = async (file, fileName) => {
    const formData = new FormData()
    formData.append('file', file)
    return send('POST', `v1/organizations/${organizationId}/files`, formData)
  }

  const remove = async (fileId) => {
    return send('DELETE', `v1/organizations/${organizationId}/files/${fileId}`)
  }

  const getFileURL = async (fileId) => {
    const response = await send('GET', `v1/organizations/${organizationId}/files/${fileId}/contents`)

    if (response.ok) {
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)
      return url
    } else {
      throw new Error('Failed to get file URL')
    }
  }

  return {
    upload,
    remove,
    getFileURL
  }
}

export function useFileLocalUrl (organizationId, fileId) {
  const path = `v1/organizations/${organizationId}/files/${fileId}/contents`
  const send = useApi()
  const { data } = useSWR(path, async url => {
    const response = await send('GET', url)

    if (response.ok) {
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)
      return url
    } else {
      throw new Error('Failed to get file URL')
    }
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false
  })

  return data
}

export function useFileDownload () {
  const send = useApi()

  return async (organizationId, fileId, fileName) => {
    const response = await send('GET', `v1/organizations/${organizationId}/files/${fileId}/contents`)
    if (Platform.OS === 'web') {
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      // Create an anchor element to trigger the download
      const a = document.createElement('a')
      a.href = url
      a.download = fileName // Specify the desired file name
      a.style.display = 'none'

      // Append the anchor element to the document and trigger a click event
      document.body.appendChild(a)
      a.click()

      // Clean up by revoking the URL
      window.URL.revokeObjectURL(url)
    }
  }
}
