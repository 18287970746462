import { useEffect, useRef, useState } from 'react'
import { useWatch } from 'react-hook-form'
import MapView from './MapView'

export default function MapPreview ({
  name
}) {
  const geocoderRef = useRef()
  const timeoutRef = useRef()
  const value = useWatch({ name })
  const [center, setCenter] = useState()
  const [markerPosition, setMakerPosition] = useState()
  const [isAddress, setIsAddress] = useState(true)

  useEffect(() => {
    geocoderRef.current = new window.google.maps.Geocoder()
  })

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (value && !value.startsWith('http')) {
      const timeout = setTimeout(() => {
        geocoderRef.current.geocode({ address: value }, (results, status) => {
          if (status === 'OK') {
            const position = results[0].geometry.location
            setIsAddress(true)
            setMakerPosition(position)
            setCenter(new window.google.maps.LatLng(
              position.lat() + 0.15,
              position.lng()
            ))
          } else {
            setIsAddress(false)
          }
        })
      }, 1000)
      timeoutRef.current = timeout
    } else {
      setIsAddress(false)
    }
  }, [value])

  if (!isAddress) return null

  return <MapView center={center} markerPosition={markerPosition} />
}
