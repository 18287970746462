import { Button, useStyleSheet } from '@ui-kitten/components'
import { StyleSheet } from 'react-native'
import { View } from 'react-native-web'
import { Outlet, useNavigate } from 'react-router'
import LeftMenu from 'app/modules/menu/LeftMenu'
import TopBar from './TopBar'
import Isologo from 'app/components/images/Isologo'
import Icon from 'app/components/Icon'
import { useTranslation } from 'react-i18next'
import { HasAccess } from 'app/modules/authentication/HasAccess'

const themedStyles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'stretch',
    width: '100%',
    height: '100vh'
  },
  right: {
    flexGrow: 1,
    flexShrink: 1,
    paddingBottom: 0,
    gap: 20
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
    padding: 20
  },
  top: {
    backgroundColor: 'color-basic-100',
    padding: 20
  },
  sidebar: {
    width: 80,
    backgroundColor: 'color-basic-100'
  },
  sidebarContent: {
    padding: 25,
    alignItems: 'center'
  },
  menu: {
    flexGrow: 1
  },
  bottom: {
    padding: 15
  },
  publishButton: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    borderRadius: 50
  }
})

export default function OrganizationWebLayout () {
  const styles = useStyleSheet(themedStyles)
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <View style={styles.sidebar}>
        <View style={styles.sidebarContent}>
          <Isologo />
        </View>
        <View style={styles.menu}>
          <LeftMenu />
        </View>
      </View>
      <View style={styles.right}>
        <View style={styles.top}>
          <TopBar />
        </View>
        <View style={styles.content}>
          <Outlet />
        </View>
      </View>
      <HasAccess scope='items:write'>
        <Button
          status='primary'
          accessoryRight={<Icon name='plus' />}
          size='large'
          onPress={() => navigate('publish')}
          style={styles.publishButton}
        >
          {t('Publish')}
        </Button>
      </HasAccess>
    </View>
  )
}
