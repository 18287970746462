import { StyleSheet, View } from 'react-native'
import HStack from 'app/components/layout/HStack'
import { Button, Text } from 'app/i18n'
import Icon from 'app/components/Icon'
import { theme } from 'app/theme'
import { useIsMobile } from 'app/components/layout/mediaQueries'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    padding: 24,
    gap: 5,
    borderRadius: 5,
    backgroundColor: theme['color-basic-1000']
  },
  containerInline: {
    borderRadius: 0
  },
  errors: {
    flexDirection: 'column',
    gap: 8,
    flexGrow: 1,
    flexShrink: 1
  },
  text: {
    color: theme['color-basic-100']
  }
})

const icons = {
  danger: {
    name: 'close-circle-outline',
    fill: theme['color-danger-500']
  },
  success: {
    name: 'checkmark-circle',
    fill: theme['color-success-500']
  },
  info: {
    name: 'info-outline',
    fill: theme['color-info-500']
  }
}

export default function MessageCard ({ title, description, type = 'danger', children, onDismiss, isInline }) {
  const isMobile = useIsMobile()

  return (
    <View style={[styles.container, styles.danger, isInline && styles.containerInline]}>
      <HStack alignItems='center' gap={18}>
        <Icon name={icons[type].name} fill={icons[type].fill} size={30} />
        <View style={styles.errors}>
          <Text category='s1' style={styles.text}>{title}</Text>
          <Text category="c1" style={styles.text}>{description}</Text>
          {children}
        </View>
        {onDismiss && !isMobile && (
          <Button
            appearance='outline'
            status='basic'
            size='small'
            onPress={onDismiss}
          >
            Dismiss
          </Button>
        )}
      </HStack>
    </View>
  )
}
