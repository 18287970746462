import React from 'react'
import { StyleSheet, Image } from 'react-native'
import { useFileLocalUrl } from 'app/api/files'

const styles = StyleSheet.create({
  image: {
    width: '100%',
    height: '100%'
  }
})

export default function ImageUploaded ({ organizationId, fileId, style }) {
  const localFileUrl = useFileLocalUrl(organizationId, fileId)

  return (
    <Image
      source={{ uri: localFileUrl }}
      style={[styles.image, style]}
    />
  )
}
