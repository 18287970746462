import { useFormContext } from 'react-hook-form'
import ButtonAsync from 'app/components/buttons/ButtonAsync'
import { useIsMobile } from 'app/components/layout/mediaQueries'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  buttonMobile: {
    flexGrow: 1
  }
})

export default function ButtonSubmit ({ children, onSubmit, style, ...props }) {
  const { handleSubmit } = useFormContext()
  const isMobile = useIsMobile()

  return (
    <ButtonAsync
      onPress={handleSubmit(onSubmit)}
      style={[isMobile && styles.buttonMobile, style]}
      {...props}
    >
      {children}
    </ButtonAsync>
  )
}
