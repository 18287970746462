import { StyleSheet, View } from 'react-native'
import { Text } from '@ui-kitten/components'
import Cube from 'app/components/images/Cube'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  container: {
    gap: 12,
    alignItems: 'center',
    minHeight: 200,
    justifyContent: 'center'
  },
  text: {
    color: '#8F9BB3',
    fontWeight: '700',
    textTransform: 'uppercase'
  }
})

export default function NoItemCard () {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <Cube />
      <Text style={styles.text}>{t("Looks like there's nothing here yet")}</Text>
    </View>
  )
}
