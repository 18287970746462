import { Button } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'
import ButtonAsync from 'app/components/buttons/ButtonAsync'
import { useState } from 'react'
import Icon from 'app/components/Icon'
import { StyleSheet } from 'react-native'
import { usePollVote } from 'app/api/polls'
import PollVoteResults from './PollVoteResults'
import PollResults from './PollResults'
import { useTranslation } from 'react-i18next'
import CardSingleButton from 'app/components/cards/CardSingleButton'

const styles = StyleSheet.create({
  button: {
    justifyContent: 'space-between',
    height: 46
  }
})

export default function PollContent ({ item }) {
  const [selected, setSelected] = useState()
  const [voted, setVoted] = useState()
  const vote = usePollVote(item.organizationId, item.id)
  const { t } = useTranslation()

  const onSubmit = async () => {
    try {
      await vote(selected)
    } catch (err) {

    }
    setVoted(true)
  }

  if (item.content.voted) {
    return (
      <PollResults poll={item.content} />
    )
  }

  if (voted) {
    return (
      <PollVoteResults organizationId={item.organizationId} itemId={item.itemId} />
    )
  }

  return (
    <Stack>
      {item.content.options.map((option, index) => (
        <Button
          key={index}
          style={styles.button}
          appearance='outline'
          status={index === selected ? 'info' : 'basic'}
          onPress={() => setSelected(index)}
          accessoryRight={index === selected && <Icon name='checkmark-outline' />}
        >
          {option.text}
        </Button>
      ))}
      <CardSingleButton>
        <ButtonAsync
          onPress={onSubmit}
          accessoryLeft={props => <Icon name='checkmark-outline' {...props} />}
        >
          {t('Vote')}
        </ButtonAsync>
      </CardSingleButton>
    </Stack>
  )
}
