import ButtonSubmit from 'app/components/forms/ButtonSubmit'
import Form from 'app/components/forms/Form'
import TextInput from 'app/components/forms/TextInput'
import Stack from 'app/components/layout/Stack'
import Alert from 'app/components/forms/Alert'
import { useState } from 'react'
import { createUser } from 'app/api/users'
import { REGEX_EMAIL_VALIDATION } from 'app/validations'
import { useTranslation } from 'react-i18next'
import { getLocales } from 'expo-localization'

const language = getLocales()[0].languageCode

export default function SignUpForm ({ onCompleted }) {
  const [hasError, setHasError] = useState(false)
  const { t } = useTranslation()

  const onSubmit = async (user) => {
    try {
      setHasError(false)
      await createUser({ ...user })
      onCompleted(user.email)
    } catch (err) {
      if (err.status === 409) {
        setHasError(t('Sorry,  {{email}} is already taken', { email: user.email }))
      } else throw err
    }
  }

  return (
    <Form defaultValues={{ email: '', language }}>
      <Stack alignItems='flex-start' gap={10}>
        {hasError && (
          <Alert type='danger'>{hasError}</Alert>
        )}
        <TextInput
          placeholder='Email'
          name='email'
          rules={{ required: true, pattern: REGEX_EMAIL_VALIDATION }}
        />
      </Stack>
      <ButtonSubmit onSubmit={onSubmit}>
        {t('Send')}
      </ButtonSubmit>
    </Form>
  )
}
