import CardFrame from 'app/components/cards/CardFrame'
import Stack from 'app/components/layout/Stack'
// import Icon from 'app/components/Icon'
import ProfileInformationTab from './ProfileInformationTab'
// import { Tab, TabBar, Text } from '@ui-kitten/components'
import ProfileHeader from './ProfileHeader'
import { Routes, Route } from 'react-router'
import { useMember } from 'app/api/members'
import MembershipUpdate from './MembershipUpdate'
import { HasAccess } from 'app/modules/authentication/HasAccess'

// const profilePaths = ['', 'forms']
// const memberPaths = ['forms']

export default function ProfileCard ({ organizationId, userId, section }) {
  // const navigate = useNavigate()
  const { member } = useMember(organizationId, userId)

  if (!member) return null

  return (
    <CardFrame>
      <Stack gap={24}>
        <ProfileHeader
          userId={userId}
          organizationId={organizationId}
        />
        {/* {userId === 'me'
            ? (
              <TabBar
                selectedIndex={profilePaths.indexOf(section)}
                onSelect={index => navigate(profilePaths[index])}
              >
                <Tab title='INFORMATION' icon={props => <Icon name='person' {...props} />} />
                <Tab title='FORMS' icon={props => <Icon name='checkmark-square-outline' {...props} />} />
              </TabBar>
            )
            : (
              <TabBar
                selectedIndex={memberPaths.indexOf(section)}
                onSelect={index => navigate(memberPaths[index])}
              >
                <Tab title='FORMS' icon={props => <Icon name='checkmark-square-outline' {...props} />} />
              </TabBar>
            )} */}
        <Routes>
          <Route index element={
            <>
              <HasAccess scope='members:write'>
                <MembershipUpdate
                  organizationId={organizationId}
                  userId={userId}
                />
              </HasAccess>
              {userId === 'me' && (
                <ProfileInformationTab
                  organizationId={organizationId}
                  userId={userId}
                />
              )}
            </>
          } />
          {/* <Route path='forms' element={<Text>Forms</Text>} /> */}
        </Routes>
      </Stack>
    </CardFrame>
  )
}
