import React from 'react'
import { MenuItem } from '@ui-kitten/components'
import Icon from 'app/components/Icon'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

export default function ItemMenuLink ({ to, title, icon }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <MenuItem
      title={t(title)}
      accessoryLeft={<Icon name={icon} />}
      onPress={() => navigate(to)}
    />
  )
}
