import React from 'react'
import StatusLabel from 'app/modules/items/components/StatusLabel'
import { theme } from 'app/theme'

const states = {
  true: {
    label: 'Attending',
    iconName: 'checkmark-circle-2-outline',
    color: theme['color-primary-500']
  },
  false: {
    label: 'Not attending',
    iconName: 'close-circle-outline',
    color: theme['color-danger-500']
  },
  pending: {
    label: 'Pending',
    iconName: 'loader-outline',
    color: theme['color-basic-600']
  }
}

export default function EventStatusLabel ({ isDone }) {
  const { label, iconName, color } = states[isDone] || states.pending

  return <StatusLabel iconName={iconName} color={color} label={label} />
}
