import { Text } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'
import { useTranslation } from 'react-i18next'

export default function FormItem ({ label, children }) {
  const { t } = useTranslation()

  return (
    <Stack gap={5}>
      <Text category='label'>{t(label)}</Text>
      {children}
    </Stack>
  )
}
