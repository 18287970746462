import ButtonAddReaction from './ButtonAddReaction'
import { StyleSheet, View } from 'react-native'
import ButtonReaction from './ButtonReaction'
import { useReactions } from 'app/api/items'
import { useState } from 'react'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    marginTop: 18
  }
})

export default function ReactionsBox ({ item }) {
  const { add, remove } = useReactions(item.organizationId, item.id)
  const [reactions, setReactions] = useState(item.reactions)
  const [userReactions, setUserReactions] = useState(item.userReaction ? item.userReaction.reactions : [])

  const onReactionAdd = async (reaction) => {
    if (!userReactions.includes(reaction)) {
      await add(reaction)
      setReactions({
        ...reactions,
        [reaction]: reactions[reaction] ? reactions[reaction] + 1 : 1
      })
      setUserReactions([...userReactions, reaction])
    }
  }

  const onReactionRemove = async (reaction) => {
    await remove(reaction)
    setReactions({
      ...reactions,
      [reaction]: reactions[reaction] - 1
    })
    setUserReactions(userReactions.filter(r => r !== reaction))
  }

  return (
    <View style={styles.container}>
      <ButtonAddReaction
        organizationId={item.organizationId} id={item.id}
        onPressEmoji={(reaction) => onReactionAdd(reaction)}
      />
      {Object.entries(reactions).map(([reaction, count]) => (
        count > 0 &&
        <ButtonReaction
          onReactionAdd={() => onReactionAdd(reaction)}
          onReactionRemove={() => onReactionRemove(reaction)}
          key={reaction}
          reaction={reaction}
          count={count}
          reacted={userReactions.includes(reaction)}
        />
      ))}
    </View>
  )
}
