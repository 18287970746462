import AsyncStorage from '@react-native-async-storage/async-storage'
import EnableNotificationsModal from './EnableNotificationsModal'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-native'
import { NotificationsClient } from 'app/notifications/client'

const getAskedByOrganization = async () => JSON.parse(await AsyncStorage.getItem('notifications-asked') || '{}')
const saveAskedByOrganization = (asked) => AsyncStorage.setItem('notifications-asked', JSON.stringify(asked))

export default function CheckDeviceNotifications () {
  const [open, isOpen] = useState(false)
  const { organizationId } = useParams()

  useEffect(() => {
    const shouldAsk = async () => {
      const asked = await getAskedByOrganization()
      if (!asked[organizationId]) {
        isOpen(true)
      }
    }
    NotificationsClient.canPushNotifications() && shouldAsk()
  }, [])

  const dontAskAgain = async () => {
    const asked = await getAskedByOrganization()
    asked[organizationId] = true
    await saveAskedByOrganization(asked)
    isOpen(false)
  }

  if (open) {
    return (
      <EnableNotificationsModal
        organizationId={organizationId}
        onDismiss={() => isOpen(false)}
        onEnable={dontAskAgain}
        onReject={dontAskAgain}
      />
    )
  }

  return null
}
