import { Navigate } from 'react-router'
import { useUserProfile } from 'app/api/users'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function VerifyUserSetup ({ children }) {
  const { user } = useUserProfile()
  const { i18n } = useTranslation()

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.language)
    }
  }, [user])

  if (!user) return null

  if (user.name === null || user.surname === null) {
    return <Navigate replace to='/user-setup' />
  } else {
    return children
  }
}
