import { Divider, Text } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'
import SignUpForm from 'app/modules/authentication/SignUpForm'
import ChangePasswordForm from 'app/modules/authentication/ChangePasswordForm'
import { useState } from 'react'
import { Link } from 'app/components/Link'
import { useSearchParams } from 'app/multiplatform/router'
import { useTranslation } from 'react-i18next'
import LanguageSwitch from './LanguageSwitch'

export default function SignUpScreen () {
  const [searchParams] = useSearchParams()
  const [email, setEmail] = useState(searchParams.get('email'))
  const { t } = useTranslation()

  if (email) {
    return (
      <Stack gap={30}>
        <Text category='h2'>{t('Account setup')}</Text>
        <Text>
          {t('Welcome to InCircl! Please set your password to complete your registration')}
        </Text>
        <ChangePasswordForm
          code={searchParams.get('code')}
          email={email}
          submitLabel={t('Create account')}
          successLabel={t('Your account has been created! You can now log in.')}
          link={'/'}
        />
      </Stack>
    )
  } else {
    return (
      <Stack gap={30}>
        <Stack gap={5}>
          <Text category='h2'>{t('Welcome to InCircl!')}</Text>
          <Text>{t('Let\'s begin, please enter your email to register:')}</Text>
        </Stack>
        <SignUpForm onCompleted={email => setEmail(email)} />
        <Divider />
        <Stack>
          <Link to='/'>Back</Link>
        </Stack>
        <Stack>
          <LanguageSwitch />
        </Stack>
      </Stack>
    )
  }
}
