import MemberList from 'app/modules/members/components/MemberList'
import { useParams } from 'react-router-native'
import ScreenContent from 'app/components/layout/ScreenContent'
import { useState } from 'react'
import InviteMembersModal from 'app/modules/members/invitations/InviteMembersModal'

export function MembersHomeScreen () {
  const { organizationId } = useParams()
  const [addMember, setAddMember] = useState(false)

  return (
    <ScreenContent
      title='Members'
      onAddPress={() => setAddMember(true)}
    >
      {addMember && (
        <InviteMembersModal
          onDismiss={() => setAddMember(false)} organizationId={organizationId}
        />
      )}
      <MemberList organizationId={organizationId} />
    </ScreenContent>
  )
}
