import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import ButtonCircle from 'app/components/buttons/ButtonCircle'
import Icon from 'app/components/Icon'
import { theme } from 'app/theme'
import AddFormFieldButton from './AddFormFieldButton'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'flex-start',
    gap: 8
  },
  optionsContainer: {
    backgroundColor: theme['color-basic-200'],
    borderRadius: 99,
    flexDirection: 'row',
    padding: 8,
    gap: 8
  }
})

export default function AddFormField ({ onAddField }) {
  const [showOptions, setShowOptions] = useState(false)

  const handleAddField = (item) => {
    onAddField(item)
    setShowOptions(false)
  }

  return (
    <View style={styles.container}>
      <ButtonCircle icon={<Icon name='plus-circle' size={42} />} onPress={() => setShowOptions(!showOptions)} />
      {showOptions && (
        <View style={styles.optionsContainer}>
          <AddFormFieldButton
            icon='text'
            onPress={() => handleAddField({ type: 'text' })}
          />
          <AddFormFieldButton
            icon='attach-2-outline'
            onPress={() => handleAddField({ type: 'file' })}
          />
          <AddFormFieldButton
            icon='checkmark-square'
            onPress={() => handleAddField({ type: 'select' })}
          />
          <AddFormFieldButton
            icon='radio-button-on-outline'
            onPress={() => handleAddField({ type: 'select', maxSelections: 1 })}
          />
          <AddFormFieldButton
            icon='phone-outline'
            onPress={() => handleAddField({ type: 'text', validation: 'phone' })}
          />
          <AddFormFieldButton
            icon='hash'
            onPress={() => handleAddField({ type: 'text', validation: 'number' })}
          />
          <AddFormFieldButton
            icon='email-outline'
            onPress={() => handleAddField({ type: 'text', validation: 'email' })}
          />
        </View>
      )}
    </View>
  )
}
