import { StyleSheet, View } from 'react-native'
import { useStyleSheet } from '@ui-kitten/components'
import { useController } from 'react-hook-form'
import { useFileUpload } from 'app/api/files'
import FileItem from './FileItem'
import CardContent from 'app/components/cards/CardContent'
import FilesPicker from './FilesPicker'
import ButtonRemove from 'app/components/buttons/ButtonRemove'
import UploadArea from './UploadArea'
import { useNotifications } from 'app/components/notifications/NotificationsContext'
import { ApiError } from 'app/api/errors'
import { maxFileSize } from 'app/config'
import { useTranslation } from 'react-i18next'

const themedStyles = StyleSheet.create({
  container: {
    flexDirection: 'column'
  },
  fileUploadArea: {
    backgroundColor: 'color-basic-200',
    flexDirection: 'row',
    flexGrow: 1
  },
  icon: {
    fill: 'color-basic-600'
  },
  text: {
    color: 'color-basic-600'
  },
  files: {
    flexDirection: 'column',
    gap: 12
  },
  file: {
    flexDirection: 'row',
    gap: 12,
    justifyContent: 'space-between'
  }
})

const MB = 1024 * 1024
const maxSizeMB = Math.round(maxFileSize / MB)

export default function FilesEdit ({ name, organizationId, disabled, defaultValue = [] }) {
  const styles = useStyleSheet(themedStyles)
  const { upload, remove } = useFileUpload(organizationId)
  const { field: { onChange, value } } = useController({
    name,
    defaultValue,
    rules: {
      validate: data => {
        if ((data && data.length > 0) || disabled) return true
        return 'At least one file is required'
      }
    }
  })
  const { notify } = useNotifications()
  const { t } = useTranslation()

  const uploadFiles = async (files) => {
    const fileUploads = []

    for (const file of files) {
      if (file.size > maxFileSize) {
        notify(
          t('File upload failed'),
          t('File size is too big ({{maxFileSize}}MB max)', { maxFileSize: maxSizeMB }),
          'danger'
        )
      } else {
        try {
          const fileUpload = await upload(file, file.name)
          fileUploads.push({
            fileId: fileUpload.id,
            name: file.name,
            size: file.size,
            mimeType: file.type
          })
        } catch (error) {
          if (error instanceof ApiError) {
            if (error.status === 422 && error.message.includes('expected size')) {
              const maxSizeFromText = error.message.match(/(\d+)/)[0]
              const maxSizeMB = Math.round(maxSizeFromText / MB)
              notify(
                t('File upload failed'),
                t('File size is too big ({{maxFileSize}}MB max)', { maxFileSize: maxSizeMB }),
                'danger'
              )
            } else {
              notify(
                t('File upload failed'),
                t('An error occurred while uploading the file'),
                'danger'
              )
            }
          } else throw error
        }
      }
    }

    onChange([...value, ...fileUploads])
  }

  const removeFile = async (index) => {
    const file = value[index]
    await remove(file.fileId)
    onChange([...value.filter((_, i) => i !== index)])
  }

  return (
    <View style={styles.container}>
      <View
        style={styles.fileUploadArea}
      >
        <FilesPicker
          onFilesSelected={uploadFiles}
          disabled={disabled}
        >
          <UploadArea />
        </FilesPicker>
      </View>
      <CardContent>
        <View style={styles.files}>
          {value.map((file, index) => (
            <View key={index} style={styles.file}>
              <FileItem file={file} />
              <ButtonRemove onPress={() => removeFile(index)} />
            </View>
          ))}
        </View>
      </CardContent>
    </View>
  )
}
