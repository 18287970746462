import { useWatch } from 'react-hook-form'
import Stack from 'app/components/layout/Stack'
import TextInput from './TextInput'
import { animated, useTransition } from 'app/components/animations/spring'
import { View } from 'react-native'

const AnimatedView = animated(View)

const getName = (name, i, subName) => `${name}.${i}${subName ? `.${subName}` : ''}`

export default function TextInputMultiple ({ min = 1, max, name, subName, rules, ...props }) {
  const values = useWatch({ name })
  const notEmptyValues = (values || []).filter(v => subName ? v[subName] : v)
  const arrayOfKeys = Array.from((new Array(Math.min(notEmptyValues.length + 1, max))).keys()).slice(min)
  const transitions = useTransition(arrayOfKeys, {
    from: {
      opacity: 0,
      top: -10
    },
    enter: {
      opacity: 1,
      top: 0
    },
    leave: {
      opacity: 0,
      top: -10
    }
  })

  return (
    <Stack gap={12}>
      {Array(min).fill().map((_, i) => (
        <TextInput
          key={i}
          name={getName(name, i, subName)}
          rules={rules}
          defaultValue=''
          {...props}
        />
      ))}
      {transitions((style, i) => {
        const isLast = i === arrayOfKeys[arrayOfKeys.length - 1]
        return (
          <AnimatedView key={i} style={style}>
            <TextInput
              name={getName(name, i, subName)}
              rules={isLast ? undefined : rules}
              defaultValue=''
              {...props}
            />
          </AnimatedView>
        )
      })}
    </Stack>
  )
}
