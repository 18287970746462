import MemberInfo from 'app/modules/members/components/MemberInfo'
import { TableCell, TableRow } from 'app/components/Table'
import { Text } from '@ui-kitten/components'
import { useTranslation } from 'react-i18next'
import useLocalDateFormat from 'app/i18n/useLocalDateFormat'

export default function EventAttendanceListItem ({ attendance, organizationId }) {
  const { t } = useTranslation()
  const format = useLocalDateFormat()

  return (
    <TableRow>
      <TableCell isPrimary>
        <MemberInfo user={attendance.member.user} member={attendance.member} size='small' />
      </TableCell>
      <TableCell>
        <Text category='c1'>{attendance.attending ? t('Yes') : t('No')}</Text>
      </TableCell>
      <TableCell>
        <Text category='c1' appearance='hint'>
          {format(new Date(attendance.createdAt), 'EEE LLL do y')}
        </Text>
      </TableCell>
    </TableRow>
  )
}
