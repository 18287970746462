import React, { useState } from 'react'
import { MenuItem } from '@ui-kitten/components'
import { usePinnedItems } from 'app/api/items'
import Icon from 'app/components/Icon'
import { useNotifications } from 'app/components/notifications/NotificationsContext'
import { useTranslation } from 'react-i18next'

export default function ItemMenuPin ({ item }) {
  const { pin, unpin } = usePinnedItems(item.organizationId)
  const [pinned, setPinned] = useState(item.pinned)
  const { notify } = useNotifications()
  const { t } = useTranslation()

  const togglePin = async () => {
    if (pinned) {
      await unpin(item.itemId)
      setPinned(false)
      notify(t('Item unpinned'), t('Item #{{itemId}} was unpinned', { itemId: item.itemId }), 'info')
    } else {
      await pin(item.itemId)
      setPinned(true)
      notify(t('Item pinned'), t('Item #{{itemId}} was pinned', { itemId: item.itemId }), 'info')
    }
  }

  return (
    <MenuItem
      title={pinned ? t('Unpin') : t('Pin')}
      accessoryLeft={<Icon name='bookmark-outline' />}
      onPress={togglePin}
    />
  )
}
