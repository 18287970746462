import { Controller, useFormContext } from 'react-hook-form'
import PinInput from './PinInput'

export default function PinInputField ({ name, placeholder, rules, ...props }) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error }
      }) => (
        <PinInput
          value={value}
          onChange={onChange}
          status={error ? 'danger' : 'basic'}
          {...props}
        />
      )}
    />
  )
}
