export class ApiError extends Error {
  constructor (response, status) {
    super(response.message)

    this.status = status
    this.response = response
  }
}

export async function checkApiResponseErrors (response) {
  if (response.status >= 400) {
    if (response.status < 500) {
      throw new ApiError(await response.json(), response.status)
    } else {
      throw new Error(response.status)
    }
  }
}
