import { Text } from '@ui-kitten/components'
import HStack from 'app/components/layout/HStack'
import ButtonCircle from 'app/components/buttons/ButtonCircle'
import Icon from 'app/components/Icon'
import { useTranslation } from 'react-i18next'

const { StyleSheet, View } = require('react-native')

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  header: {
    flexDirection: 'row',
    gap: 10
  },
  column: {
    flexDirection: 'column',
    gap: 4
  },
  title: {
    textTransform: 'capitalize'
  },
  section: {
    textTransform: 'capitalize'
  },
  description: {
    color: '#8F9BB3'
  }
})

export default function Header ({ description, section, title, onAddPress, children }) {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.column}>
          <Text category={section ? 'h5' : 'h3'} style={styles.title}>{t(title)}</Text>
          {description && <Text category='p1' style={styles.description}>{t(description)}</Text>}
        </View>
        {onAddPress && <ButtonCircle icon={<Icon name='plus-circle' size={35} />} onPress={onAddPress} />}
      </View>
      <HStack>
        {children}
      </HStack>
    </View>
  )
}
