import { useEffect, useRef, useState } from 'react'
import { FORMAT_TEXT_COMMAND } from 'lexical'
import { computePosition } from '@floating-ui/dom'
import { Button, ButtonGroup } from '@ui-kitten/components'
import { View } from 'react-native'
import FormatBoldIcon from './icons/FormatBoldIcon'
import FormatItalicIcon from './icons/FormatItalicIcon'
import FormatQuoteIcon from './icons/FormatQuoteIcon'
import FormatHeadingIcon from './icons/FormatHeadingIcon'

export default function EditorTextMenu ({ show, editor, onQuote, onHeading, ...props }) {
  const ref = useRef()
  const [pos, setPos] = useState()
  const nativeSel = window.getSelection()

  useEffect(() => {
    const isCollapsed = nativeSel?.rangeCount === 0 || nativeSel?.isCollapsed

    if (!show || !ref.current || !nativeSel || isCollapsed) {
      setPos(undefined)
      return
    }
    const domRange = nativeSel.getRangeAt(0)

    computePosition(domRange, ref.current, { placement: 'top' })
      .then((pos) => {
        setPos({ x: pos.x, y: pos.y - 10 })
      })
    // anchorOffset, so that we sync the menu position with
    // native selection (if user selects two ranges consecutively)
  }, [show, nativeSel, nativeSel.anchorOffset])

  return (
    <View ref={ref} style={{ top: pos?.y, left: pos?.x, display: show ? 'block' : 'none', position: 'absolute' }}>
      <ButtonGroup size='small' status='info'>
        <Button
          accessoryRight={<FormatBoldIcon />}
          aria-label="Format text as bold"
          active={props.isBold}
          onPress={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
          }}
        />
        <Button
          accessoryRight={<FormatItalicIcon />}
          aria-label="Format text as italics"
          active={props.isItalic}
          onPress={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
          }}
        />
        <Button
          accessoryRight={<FormatQuoteIcon />}
          aria-label="Format text to quote"
          active={props.isQuote}
          onPress={onQuote}
        />
        <Button
          accessoryRight={<FormatHeadingIcon />}
          aria-label="Format text to heading"
          active={props.isHeading}
          onPress={onHeading}
        />
      </ButtonGroup>
    </View>
  )
}
