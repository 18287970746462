import Text from 'app/i18n/Text'
import EditableSectionButtons from 'app/components/forms/EditableSectionButtons'
import HStack from 'app/components/layout/HStack'

export default function EditableSectionHeader ({ title, onSave }) {
  return (
    <HStack alignItems='center' justifyContent='space-between'>
      <Text category='h6'>{title}</Text>
      <EditableSectionButtons onSave={onSave} />
    </HStack>
  )
}
