import React from 'react'
import StatusLabel from 'app/modules/items/components/StatusLabel'
import { theme } from 'app/theme'

const states = {
  true: {
    iconName: 'checkmark-circle-2-outline',
    color: theme['color-primary-500'],
    label: 'Submitted'
  },
  false: {
    iconName: 'close-circle-outline',
    color: theme['color-basic-600'],
    label: 'Not submitted'
  }
}

export default function FormStatusLabel ({ isDone }) {
  const { iconName, color, label } = states[isDone]

  return <StatusLabel iconName={iconName} color={color} label={label} />
}
