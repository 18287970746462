import { useApi } from 'app/api/api'
import { SWRConfig } from 'swr'

export default function SWRProvider ({ children }) {
  const send = useApi()

  return (
    <SWRConfig
      value={{
        fetcher: url => send('GET', url),
        refreshInterval: 60000 * 15
      }}
    >
      {children}
    </SWRConfig>
  )
}
