import { Text } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'
import HStack from 'app/components/layout/HStack'
import EditableSectionHeader from 'app/components/forms/EditableSectionHeader'
import { EditableSection } from 'app/components/forms/EditableSection'
import EditableField from 'app/components/forms/EditableField'
import Form from 'app/components/forms/Form'
import TextInputInline from 'app/components/forms/TextInputInline'
import { useTranslation } from 'react-i18next'
import { useMember } from 'app/api/members'
import RoleSelect from 'app/modules/members/components/RoleSelect'

export default function MemberInformationEditable ({ organizationId, userId }) {
  const { member, update } = useMember(organizationId, userId)
  const { t } = useTranslation()

  if (!member) return null

  return (
    <Stack gap={24}>
      <EditableSection>
        <Form defaultValues={member}>
          <EditableSectionHeader
            title='Membership Details'
            onSave={update}
          />
          <Stack>
            <HStack justifyContent='space-between'>
              <EditableField
                label='Title, job or position'
                renderView={() => <Text>{member.title}</Text>}
                renderEdit={() => <TextInputInline name='title' />}
              />
              {/* <EditableField
                label='Member ID'
                renderView={() => <Text>{member.memberId}</Text>}
                renderEdit={() => <TextInputInline name='memberId' />}
              /> */}
            </HStack>
            <HStack justifyContent='space-between'>
              <EditableField
                label='Role'
                renderView={() => <Text>{t(member.role)}</Text>}
                renderEdit={() => <RoleSelect />}
              />
            </HStack>
          </Stack>
        </Form>
      </EditableSection>
    </Stack>
  )
}
