import React from 'react'
import CardFrame from 'app/components/cards/CardFrame'
import ItemSummary from 'app/modules/items/components/ItemSummary'
import PollContent from './PollContent'
import ArticleContent from './ArticleContent'
import EventContent from 'app/modules/items/events/EventContent'
import FilesContent from 'app/modules/items/files/FilesContent'
import ReactionsBox from 'app/modules/items/reactions/ReactionsBox'
import ItemMenu from 'app/modules/items/components/ItemMenu'
import CardHeader from 'app/components/cards/CardHeader'
import ImageUploaded from 'app/components/ImageUploaded'
import CoverContainer from 'app/modules/items/components/CoverContainer'
import { useNavigate } from 'react-router'
import FormContent from 'app/modules/items/forms/FormContent'
import ItemHeader from 'app/modules/items/components/ItemHeader'

function ContentByType ({ item }) {
  switch (item.type) {
    case 'poll':
      return <PollContent item={item} />
    case 'event':
      return <EventContent item={item} />
    case 'files':
      return <FilesContent item={item} />
    case 'article':
      return <ArticleContent item={item} />
    case 'form':
      return <FormContent item={item} />
  }
}

export default function ItemContent ({ item }) {
  const navigate = useNavigate()

  return (
    <CardFrame>
      <ItemHeader item={item} />
      <CardHeader
        showButtonMenu={true}
        menu={<ItemMenu
          item={item}
          onDelete={() => navigate(`/org/${item.organizationId}/`)} />}
      >
        <ItemSummary item={item} />
      </CardHeader>
      {item.content && item.content.image && (
        <CoverContainer>
          <ImageUploaded organizationId={item.organizationId} fileId={item.content.image} />
        </CoverContainer>
      )}
      <ContentByType item={item} />
      <ReactionsBox item={item} />
    </CardFrame >
  )
}
