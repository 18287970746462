import { Divider, Text } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'
import { useCurrentOrganization } from 'app/api/users'
import ButtonAsync from 'app/components/buttons/ButtonAsync'
import { useMember } from 'app/api/members'
import { useNavigate, useParams } from 'react-router'
import { useSession } from 'app/components/SessionContext'
import { Link } from 'app/components/Link'
import { useTranslation } from 'react-i18next'

export default function AcceptInvitationScreen () {
  const { organizationId } = useParams()
  const { organization } = useCurrentOrganization()
  const { update } = useMember(organizationId, 'me')
  const navigate = useNavigate()
  const { refreshToken } = useSession()
  const { t } = useTranslation()

  if (!organization) return null

  const join = async () => {
    await update({ acceptedAt: new Date() })
    await refreshToken()
    navigate(`/org/${organization.id}`)
  }

  return (
    <Stack gap={30}>
      <Text category='h2'>{t('Join {{name}} in InCircl', { name: organization.name })}</Text>
      <Text>{t('You have been invited to be a part of this organization in InCircl. To become a member, click the button below.')}</Text>
      <ButtonAsync
        onPress={join}
      >{t('Join')}</ButtonAsync>
      <Divider />
      <Link to='/'>Back</Link>
    </Stack>
  )
}
