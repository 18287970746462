import { StyleSheet, View } from 'react-native'
import { Text, useStyleSheet } from '@ui-kitten/components'

const themedStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'color-basic-500',
    height: 46,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 18
  },
  right: {
    flexDirection: 'row',
    gap: 12,
    alignItems: 'center'
  },
  text: {
    color: 'color-basic-600',
    fontWeight: 'bold'
  },
  value: {
    color: 'color-basic-600'
  },
  primary: {
    color: 'color-primary-500',
    borderColor: 'color-primary-500'
  },
  bar: {
    position: 'absolute',
    backgroundColor: 'color-basic-500',
    opacity: 0.16,
    height: '100%',
    marginLeft: -18
  },
  barPrimary: {
    backgroundColor: 'color-primary-500'
  }
})

export default function ProgressBar ({ label, total, value, valueLabel, primary }) {
  const styles = useStyleSheet(themedStyles)
  const percentage = total && Math.round(value * 100 / total)

  return (
    <View style={[styles.container, primary && styles.primary]}>
      <View style={[styles.bar, { width: `${percentage}%` }, primary && styles.barPrimary]}></View>
      <Text category='p1' style={[styles.text, primary && styles.primary]}>{label}</Text>
      <View style={styles.right}>
        <Text category='c2' style={[styles.value, primary && styles.primary]}>{value} {valueLabel}</Text>
        <Text category='p1' style={[styles.text, primary && styles.primary]}>{percentage}%</Text>
      </View>
    </View>
  )
}
