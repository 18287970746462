import { Ellipse, Path, Svg } from 'react-native-svg'

export default function Logo ({ width = 190, height = 51, color = '#101426' }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 190 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M63.3516 43.2102H57.2218V7.58686H63.3516V43.2102Z" fill={color} />
      <Path d="M74.9577 29.0915V43.2102H69.1294V18.7411H74.807V22.007C75.5774 20.6672 76.599 19.679 77.8719 19.0426C79.1783 18.3727 80.5684 18.0377 82.0422 18.0377C84.9229 18.0377 87.1169 18.9421 88.6242 20.7509C90.1316 22.5597 90.8852 24.8877 90.8852 27.7349V43.2102H85.0569V28.7398C85.0569 27.0985 84.6549 25.7921 83.851 24.8207C83.0471 23.8158 81.7742 23.3134 80.0324 23.3134C78.4246 23.3134 77.1685 23.8661 76.2641 24.9715C75.3932 26.0433 74.9577 27.4167 74.9577 29.0915Z" fill={color} />
      <Path d="M112.049 43.9639C107.058 43.9639 102.804 42.2723 99.287 38.8892C95.8034 35.5061 94.0616 31.0176 94.0616 25.4237C94.0616 19.8633 95.8369 15.3748 99.3875 11.9581C102.938 8.54151 107.142 6.83319 111.999 6.83319C116.286 6.83319 119.804 7.92182 122.55 10.0991C125.33 12.2763 127.156 15.0398 128.027 18.3894L122.349 20.2987C121.78 17.9875 120.624 16.1117 118.882 14.6713C117.141 13.231 114.846 12.5108 111.999 12.5108C108.817 12.5108 106.053 13.6497 103.709 15.9275C101.397 18.1717 100.242 21.3371 100.242 25.4237C100.242 29.4097 101.397 32.5416 103.709 34.8194C106.02 37.0636 108.8 38.1858 112.049 38.1858C114.829 38.1858 117.124 37.4321 118.933 35.9248C120.741 34.4174 121.981 32.5584 122.651 30.3476L128.178 32.1564C127.307 35.4726 125.464 38.2695 122.651 40.5473C119.871 42.825 116.337 43.9639 112.049 43.9639Z" fill={color} />
      <Path d="M137.313 43.2102H131.535V18.7411H137.313V43.2102ZM131.736 12.8123C130.999 12.0754 130.63 11.1877 130.63 10.1493C130.63 9.11095 130.999 8.22329 131.736 7.48637C132.472 6.71596 133.36 6.33075 134.399 6.33075C135.47 6.33075 136.375 6.69921 137.112 7.43613C137.849 8.17305 138.217 9.07745 138.217 10.1493C138.217 11.1877 137.849 12.0754 137.112 12.8123C136.375 13.5492 135.47 13.9177 134.399 13.9177C133.36 13.9177 132.472 13.5492 131.736 12.8123Z" fill={color} />
      <Path d="M156.89 18.5904V24.5193C156.22 24.4188 155.584 24.3685 154.981 24.3685C150.593 24.3685 148.399 26.7635 148.399 31.5535V43.2102H142.571V18.7411H148.248V22.6602C148.818 21.3539 149.739 20.3322 151.012 19.5953C152.285 18.8249 153.725 18.4397 155.333 18.4397C155.869 18.4397 156.388 18.4899 156.89 18.5904Z" fill={color} />
      <Path d="M169.901 23.3636C168.026 23.3636 166.435 24.0503 165.128 25.4237C163.822 26.7635 163.169 28.6058 163.169 30.9506C163.169 33.3288 163.822 35.2046 165.128 36.5779C166.468 37.9513 168.093 38.638 170.002 38.638C171.71 38.638 173.067 38.1858 174.072 37.2814C175.11 36.3435 175.797 35.2883 176.132 34.116L181.257 35.975C180.587 38.1523 179.28 40.0281 177.338 41.6024C175.428 43.1767 172.983 43.9639 170.002 43.9639C166.418 43.9639 163.403 42.7245 160.958 40.2458C158.546 37.7671 157.34 34.6687 157.34 30.9506C157.34 27.199 158.529 24.1006 160.908 21.6553C163.319 19.2101 166.301 17.9875 169.851 17.9875C172.899 17.9875 175.378 18.7746 177.287 20.349C179.23 21.9233 180.486 23.8158 181.056 26.0266L175.83 27.9359C175.495 26.663 174.842 25.5911 173.871 24.7202C172.933 23.8158 171.61 23.3636 169.901 23.3636Z" fill={color} />
      <Path d="M190 43.2102H184.172V6.83319H190V43.2102Z" fill={color} />
      <Path d="M25.1223 -4.91283e-06C20.9909 -4.73224e-06 16.9233 1.01888 13.2797 2.9664C9.63615 4.91392 6.52913 7.72996 4.23386 11.1651C1.93859 14.6002 0.525915 18.5484 0.120969 22.6598C-0.283977 26.7713 0.331306 30.9192 1.91232 34.7361C3.49333 38.553 5.99126 41.9211 9.18486 44.542C12.3785 47.1629 16.1691 48.9558 20.2211 49.7618C24.2731 50.5678 28.4614 50.362 32.4149 49.1627C36.3683 47.9635 39.965 45.8077 42.8864 42.8864L32.2279 32.2279C31.0594 33.3964 29.6207 34.2587 28.0393 34.7385C26.4579 35.2182 24.7826 35.3005 23.1618 34.9781C21.541 34.6557 20.0247 33.9385 18.7473 32.8902C17.4699 31.8418 16.4707 30.4946 15.8383 28.9678C15.2059 27.441 14.9598 25.7819 15.1217 24.1373C15.2837 22.4927 15.8488 20.9134 16.7669 19.5394C17.685 18.1653 18.9278 17.0389 20.3852 16.2599C21.8427 15.4809 23.4697 15.0733 25.1223 15.0733L25.1223 -4.91283e-06Z" fill={color} />
      <Ellipse cx="25.1222" cy="25.1221" rx="5.5269" ry="5.5269" transform="rotate(-90 25.1222 25.1221)" fill={color} />
    </Svg>
  )
}
