import { Text } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'

export default function CardSummary ({ item, statusLabel }) {
  return (
    <Stack gap={5}>
      {statusLabel}
      <Stack gap={5}>
        <Text category='h6'>{item.title}</Text>
      </Stack>
      <Text category="c1" appearance='hint' numberOfLines={2}>{item.description}</Text>
    </Stack>
  )
}
