import ScreenContent from 'app/components/layout/ScreenContent'
import { TwoColumns, LeftColumn, RightColumn } from 'app/components/layout/TwoColumns'
import CreateItemForm from './create/CreateItemForm'
import { useParams, useNavigate } from 'react-router'
import { RelatedItemsEditProvider } from './related/RelatedItemsContext'
import RelatedItemsEditMode from './related/RelatedItemsEditMode'
import { useCreate } from 'app/api/items'
import Form from 'app/components/forms/Form'
import CardFrame from 'app/components/cards/CardFrame'
import ScrollContent from 'app/components/layout/ScrollContent'
import ErrorMessages from 'app/components/forms/ErrorMessages'
import { useTranslation } from 'react-i18next'

export default function CreateItemScreen () {
  const { type, organizationId } = useParams()
  const navigate = useNavigate()
  const create = useCreate(organizationId)
  const { t } = useTranslation()

  const onSubmit = async (values) => {
    await create(type, values)
    navigate(`/org/${organizationId}`)
  }

  return (
    <ScreenContent
      title='Publish'
      description={t('Create a new {{type}} in your organization', { type: t(type) })}
    >
      <Form defaultValues={{ title: '', description: '' }}>
        <RelatedItemsEditProvider name='relatedItemIds'>
          <TwoColumns
            LeftColumn={
              <LeftColumn title='Item'>
                <ScrollContent fadeTop={false}>
                  <CardFrame>
                    <ErrorMessages />
                    <CreateItemForm
                      type={type}
                      onSubmit={onSubmit}
                      organizationId={organizationId}
                      submitLabel={t('Publish')}
                    />
                  </CardFrame>
                </ScrollContent>
              </LeftColumn>
            }
            RightColumn={
              <RightColumn title='Related'>
                <RelatedItemsEditMode organizationId={organizationId} />
              </RightColumn>
            }
          />
        </RelatedItemsEditProvider>
      </Form>
    </ScreenContent>
  )
}
