import { Button } from '@ui-kitten/components'
import { useResolvedPath } from 'react-router'
import Icon from 'app/components/Icon'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  button: {
    height: 60
  }
})

export default function MenuButton ({ item: { name, path, icon }, onPress }) {
  const { pathname } = useResolvedPath()

  return (
    <Button
      appearance={pathname.includes(path) ? 'filled' : 'ghost'}
      accessoryLeft={<Icon name={icon} />}
      onPress={onPress}
      style={styles.button}
      size='large'
    />
  )
}
