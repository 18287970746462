import useSWR from 'swr'
import { useApi, sendWithoutAuthorization } from './api'
import { useParams } from 'react-router-native'

export function useUsers (take = 10, skip = 0) {
  const path = 'v1/users'
  const { data: users, ...props } = useSWR(path + `?take=${take}&skip=${skip}`)

  return {
    users,
    ...props
  }
}

export function useUserProfile () {
  const path = 'v1/users/me'
  const send = useApi()
  const { data: user, mutate, ...props } = useSWR(path)

  async function updateProfile (data) {
    return mutate(send('PATCH', path, data))
  }

  return {
    user,
    updateProfile,
    ...props
  }
}

export function useUser (id) {
  const path = 'v1/users'
  const send = useApi()
  const { data: user, mutate, ...props } = useSWR(`${path}/${id}`)

  async function update (data) {
    const updatedUser = await mutate(send('PATCH', `${path}/${id}`, data))
    return updatedUser
  }

  async function remove () {
    return send('DELETE', `${path}/${id}`)
  }

  return {
    user,
    update,
    remove,
    ...props
  }
}

export function requestPasswordChangeCode (email) {
  return sendWithoutAuthorization('POST', `v1/users/${email}/reset`)
}

export function changePassword (email, code, password) {
  return sendWithoutAuthorization('PATCH', `v1/users/${email}/reset`, { code, password })
}

export function createUser (data) {
  return sendWithoutAuthorization('POST', 'v1/users', data)
}

export function useUserOrganizations () {
  const path = 'v1/users/me/organizations'
  const { data: organizations, ...props } = useSWR(path)

  return {
    organizations,
    ...props
  }
}

export function useUserMembers () {
  const path = 'v1/users/me/members'
  const { data: members, ...props } = useSWR(path)

  return {
    members,
    ...props
  }
}

export function useCurrentOrganization () {
  const { organizations } = useUserOrganizations()
  const { organizationId } = useParams()

  return {
    isLoading: !organizations,
    organization: organizations && organizations.find(o => o.id === organizationId)
  }
}
