import { useNavigate } from 'react-router'
import { StyleSheet, View } from 'react-native'
import { Divider, useStyleSheet } from '@ui-kitten/components'
import { OrganizationCard } from 'app/modules/organizations/OrganizationCard'
import Stack from 'app/components/layout/Stack'
import { Link } from 'app/components/Link'
import Loader from 'app/components/Loader'

const themedStyles = StyleSheet.create({
  list: {
    borderWidth: 1,
    borderColor: 'color-basic-300',
    borderRadius: 4,
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    backgroundColor: 'color-basic-300',
    flexShrink: 1
  }
})

export default function SelectOrganizationList ({ members }) {
  const navigate = useNavigate()
  const styles = useStyleSheet(themedStyles)

  if (!members) {
    return <Loader />
  }

  return (
    <Stack alignItems='stretch' gap={30}>
      <View style={styles.list}>
        {members.map((member, id) => (
          <OrganizationCard
            key={id}
            member={member}
            onPress={() => navigate(`/org/${member.organization.id}/feed`)} />
        ))}
      </View>
      <Divider />
      <Link to='/create-organization'>
        Create new organization
      </Link>
    </Stack>
  )
}
