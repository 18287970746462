import { Layout, ApplicationProvider, IconRegistry } from '@ui-kitten/components'
import { StyleSheet } from 'react-native'
import * as eva from '@eva-design/eva'
import { EvaIconsPack } from '@ui-kitten/eva-icons'
import { theme } from 'app/theme'
import mappings from 'app/theme-mappings.json'
import { NotificationsProvider } from 'app/components/notifications/NotificationsContext'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'stretch',
    alignContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#F7F9FC',
    maxHeight: '100vh'
  }
})

export default function Container ({ children }) {
  return (
    <ApplicationProvider {...eva} theme={theme} customMapping={mappings}>
      <IconRegistry icons={EvaIconsPack} />
      <Layout
        style={styles.container}
        level='1'
      >
        <NotificationsProvider>
          {children}
        </NotificationsProvider>
      </Layout>
    </ApplicationProvider>
  )
}
