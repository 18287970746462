import CoverUpload from 'app/modules/items/components/CoverUpload'
import ItemForm from 'app/modules/items/create/ItemForm'
import CardButtons from 'app/components/cards/CardButtons'
import ButtonSubmit from 'app/components/forms/ButtonSubmit'

export default function UpdateForm ({ onSubmit, organizationId, submitLabel = 'Publish' }) {
  return (
    <>
      <CoverUpload organizationId={organizationId} />
      <ItemForm />
      <CardButtons>
        <ButtonSubmit status='primary' onSubmit={onSubmit}>
          {submitLabel}
        </ButtonSubmit>
      </CardButtons>
    </>
  )
}
