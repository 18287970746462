import { Text } from '@ui-kitten/components'
import { StyleSheet, View } from 'react-native'
import { theme } from 'app/theme'

const styles = StyleSheet.create({
  pill: {
    borderRadius: 25,
    padding: 6,
    borderWidth: 1,
    borderColor: theme['color-info-500'],
    backgroundColor: theme['color-info-transparent-100']
  },
  pillText: {
    textTransform: 'capitalize',
    fontSize: 10,
    marginRight: 6,
    marginLeft: 6
  }
})

export default function PillText ({ children }) {
  return (
    <View style={styles.pill}>
      <Text category='c2' status='info' style={styles.pillText}>
        {children}
      </Text>
    </View>
  )
}
