import { StyleSheet, View } from 'react-native'
import HStack from './HStack'
import { Text, Button } from '@ui-kitten/components'
import Icon from 'app/components/Icon'
import { useNavigate } from 'react-router'
import { useCurrentOrganization } from 'app/api/users'

const styles = StyleSheet.create({
  organization: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    flexShrink: 1,
    flexGrow: 1
  }
})

export default function OrganizationBlock () {
  const navigate = useNavigate()
  const { organization } = useCurrentOrganization()

  if (!organization) return null

  return (
    <HStack>
      <View
        style={styles.organization}
      >
        <Text category='s1'>{organization.name}</Text>
        <Text category='c1' appearance='hint'>{organization.slogan}</Text>
      </View>
      <Button
        appearance='ghost'
        size='small'
        onPress={() => navigate('/select-organization')}
        accessoryRight={<Icon name='arrow-ios-forward-outline' width={20} height={20} />}
      />
    </HStack>
  )
}
