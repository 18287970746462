import { useState } from 'react'
import { Divider, Text } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'
import ChangePasswordForm from 'app/modules/authentication/ChangePasswordForm'
import RequestPasswordChangeForm from './RequestPasswordChangeForm'
import { Link } from 'app/components/Link'
import { useTranslation } from 'react-i18next'

export default function RecoverPasswordScreen () {
  const [email, setEmail] = useState()
  const { t } = useTranslation()

  if (email) {
    return (
      <Stack gap={30}>
        <Text category='h2'>{t('Recover password')}</Text>
        <Text>
          {t('We\'ve sent you a verification code to your email address.')}
          {t('Enter the code you&apos;ve received and then your new password:')}
        </Text>
        <ChangePasswordForm
          email={email}
          submitLabel={t('Change password')}
          link={'/'}
        />
        <Link to='/'>Back</Link>
      </Stack>
    )
  } else {
    return (
      <Stack gap={30}>
        <Text category='h2'>{t('Oops... did you forget your password?')}</Text>
        <RequestPasswordChangeForm onCompleted={email => setEmail(email)} />
        <Divider />
        <Link to='/'>Back</Link>
      </Stack>
    )
  }
}
