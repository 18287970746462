import { useNavigate } from 'react-router'
import { Button } from '@ui-kitten/components'
import { useTranslation } from 'react-i18next'

export function Link ({ to, size = 'medium', appearance = 'ghost', style, children }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Button
      appearance={appearance}
      onPress={(() => navigate(to))}
      size={size}
      style={style}
    >
      {t(children)}
    </Button>
  )
}
