import { Divider, Text } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'
import SignInForm from 'app/modules/authentication/SignInForm'
import { Link } from 'app/components/Link'
import LanguageSwitch from './LanguageSwitch'
import { useTranslation } from 'react-i18next'

export default function SignInScreen () {
  const { t } = useTranslation()

  return (
    <Stack gap={30}>
      <Stack gap={5}>
        <Text category='h2'>{t('Sign In')}</Text>
        <Text>{t('Welcome back')}</Text>
      </Stack>
      <SignInForm />
      <Divider />
      <Stack gap={5}>
        <Link to='/recover-password'>Forgot password?</Link>
        <Link to='/sign-up'>Sign up</Link>
      </Stack>
      <Stack>
        <LanguageSwitch />
      </Stack>
    </Stack>
  )
}
