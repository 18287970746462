import { View, StyleSheet } from 'react-native'
import { containerShadow, theme } from 'app/theme'

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme['color-basic-400'],
    height: '100%',
    ...containerShadow
  },
  list: {
    overflowX: 'scroll',
    borderRadius: 4
  },
  row: {
    flexDirection: 'row'
  },
  column: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingHorizontal: 12,
    paddingVertical: 12,
    width: 200,
    flexGrow: 1,
    gap: 4,
    overflow: 'hidden',
    borderBottomWidth: 1,
    borderBottomColor: theme['color-basic-400']
  },
  columnData: {
    backgroundColor: theme['color-basic-100']
  },
  columnHeader: {
    paddingBottom: 12,
    backgroundColor: theme['color-background']
  },
  primaryColumn: {
    position: 'sticky',
    backgroundColor: theme['color-basic-100'],
    left: 0,
    zIndex: 1,
    borderRightWidth: 1,
    borderRightColor: theme['color-basic-400']
  }
})

export const TableRow = ({ children }) => (
  <View style={styles.row}>{children}</View>
)

export const TableCell = ({ children, isPrimary }) => (
  <View style={[styles.column, isPrimary ? styles.primaryColumn : styles.columnData]}>{children}</View>
)

export const TableHeader = ({ children }) => (
  <View style={[styles.column, styles.columnHeader]}>{children}</View>
)

export const TableContainer = ({ children }) => (
  <View style={styles.container}>{children}</View>
)
