import { createContext, useContext, useState } from 'react'

const SectionContext = createContext({
  isEditing: false,
  setIsEditing: () => { }
})

export function EditableSection ({ children }) {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <SectionContext.Provider value={{ isEditing, setIsEditing }}>
      {children}
    </SectionContext.Provider>
  )
}

export function useSectionContext () {
  return useContext(SectionContext)
}
