import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import CardPressable from 'app/components/cards/CardPressable'
import CardHeader from 'app/components/cards/CardHeader'
import ItemMenu from 'app/modules/items/components/ItemMenu'
import ArticleCardContent from './ArticleCardContent'
import CardSummary from 'app/modules/items/components/CardSummary'

export default function ArticleCard ({ item }) {
  const [mouseOver, setMouseOver] = useState(false)
  const navigate = useNavigate()

  return (
    <CardPressable
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onPress={() => navigate(`/org/${item.organizationId}/items/${item.itemId}`)}
    >
      <CardHeader
        showButtonMenu={mouseOver}
        menu={<ItemMenu item={item} />}
      >
        <CardSummary item={item} />
      </CardHeader>
      <ArticleCardContent organizationId={item.organizationId} item={item} />
    </CardPressable>
  )
}
