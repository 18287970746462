import { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { $createParagraphNode, $getSelection, $isRangeSelection } from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import EditorTextMenu from './EditorTextMenu'
import { useUserInteractions } from './useUserInteractions'
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode
} from '@lexical/rich-text'
import {
  $wrapNodes
} from '@lexical/selection'

const ANCHOR_ELEMENT = document.body

export default function EditorTextMenuPlugin () {
  const [show, setShow] = useState(false)
  const [blockType, setBlockType] = useState('paragraph')
  const [isBold, setIsBold] = useState(false)
  const [isCode, setIsCode] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isQuote, setIsQuote] = useState(false)
  const [isHeading, setIsHeading] = useState(false)
  const { isPointerDown, isKeyDown } = useUserInteractions()
  const [editor] = useLexicalComposerContext()

  const updateFloatingMenu = useCallback(() => {
    editor.getEditorState().read(() => {
      if (editor.isComposing() || isPointerDown || isKeyDown) return

      if (editor.getRootElement() !== document.activeElement) {
        setShow(false)
        return
      }

      const selection = $getSelection()

      if ($isRangeSelection(selection) && !selection.anchor.is(selection.focus)) {
        const anchorNode = selection.anchor.getNode()
        const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow()
        const type = element.getType()

        setBlockType(type)
        setIsBold(selection.hasFormat('bold'))
        setIsCode(selection.hasFormat('code'))
        setIsItalic(selection.hasFormat('italic'))
        setIsQuote(type === 'quote')
        setIsHeading($isHeadingNode(element))
        setShow(true)
      } else {
        setShow(false)
      }
    })
  }, [editor])

  // Rerender the floating menu automatically on every state update.
  // Needed to show correct state for active formatting state.
  useEffect(() => {
    return editor.registerUpdateListener(() => {
      updateFloatingMenu()
    })
  }, [editor, updateFloatingMenu])

  // Rerender the floating menu on relevant user interactions.
  // Needed to show/hide floating menu on pointer up / key up.
  useEffect(() => {
    updateFloatingMenu()
  }, [isPointerDown, isKeyDown, updateFloatingMenu])

  const formatQuote = () => {
    editor.update(() => {
      const selection = $getSelection()

      if ($isRangeSelection(selection)) {
        if (blockType !== 'quote') {
          $wrapNodes(selection, () => $createQuoteNode())
        } else {
          $wrapNodes(selection, () => $createParagraphNode())
        }
      }
    })
  }

  const formatHeading = () => {
    editor.update(() => {
      const selection = $getSelection()

      if ($isRangeSelection(selection)) {
        if (blockType !== 'heading') {
          $wrapNodes(selection, () => $createHeadingNode('h3'))
        } else {
          $wrapNodes(selection, () => $createParagraphNode())
        }
      }
    })
  }

  return createPortal(
    <EditorTextMenu
      blockType={blockType}
      editor={editor}
      show={show}
      isBold={isBold}
      isCode={isCode}
      isItalic={isItalic}
      isQuote={isQuote}
      onQuote={formatQuote}
      isHeading={isHeading}
      onHeading={formatHeading}
    />,
    ANCHOR_ELEMENT
  )
}
