import { useList } from 'app/api/items'
import InfiniteList from 'app/components/InfiniteList'
import NoItemsCard from 'app/components/cards/NoItemsCard'
import Loader from 'app/components/Loader'

export default function FeedList ({ organizationId, section, renderItem }) {
  const { items, setSize, size, isLoading, mutate } = useList(organizationId, { types: section && [section] })

  if (!items) {
    return (<Loader />)
  }

  if (items[0].length === 0) {
    return (
      <NoItemsCard />
    )
  }

  return (
    <InfiniteList
      isLoading={isLoading}
      setSize={setSize}
      size={size}
      mutate={mutate}
      pages={items}
      renderItem={item => renderItem(item)}
    />
  )
}
