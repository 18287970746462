import { theme } from 'app/theme'
import { StyleSheet, View } from 'react-native'
import Icon from 'app/components/Icon'
import Text from 'app/i18n/Text'

const styles = StyleSheet.create({
  fileUploadArea: {
    backgroundColor: theme['color-basic-200'],
    flexDirection: 'column',
    flexGrow: 1,
    paddingVertical: 48,
    alignItems: 'center'
  },
  icon: {
    fill: theme['color-basic-600']
  },
  text: {
    color: theme['color-basic-600'],
    textAlign: 'center'
  }
})

export default function UploadArea ({ text = 'Drop or click here to upload a file' }) {
  return (
    <View style={styles.fileUploadArea}>
      <Icon name='cloud-upload' size={48} fill={styles.icon.fill} />
      <Text category='c2' style={styles.text}>{text}</Text>
    </View>
  )
}
