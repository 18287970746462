import ScreenContent from 'app/components/layout/ScreenContent'
import { TwoColumns, LeftColumn, RightColumn } from 'app/components/layout/TwoColumns'
import { useItem } from 'app/api/items'
import { useParams } from 'react-router'
import ItemContent from './view/ItemContent'
import RelatedList from './related/RelatedList'
import ScrollContent from 'app/components/layout/ScrollContent'

export default function ViewItemScreen () {
  const { organizationId, itemId } = useParams()
  const { item } = useItem(organizationId, itemId)

  if (!item) return null

  return (
    <ScreenContent
      title={item.type}
    >
      <TwoColumns
        LeftColumn={
          <LeftColumn title='Content'>
            <ScrollContent>
              <ItemContent item={item} />
            </ScrollContent>
          </LeftColumn>
        }
        RightColumn={
          <RightColumn title='Related'>
            {item.relatedItemIds && (
              <RelatedList
                title='See more'
                organizationId={organizationId}
                filter={{ itemIds: item.relatedItemIds }}
              />
            )}
          </RightColumn>
        }
      />
    </ScreenContent>
  )
}
