import Select from 'app/components/forms/Select'

export default function NotifySelect ({ name }) {
  return (
    <Select
      name={name}
      values={['all', 'none']}
    />
  )
}
