import ImageEdit from 'app/components/files/ImageEdit'
import CoverContainer from './CoverContainer'

export default function CoverUpload ({ organizationId }) {
  return (
    <CoverContainer>
      <ImageEdit name='content.image' organizationId={organizationId} />
    </CoverContainer>
  )
}
