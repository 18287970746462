import CheckInput from 'app/components/forms/CheckInput'
import RadioInput from 'app/components/forms/RadioInput'
import Stack from 'app/components/layout/Stack'

export default function FormFieldTypeSelect ({ field, name, value }) {
  const options = field.options.filter(option => option !== '')

  if (field.maxSelections === 1) {
    return (
      <RadioInput
        name={`${name}[0]`}
        options={options}
        defaultValue={value && value[0]}
      />
    )
  }

  return (
    <Stack>
      {options.map((option, i) => (
        <CheckInput
          key={i}
          name={`${name}[${i}]`}
          label={option}
          defaultValue={value && value[i]}
        />
      ))}
    </Stack>
  )
}
