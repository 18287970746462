import Stack from 'app/components/layout/Stack'
import ProgressBar from 'app/components/ProgressBar'
import { useTranslation } from 'react-i18next'

function findWinningOptions (options) {
  let winningOptions = []
  let maxVotes = 0

  options.forEach((option, index) => {
    if (option.votes > maxVotes) {
      winningOptions = [index]
      maxVotes = option.votes
    } else if (option.votes === maxVotes) {
      winningOptions.push(index)
    }
  })

  return winningOptions
}

function calculateTotalVotes (options) {
  return options.reduce((total, option) => total + option.votes, 0)
}

export default function PollResults ({ poll }) {
  const winningOptions = findWinningOptions(poll.options)
  const { t } = useTranslation()

  return (
    <Stack gap={12}>
      {poll.options.map((option, index) => (
        <ProgressBar
          primary={winningOptions.includes(index)}
          label={option.text}
          key={index}
          valueLabel={t('votes')}
          value={option.votes}
          total={calculateTotalVotes(poll.options)}
        />
      ))}
    </Stack>
  )
}
