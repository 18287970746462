import ScreenContent from 'app/components/layout/ScreenContent'
import { useParams } from 'react-router'
import ScrollContent from 'app/components/layout/ScrollContent'
import ProfileCard from './ProfileCard'

export default function ProfileScreen () {
  const { organizationId, '*': section } = useParams()

  return (
    <ScreenContent
      title='Profile'
      description='Your profile & settings'
    >
      <ScrollContent>
        <ProfileCard
          organizationId={organizationId}
          userId='me'
          section={section}
        />
      </ScrollContent>
    </ScreenContent>
  )
}
