import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { Spinner, Text } from '@ui-kitten/components'
import { googleApiKey } from 'app/config'

const render = (status, map) => {
  switch (status) {
    case Status.LOADING:
      return <Spinner />
    case Status.FAILURE:
      return <Text>Map cannot be loaded right now, sorry.</Text>
    case Status.SUCCESS:
      return map
  }
}

export default function MapLoader ({ map }) {
  return (
    <Wrapper apiKey={googleApiKey} render={status => render(status, map)} />
  )
}
