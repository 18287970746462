import { useNavigate } from 'react-router'
import { logIn } from 'app/api/oauth-client'
import { oauthClientId } from 'app/config'
import ButtonSubmit from 'app/components/forms/ButtonSubmit'
import Form from 'app/components/forms/Form'
import TextInput from 'app/components/forms/TextInput'
import PasswordInput from 'app/components/forms/PasswordInput'
import ToggleInput from 'app/components/forms/ToggleInput'
import Stack from 'app/components/layout/Stack'
import { Platform } from 'react-native'
import Alert from 'app/components/forms/Alert'
import { useContext, useState } from 'react'
import { SessionContext } from 'app/components/SessionContext'
import { useTranslation } from 'react-i18next'

export default function SignInForm () {
  const [hasError, setHasError] = useState(false)
  const { saveToken } = useContext(SessionContext)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onSubmit = async ({ email, password }) => {
    try {
      setHasError(false)
      const token = await logIn(email, password, oauthClientId)
      await saveToken(token)
      navigate('/select-organization')
    } catch (err) {
      if (err.message.includes('user credentials are invalid')) {
        setHasError(true)
      } else {
        throw err
      }
    }
  }

  return (
    <Form defaultValues={{ email: '', password: '' }}>
      <Stack alignItems='flex-start' gap={10}>
        {hasError && (
          <Alert type='danger'>{t('Could not sign you in, check your credentials')}</Alert>
        )}
        <TextInput placeholder='Email' name='email' rules={{ required: true }} />
        <PasswordInput placeholder='Password' name='password' rules={{ required: true }} />
        {Platform.OS === 'web' && (
          <ToggleInput name='remember'>
            Remember me
          </ToggleInput>
        )}
      </Stack>
      <ButtonSubmit onSubmit={onSubmit}>
        {t('Sign in')}
      </ButtonSubmit>
    </Form>
  )
}
