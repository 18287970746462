import { Outlet } from 'react-router'
import BottomTabNavigation from 'app/modules/menu/BottomTabNavigation'
import { Platform, StyleSheet, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { theme } from 'app/theme'

const styles = StyleSheet.create({
  frame: {
    width: '100%',
    height: Platform.OS === 'web' ? '100vh' : '100%'
  },
  content: {
    flexGrow: 1,
    flexShrink: 1
  },
  nav: {
    backgroundColor: theme['color-basic-300']
  }
})

export default function OrganizationMobileLayout () {
  return (
    <SafeAreaView style={styles.frame}>
      <View style={styles.content}>
        <Outlet />
      </View>
      <View style={styles.nav}>
        <BottomTabNavigation />
      </View>
    </SafeAreaView>
  )
}
