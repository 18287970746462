import React from 'react'
import { MenuItem } from '@ui-kitten/components'
import { useRemove } from 'app/api/items'
import Icon from 'app/components/Icon'
import { useNotifications } from 'app/components/notifications/NotificationsContext'
import { useTranslation } from 'react-i18next'

export default function ItemMenuRemove ({ item, onDelete }) {
  const remove = useRemove(item.organizationId)
  const { notify } = useNotifications()
  const { t } = useTranslation()

  const removeItem = async () => {
    await remove(item.itemId)
    notify(t('Item removed'), t('Item #{{itemId}} was removed', { itemId: item.itemId }), 'danger')
    onDelete && onDelete()
  }

  return (
    <MenuItem
      appearance='danger'
      title={t('Remove')}
      accessoryLeft={<Icon name='trash-2-outline' />}
      onPress={removeItem}
    />
  )
}
