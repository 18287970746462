import { IndexPath, Select as SelectBase, SelectItem } from '@ui-kitten/components'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default function Select ({ name, values = [], labels = {} }) {
  const { field: { onChange, value } } = useController({ name })
  const { t } = useTranslation()

  const selectedIndex = values.indexOf(value)

  const getText = value => labels[value] ? t(labels[value]) : t(value)

  return (
    <SelectBase
      style={{ minWidth: 0 }}
      value={getText(value)}
      placeholder={t('Select')}
      selectedIndex={selectedIndex !== -1 && new IndexPath(selectedIndex)}
      onSelect={index => onChange(values[index.row])}
    >
      {values.map(value => (
        <SelectItem key={value} title={getText(value)} />
      ))}
    </SelectBase>
  )
}
