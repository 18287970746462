import { Navigate, useParams } from 'react-router'
import { useMember } from 'app/api/members'

export default function VerifyInvitationAccepted ({ children }) {
  const { organizationId } = useParams()
  const { member } = useMember(organizationId, 'me')

  if (!member) return null

  if (member.acceptedAt === null) {
    return <Navigate replace to={`/join/${organizationId}`} />
  } else {
    return children
  }
}
