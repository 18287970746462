import Text from 'app/i18n/Text'
import { useSectionContext } from './EditableSection'
import { StyleSheet, View } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    gap: 12,
    flexGrow: 1,
    flexBasis: 0
  },
  field: {
    minHeight: 28,
    flexGrow: 1,
    justifyContent: 'center'
  },
  label: {
    textTransform: 'uppercase'
  }
})

export default function EditableField ({ label, renderEdit, renderView }) {
  const { isEditing } = useSectionContext()

  return (
    <View style={styles.container}>
      {label && (<Text category='label' appearance='hint' style={styles.label}>{label}</Text>)}
      <View style={styles.field}>
        {isEditing ? renderEdit() : renderView()}
      </View>
    </View>
  )
}
