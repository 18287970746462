import { Text } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'
import MarkdownText from 'app/components/editor/MarkdownText'

export default function ItemSummary ({ item, numberOfLines }) {
  return (
    <Stack gap={4}>
      <Stack gap={0}>
        <Text category='h6'>{item.title}</Text>
      </Stack>
      <Text numberOfLines={numberOfLines}>
        <MarkdownText
          organizationId={item.organizationId}
          text={item.description}
          category='p2'
        />
      </Text>
    </Stack>
  )
}
