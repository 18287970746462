import { useContext } from 'react'
import { SessionContext } from 'app/components/SessionContext'
import { useParams } from 'react-router'

export function HasAccess ({ children, scope }) {
  const { organizationId } = useParams()
  const { accessToken } = useContext(SessionContext)
  const permission = `${organizationId}:${scope}`

  if (accessToken.scope.includes(permission)) {
    return children
  }
  return null
}
