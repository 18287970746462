import { Button, Spinner } from '@ui-kitten/components'
import React, { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'

const styles = StyleSheet.create({
  indicator: {
    position: 'absolute',
    left: '50%',
    transform: [{ translateX: '-50%', translateY: '-50%' }]
  },
  hideContent: {
    opacity: 0
  }
})

const ActivityIndicator = () => (
  <View style={styles.indicator}>
    <Spinner size='tiny' status='control' />
  </View>
)

export default function ButtonAsync ({ children, onPress, ...props }) {
  const [isLoading, setIsLoading] = useState()

  const onPressAsync = async (event) => {
    setIsLoading(true)
    await onPress(event)
    setIsLoading(false)
  }

  return (
    <Button
      onPress={onPressAsync}
      {...props}
    >
      {({ style, ...props }) => (
        <>
          {isLoading && <ActivityIndicator />}
          {children && (
            <Text style={[isLoading && styles.hideContent, style]} {...props}>
              {children}
            </Text>
          )}
        </>
      )
      }
    </Button>
  )
}
