import { Icon as KittenIcon } from '@ui-kitten/components'
import { forwardRef } from 'react'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  icon: {
    width: 16,
    height: 16
  }
})

const Icon = forwardRef(({ style, size, color = 'black', ...props }, ref) => {
  // Adds some default color that fixes bug on android
  return (
    <KittenIcon
      ref={ref}
      color={color}
      {...props}
      style={[styles.icon, { width: size, height: size }, style]}
    />
  )
})

Icon.displayName = 'Icon'

export default Icon
