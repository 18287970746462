import { Button as ButtonBase } from '@ui-kitten/components'
import { useTranslation } from 'react-i18next'

export default function Button ({ children, ...props }) {
  const { t } = useTranslation()

  return (
    <ButtonBase {...props}>
      {t(children)}
    </ButtonBase>
  )
}
