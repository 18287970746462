import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect, useRef } from 'react'
import { AutoLinkNode } from '@lexical/link'
import { useRelatedItemsEdit } from './RelatedItemsContext'
import { $getNodeByKey } from 'lexical'
import { ITEM_ID_MATCHER } from 'app/validations'

export default function RelatedItemsCollectPlugin () {
  const { addRelatedItemId, removeRelatedItemId } = useRelatedItemsEdit()
  const [editor] = useLexicalComposerContext()
  const itemIdNodeMap = useRef(new Map())

  useEffect(() => {
    return editor.registerMutationListener(AutoLinkNode, nodeMutations => {
      editor.getEditorState().read(() => {
        for (const [nodeKey, mutation] of nodeMutations) {
          if (mutation === 'created') {
            const itemMatch = $getNodeByKey(nodeKey).getTextContent().match(ITEM_ID_MATCHER)
            itemIdNodeMap.current.set(nodeKey, itemMatch[2])
            addRelatedItemId(itemMatch[2])
          } else if (mutation === 'updated') {
            const itemMatch = $getNodeByKey(nodeKey).getTextContent().match(ITEM_ID_MATCHER)
            const id = itemIdNodeMap.current.get(nodeKey)
            removeRelatedItemId(id)
            itemIdNodeMap.current.set(nodeKey, itemMatch[2])
            addRelatedItemId(itemMatch[2])
          } else if (mutation === 'destroyed') {
            const id = itemIdNodeMap.current.get(nodeKey)
            removeRelatedItemId(id)
            itemIdNodeMap.current.delete(nodeKey)
          }
        }
      })
    })
  }, [editor])

  return null
}
