import { StyleSheet, View } from 'react-native'
import Icon from 'app/components/Icon'
import { Text, useStyleSheet } from '@ui-kitten/components'

const themedStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 12,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexShrink: 1
  },
  icon: {
    fill: 'color-primary-500'
  },
  textContainer: {
    flexDirection: 'column',
    gap: 4,
    flexShrink: 1
  },
  size: {
    color: 'color-basic-600'
  }
})

export default function FileItem ({ file }) {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      <Icon name='attach-2-outline' size={16} fill={styles.icon.fill} />
      <View style={styles.textContainer}>
        <Text category='c2'>{file.name}</Text>
        <Text category='c1' style={styles.size}>{Math.round(file.size / 1024)}kb</Text>
      </View>
    </View>
  )
}
