import { Text, useStyleSheet } from '@ui-kitten/components'
import { Pressable, StyleSheet } from 'react-native'
import { useNavigate } from 'react-router'

const themedStyles = StyleSheet.create({
  link: {
    color: 'color-primary-500'
  }
})

export default function TextLink ({ to, category, children }) {
  const navigate = useNavigate()
  const styles = useStyleSheet(themedStyles)

  return (
    <Pressable
      onPress={() => navigate(to)}
    >
      <Text category={category} style={styles.link}>{children}</Text>
    </Pressable >
  )
}
