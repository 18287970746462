import { useMembers } from 'app/api/members'
import MembersGridCard from './MemberCard'
import InfiniteList from 'app/components/InfiniteList'
import Loader from 'app/components/Loader'

export default function MemberList ({ organizationId }) {
  const { members, setSize, size, isLoading, mutate } = useMembers(organizationId)

  if (!members) {
    return <Loader />
  }

  return (
    <InfiniteList
      isLoading={isLoading}
      setSize={setSize}
      size={size}
      mutate={mutate}
      pages={members}
      renderItem={item => <MembersGridCard member={item} organizationId={organizationId} />}
    />

  )
}
