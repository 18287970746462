import { FlatList } from 'react-native'
import FadeTop from 'app/components/layout/FadeTop'
import { useIsMobile } from './layout/mediaQueries'

export default function InfiniteList ({ isLoading, setSize, mutate, pages, size, renderItem }) {
  const isMobile = useIsMobile()

  if (!pages) return null

  return (
    <>
      <FadeTop />
      <FlatList
        style={{ width: '100%' }}
        contentContainerStyle={{
          maxWidth: 900,
          alignSelf: 'center',
          width: '100%',
          paddingTop: 30,
          gap: 12,
          paddingHorizontal: isMobile ? 16 : 0
        }}
        initialNumToRender={10}
        onEndReachedThreshold={1}
        data={pages.flat()}
        onEndReached={() => !isLoading && setSize(size + 1)}
        renderItem={({ item }) => renderItem(item)}
        keyExtractor={item => item.id}
        showsVerticalScrollIndicator={false}
        refreshing={isLoading}
        onRefresh={() => mutate()}
      />
    </>
  )
}
