import { useFormSubmit } from 'app/api/forms'
import { useSession } from 'app/components/SessionContext'
import CardButtons from 'app/components/cards/CardButtons'
import ButtonSubmit from 'app/components/forms/ButtonSubmit'
import Form from 'app/components/forms/Form'
import Stack from 'app/components/layout/Stack'
import FormFieldSubmit from './FormFieldSubmit'
import { useNotifications } from 'app/components/notifications/NotificationsContext'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

export default function FormSubmit ({ item, submission }) {
  const { accessToken } = useSession()
  const submit = useFormSubmit(item.organizationId, item.id, accessToken.sub)
  const { notify } = useNotifications()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleSubmit = async (values) => {
    Object.entries(values.fields).forEach(([_, field]) => {
      if (field.answer instanceof Array) {
        field.answer = field.answer.filter(answer => answer !== undefined)
      }
    })

    await submit(values)
    navigate(`/org/${item.organizationId}`)
    notify(t('Form submitted'), t('Your form has been submitted'), 'success')
  }

  return (
    <Form>
      <Stack gap={12}>
        <Stack gap={32}>
          {item.content.fields && item.content.fields.map((field, i) => (
            <FormFieldSubmit
              key={i}
              field={field}
              index={i}
              organizationId={item.organizationId}
              value={submission && submission.fields[field.id].answer}
            />
          ))}
          <CardButtons>
            <ButtonSubmit status='primary' onSubmit={handleSubmit}>{t('Submit')}</ButtonSubmit>
          </CardButtons>
        </Stack>
      </Stack>
    </Form>
  )
}
