import { useNavigate } from 'react-router'
import ButtonSubmit from 'app/components/forms/ButtonSubmit'
import Form from 'app/components/forms/Form'
import TextInput from 'app/components/forms/TextInput'
import Stack from 'app/components/layout/Stack'
import Alert from 'app/components/forms/Alert'
import { useState } from 'react'
import { useUserProfile } from 'app/api/users'
import { useTranslation } from 'react-i18next'

export default function ProfileSetupForm () {
  const [hasError, setHasError] = useState(false)
  const navigate = useNavigate()
  const { updateProfile } = useUserProfile()
  const { t } = useTranslation()

  const onSubmit = async (user) => {
    try {
      await updateProfile(user)
      setHasError(false)
      navigate('/')
    } catch (err) {
      setHasError('An error has occured. Please try again later')
      throw err
    }
  }

  return (
  <Form defaultValues={{ name: '', surname: '' }}>
    <Stack alignItems='flex-start' gap={10}>
      {hasError && (
        <Alert type='danger'>{hasError}</Alert>
      )}
      <TextInput placeholder='Your name' name='name' rules={{ required: true }} />
      <TextInput placeholder='Your last name' name='surname' rules={{ required: true }} />
    </Stack>
    <ButtonSubmit onSubmit={onSubmit}>
      {t('Save')}
    </ButtonSubmit>
  </Form>
  )
}
