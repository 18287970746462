import { Icon, StyleService, Text, useStyleSheet } from '@ui-kitten/components'
import FadeInView from 'app/components/animations/FadeInView'

const themedStyles = StyleService.create({
  card: {
    width: '100%',
    borderRadius: 3,
    padding: 15,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10
  },
  text: {
    color: '#fff',
    flexShrink: 1
  },
  icon: {
    width: 24,
    height: 24
  }
})

const icons = {
  success: 'checkmark-circle-2-outline'
}

export default function Alert ({ children, type }) {
  themedStyles.card.backgroundColor = `color-${type}-400`

  const styles = useStyleSheet(themedStyles)

  return (
    <FadeInView style={styles.card}>
      <Icon name={icons[type] || 'alert-circle-outline'} style={styles.icon} fill='#fff' />
      <Text style={styles.text}>
        {children}
      </Text>
    </FadeInView>
  )
}
