import { useParams } from 'react-router'
import TextInputMultiple from 'app/components/forms/TextInputMultiple'
import ItemForm from './ItemForm'
import CardButtons from 'app/components/cards/CardButtons'
import ButtonSubmit from 'app/components/forms/ButtonSubmit'
import { useItem } from 'app/api/items'
import MessageCard from 'app/components/cards/MessageCard'

export default function PollForm ({ onSubmit, submitLabel = 'Publish' }) {
  const { organizationId, itemId } = useParams()
  const { item } = useItem(organizationId, itemId)

  const hasVotes = item?.content?.options.some(option => option.votes > 0)

  const onPollSubmit = item => {
    item.content.options = item.content.options.filter(option => option.text !== '')
    onSubmit(item)
  }

  return (
    <>
      <ItemForm />
      {hasVotes
        ? <MessageCard
          title={'These options can not longer be edited.'}
          description={'This poll has already received votes.'}
          type='info'
        >
        </MessageCard>
        : <TextInputMultiple
          name='content.options'
          subName='text'
          placeholder='Option'
          rules={{ required: 'Option is required' }}
          max={30}
          min={2}
        />
      }
      <CardButtons>
        <ButtonSubmit status='primary' onSubmit={onPollSubmit}>
          {submitLabel}
        </ButtonSubmit>
      </CardButtons>
    </>
  )
}
