import { Pressable, StyleSheet } from 'react-native'
import { useFileDownload } from 'app/api/files'
import FileItem from 'app/components/files/FileItem'

const styles = StyleSheet.create({
  container: {
    flexShrink: 1
  }
})

export default function FileItemDownload ({ organizationId, file }) {
  const download = useFileDownload()

  return (
    <Pressable
      style={styles.container}
      key={file.id}
      onPress={() => download(organizationId, file.fileId, file.name)}
    >
      <FileItem file={file} />
    </Pressable>
  )
}
