const { StyleSheet, View } = require('react-native')

const styles = StyleSheet.create({
  stack: {
    flexDirection: 'row',
    flexShrink: 1
  }
})

export default function HStack ({ children, minWidth, paddingHorizontal, paddingVertical, gap = 15, alignItems, justifyContent }) {
  return (
    <View style={[styles.stack, { minWidth, paddingHorizontal, paddingVertical, gap, alignItems, justifyContent }]}>
      {children}
    </View>
  )
}
