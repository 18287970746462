import ScreenContent from 'app/components/layout/ScreenContent'
import { useParams } from 'react-router-native'
import ItemsInfiniteSearch from './items/ItemsInfiniteSearch'
import MembersInfiniteSearch from './members/MembersInfiniteSearch'
import { useIsMobile } from 'app/components/layout/mediaQueries'
import SearchBar from './SearchBar'
import { useTranslation } from 'react-i18next'

export default function SearchScreen () {
  const { organizationId, query = '', type } = useParams()
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  return (
    <ScreenContent
      title='Search'
      description={query ? t('Results for "{{query}}" in {{type}}', { query, type: t(type) }) : t('Search for items or members')}
      headerContent={isMobile && (
        <SearchBar />
      )}
    >
      {query && type === 'items' && (
        <ItemsInfiniteSearch organizationId={organizationId} query={query} />
      )}
      {query && type === 'members' && (
        <MembersInfiniteSearch organizationId={organizationId} query={query} />
      )}
    </ScreenContent>
  )
}
