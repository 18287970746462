import Stack from 'app/components/layout/Stack'
import Modal from 'app/components/Modal'
import { EnablePushNotificationsButton } from './EnablePushNotificationsButton'
import { Button } from '@ui-kitten/components'
import { useCurrentOrganization } from 'app/api/users'
import { useTranslation } from 'react-i18next'

export default function EnableNotificationsModal ({ isOpen, onDismiss, onReject, onEnable, organizationId }) {
  const { organization } = useCurrentOrganization()
  const { t } = useTranslation()

  if (!organization) return null

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={t('Enable notifications')}
      description={t('Would you like to receive notifications of {{name}} on this device?', { name: organization.name })}
    >
      <Stack>
        <EnablePushNotificationsButton
          organizationId={organizationId}
          onEnable={onEnable}
        />
        <Button
          onPress={onReject}
          appearance='ghost'
          status='basic'
        >
          {t('No, thanks')}
        </Button>
      </Stack >
    </Modal >
  )
}
