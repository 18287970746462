import { useStyleSheet } from '@ui-kitten/components'
import { StyleSheet, View } from 'react-native'

const themedStyles = StyleSheet.create({
  card: {
    borderRadius: 12,
    paddingHorizontal: 24,
    paddingVertical: 16,
    backgroundColor: 'color-basic-100',
    shadowColor: 'color-basic-400',
    shadowRadius: 4,
    shadowOpacity: 0.8,
    shadowOffset: { width: 0, height: 2 },
    gap: 16
  }
})

export default function CardFrame ({ children, onMouseEnter, onMouseLeave }) {
  const styles = useStyleSheet(themedStyles)

  return (
    <View
      style={styles.card}
      onMouseEnter={() => onMouseEnter && onMouseEnter()}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
    >
      {children}
    </View>
  )
}
