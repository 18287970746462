import TextInput from 'app/components/forms/TextInput'
import ItemForm from 'app/modules/items/create/ItemForm'
import CoverUpload from 'app/modules/items/components/CoverUpload'
import CardButtons from 'app/components/cards/CardButtons'
import ButtonSubmit from 'app/components/forms/ButtonSubmit'
import { Button } from 'app/i18n'
import DateTimeInput from 'app/modules/items/events/DateTimeInput'
import { useState } from 'react'
import Icon from 'app/components/Icon'
import { StyleSheet, View } from 'react-native'
import MapLoader from './MapLoader'
import MapPreview from './MapPreview'
import { theme } from 'app/theme'
import { useGenerateMapFile } from 'app/api/maps'
import { useIsMobile } from 'app/components/layout/mediaQueries'

const styles = StyleSheet.create({
  placeInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 8,
    zIndex: 1,
    backgroundColor: theme['color-basic-control-transparent-600']
  },
  date: {
    flexDirection: 'column',
    gap: 4,
    alignItems: 'stretch'
  },
  location: {
    flexDirection: 'column',
    flexGrow: 1,
    gap: 8,
    minHeight: 150,
    borderWidth: 1,
    borderColor: theme['color-basic-400'],
    borderRadius: 4
  },
  form: {
    flexDirection: 'row',
    gap: 28
  },
  formMobile: {
    flexDirection: 'column'
  },
  arrow: {
    alignItems: 'center'
  }
})

export default function EventForm ({ onSubmit, organizationId, submitLabel = 'Publish' }) {
  const [hasEnd, setHasEnd] = useState(false)
  const generateMapFile = useGenerateMapFile(organizationId)
  const isMobile = useIsMobile()

  const handleSubmit = async (data) => {
    if (!data.content.location.startsWith('http')) {
      const file = await generateMapFile(data.content.location)
      data.content.mapFileId = file.id
    }

    return onSubmit(data)
  }

  return (
    <>
      <CoverUpload organizationId={organizationId} />
      <ItemForm />
      <View style={[styles.form, isMobile && styles.formMobile]}>
        <View style={styles.date}>
          <DateTimeInput
            name='content.start'
            defaultValue={new Date()}
            label='Start'
          />
          {hasEnd
            ? (
              <>
                <View style={styles.arrow}>
                  <Icon name='arrow-downward-outline' size={24} />
                </View>
                <DateTimeInput
                  name='content.end'
                  defaultValue={new Date()}
                  label='End'
                />
              </>
              )
            : (
              <Button appearance='ghost' status='basic' onPress={() => setHasEnd(true)}>Add end date</Button>
              )}
        </View>
        <View style={styles.location}>
          <TextInput
            placeholder='Location address or video call link'
            name='content.location'
            rules={{ required: 'Location is required' }}
            style={styles.placeInput}
            defaultValue=''
          />
          <MapLoader map={<MapPreview name='content.location' />} />
        </View>
      </View>
      <CardButtons>
        <ButtonSubmit status='primary' onSubmit={handleSubmit}>
          {submitLabel}
        </ButtonSubmit>
      </CardButtons>
    </>
  )
}
