import { useController } from 'react-hook-form'

export default function ButtonSelect ({ name, renderButton, value }) {
  const { field: { value: currentValue, onChange } } = useController({ name })

  return renderButton({
    onPress: () => onChange(value),
    status: currentValue === value ? 'info' : 'basic'
  })
}
