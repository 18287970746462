import { Input, useStyleSheet } from '@ui-kitten/components'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

const themedStyles = StyleSheet.create({
  input: {
    width: '100%'
  }
})

export default function TextInput ({ name, placeholder, defaultValue, rules, style, ...props }) {
  const { control } = useFormContext()
  const styles = useStyleSheet(themedStyles)
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error }
      }) => (
        <Input
          style={[styles.input, style]}
          placeholder={t(placeholder)}
          value={value}
          onChangeText={onChange}
          autoCapitalize='none'
          status={error ? 'danger' : 'basic'}
          {...props}
        />
      )}
    />
  )
}
