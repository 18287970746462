import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyBVUaUZ3KQwdWzA3_Y8utLQnL_QZ5Om5qY',
  authDomain: 'incircl.firebaseapp.com',
  projectId: 'incircl',
  storageBucket: 'incircl.appspot.com',
  messagingSenderId: '34467099343',
  appId: '1:34467099343:web:598ad986c3d92ee9f3bdba'
}

export const vapidKey = 'BPT6huJ3qplHWNyHccdsFU3I5dSet6XioLphhfPDEmRq4eSc9_tCSNKgjSuJlXdwMXRpub43bWhFMbT7Ao1a1sg'

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
