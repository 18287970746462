import Icon from 'app/components/Icon'
import TextInput from 'app/components/forms/TextInput'
import { REGEX_EMAIL_VALIDATION, REGEX_NUMBER_VALIDATION, REGEX_PHONE_VALIDATION } from 'app/validations'

const icons = {
  email: 'email-outline',
  number: 'hash',
  phone: 'phone-outline'
}

const validations = {
  email: REGEX_EMAIL_VALIDATION,
  number: REGEX_NUMBER_VALIDATION,
  phone: REGEX_PHONE_VALIDATION
}

export default function FormFieldTypeText ({ name, validation, disabled, value }) {
  return (
    <TextInput
      name={name}
      accessoryRight={icons[validation] && <Icon name={icons[validation]} />}
      disabled={disabled}
      rules={{ required: !disabled, pattern: validations[validation] }}
      defaultValue={value}
    />
  )
}
