import ScreenContent from 'app/components/layout/ScreenContent'
import FeedList from 'app/modules/items/feed/FeedList'
import { useParams } from 'react-router-native'
import FormCard from './FormCard'

export default function FormsScreen () {
  const { organizationId } = useParams()

  return (
    <ScreenContent title='Forms' description='All forms'>
      <FeedList
        organizationId={organizationId}
        section='form'
        renderItem={item => <FormCard item={item} />}
      />
    </ScreenContent>
  )
}
