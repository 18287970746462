import { FlatList, StyleSheet } from 'react-native'
import { useSubmissions } from 'app/api/forms'
import { Text } from '@ui-kitten/components'
import { TableContainer, TableHeader, TableRow } from 'app/components/Table'
import FormSubmissionListItem from './FormSubmissionListItem'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  list: {
    overflowX: 'scroll',
    borderRadius: 4
  }
})

export default function FormSubmissionsList ({ item }) {
  const { submissions, setSize, mutate, isLoading, size } = useSubmissions(item.organizationId, item.id)
  const { t } = useTranslation()

  if (!submissions) return null

  return (
    <TableContainer>
      <FlatList
        style={styles.list}
        ListHeaderComponent={
          <TableRow>
            <TableHeader></TableHeader>
            {(item.content.fields || []).map(field => (
              <TableHeader key={field.id}>
                <Text category='label' appearance='hint'>{field.label.toUpperCase()}</Text>
              </TableHeader>
            ))}
            <TableHeader key='date'>
              <Text category='label' appearance='hint'>{t('Submitted on')}</Text>
            </TableHeader>
          </TableRow>
        }
        stickyHeaderIndices={[0]}
        renderItem={({ item: submission }) => (
          <FormSubmissionListItem
            key={submission.id}
            submission={submission}
            fields={item.content.fields}
            organizationId={item.organizationId}
          />
        )}
        keyExtractor={submission => submission.id}
        data={submissions.flat()}
        onEndReached={() => setSize(size + 1)}
        onEndReachedThreshold={1}
        refreshing={isLoading}
        onRefresh={() => mutate()}
      />
    </TableContainer>
  )
}
