import { checkApiResponseErrors } from './errors'
import { apiUrl } from 'app/config'

export async function requestAccessToken (params) {
  const formBody = []

  for (const property in params) {
    const encodedKey = encodeURIComponent(property)
    const encodedValue = encodeURIComponent(params[property])
    formBody.push(`${encodedKey}=${encodedValue}`)
  }

  const res = await fetch(`${apiUrl}/api/v1/oauth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: formBody.join('&')
  })

  await checkApiResponseErrors(res)

  return res.json()
}

export async function logIn (email, password, clientId) {
  return requestAccessToken({
    client_id: clientId,
    grant_type: 'password',
    username: email,
    password
  })
}

export async function requestAuthorizationCode (token, clientId, redirectUri) {
  const res = await fetch(`${apiUrl}/api/v1/oauth/authorize`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      state: '12345',
      response_type: 'code',
      client_id: clientId,
      redirect_uri: redirectUri
    })
  })

  await checkApiResponseErrors(res)

  return res.json()
}

let lastCodeExchange

export async function exchangeCodeForToken (code, clientId) {
  if (!lastCodeExchange) {
    lastCodeExchange = requestAccessToken({
      client_id: clientId,
      grant_type: 'authorization_code',
      redirect_uri: `${window.location.protocol}//${window.location.host}`,
      code
    })
  }
  return lastCodeExchange
}

export async function requestTokenWithRefreshToken (refreshToken, clientId) {
  return requestAccessToken({
    client_id: clientId,
    grant_type: 'refresh_token',
    refresh_token: refreshToken
  })
}
