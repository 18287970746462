import { useStyleSheet } from '@ui-kitten/components'
import { Pressable, StyleSheet, View } from 'react-native'
import CardFrame from './CardFrame'

const themedStyles = StyleSheet.create({
  content: {
    marginBottom: 4,
    gap: 16,
    flexDirection: 'column'
  }
})

export default function CardPressable ({
  onPress,
  onLongPress,
  onMouseEnter,
  onMouseLeave,
  children
}) {
  const styles = useStyleSheet(themedStyles)

  return (
    <Pressable
      onPress={onPress}
      onLongPress={onLongPress}
    >
      <CardFrame
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <View style={styles.content}>
          {children}
        </View>
      </CardFrame>
    </Pressable>
  )
}
