import { Pressable } from 'react-native'
import Icon from 'app/components/Icon'
import { theme } from 'app/theme'
import { useState } from 'react'

export default function AddFormFieldButton ({ icon, onPress }) {
  const [mouseOver, setMouseOver] = useState(false)

  return (
    <Pressable
      onPress={onPress}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <Icon name={icon} size={24} fill={mouseOver ? theme['color-primary-500'] : theme['color-basic-600']} />
    </Pressable>
  )
}
