import { animated, useSpring } from 'app/components/animations/spring'
import { View } from 'react-native'

const AnimatedView = animated(View)

export default function FadeInView ({ children, style, animate = true }) {
  const springStyles = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  })

  return (
    <AnimatedView style={Object.assign({}, style, animate && springStyles)}>
      {children}
    </AnimatedView>
  )
}
