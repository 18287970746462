import { Avatar as AvatarKitten } from '@ui-kitten/components'
import { memo } from 'react'
import { StyleSheet } from 'react-native'
import { theme } from 'app/theme'

const styles = StyleSheet.create({
  avatar: {
    backgroundColor: theme['color-primary-100']
  }
})

const Avatar = memo((props) => {
  return <AvatarKitten {...props} style={styles.avatar} />
})

Avatar.displayName = 'Avatar'

export default Avatar
