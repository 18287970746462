import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import CardPressable from 'app/components/cards/CardPressable'
import ItemMenu from 'app/modules/items/components/ItemMenu'
import FormSummary from './FormSummary'
import CardHeader from 'app/components/cards/CardHeader'
import ItemMenuLink from 'app/modules/items/components/ItemMenuLink'

export default function FormCard ({ item }) {
  const [mouseOver, setMouseOver] = useState(false)
  const navigate = useNavigate()

  const onPress = () => navigate(`/org/${item.organizationId}/items/${item.itemId}`)

  return (
    <CardPressable
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onPress={onPress}
    >
      <CardHeader
        showButtonMenu={mouseOver}
        menu={<ItemMenu
          item={item}
          options={(
            <ItemMenuLink
              to={`/org/${item.organizationId}/forms/${item.itemId}/submissions`}
              title='Submissions'
              icon='inbox-outline'
            />
          )}
        />}
        onPress={onPress}
      >
        <FormSummary
          item={item}
        />
      </CardHeader>
    </CardPressable>
  )
}
