import { FlatList, StyleSheet } from 'react-native'
import { Text } from '@ui-kitten/components'
import { TableContainer, TableHeader, TableRow } from 'app/components/Table'
import { useEventAttendanceList } from 'app/api/events'
import EventAttendanceListItem from './EventAttendanceListItem'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  list: {
    overflowX: 'scroll',
    borderRadius: 4
  }
})

export default function EventAttendanceList ({ item }) {
  const { attendances, setSize, mutate, isLoading, size } = useEventAttendanceList(item.organizationId, item.id)
  const { t } = useTranslation()

  if (!attendances) return null

  return (
    <TableContainer>
      <FlatList
        style={styles.list}
        ListHeaderComponent={
          <TableRow>
            <TableHeader></TableHeader>
            <TableHeader>
              <Text category='label' appearance='hint'>{t('Attending')}</Text>
            </TableHeader>
            <TableHeader>
              <Text category='label' appearance='hint'>{t('Submitted on')}</Text>
            </TableHeader>
          </TableRow>
        }
        stickyHeaderIndices={[0]}
        renderItem={({ item: attendance }) => (
          <EventAttendanceListItem
            key={attendance.id}
            attendance={attendance}
            organizationId={item.organizationId}
          />
        )}
        keyExtractor={attendance => attendance.id}
        data={attendances.flat()}
        onEndReached={() => setSize(size + 1)}
        onEndReachedThreshold={1}
        refreshing={isLoading}
        onRefresh={() => mutate()}
      />
    </TableContainer>
  )
}
