import HStack from 'app/components/layout/HStack'
import ButtonSelect from 'app/components/forms/ButtonSelect'
import ButtonPill from 'app/components/buttons/ButtonPill'

export default function RoleSelect () {
  return (
    <HStack>
      <ButtonSelect
        name='role'
        value='admin'
        renderButton={props => <ButtonPill size='tiny' appearance='outline' {...props}>Admin</ButtonPill>}
      />
      <ButtonSelect
        name='role'
        value='leader'
        renderButton={props => <ButtonPill size='tiny' appearance='outline' {...props}>Leader</ButtonPill>}
      />
      <ButtonSelect
        name='role'
        value='member'
        renderButton={props => <ButtonPill size='tiny' appearance='outline' {...props}>Member</ButtonPill>}
      />
    </HStack>
  )
}
