import { useFormContext } from 'react-hook-form'
import { Text } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'
import MessageCard from 'app/components/cards/MessageCard'
import { useEffect } from 'react'
import { useNotifications } from 'app/components/notifications/NotificationsContext'
import { useIsMobile } from 'app/components/layout/mediaQueries'
import { useTranslation } from 'react-i18next'

function extractErrors (errors) {
  const messages = []
  Object.entries(errors).forEach(([key, value]) => {
    if (typeof value.message === 'string') {
      messages.push(value.message)
      return
    }
    messages.push(...extractErrors(value))
  })
  return messages
}

export default function NotifyErrors () {
  const { clearErrors, formState: { errors } } = useFormContext()
  const { notify } = useNotifications()
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  useEffect(() => {
    const messages = extractErrors(errors)

    if (isMobile && messages.length > 0) {
      notify(t('There are errors in the form'), messages.map(message => t(message)).join('\n'), 'danger')
      clearErrors()
    }
  }, [errors, Object.keys(errors).length])

  if (isMobile || Object.keys(errors).length === 0) return null

  return (
    <MessageCard
      type='danger'
      title='There are errors in the form'
      onDismiss={() => clearErrors()}
    >
      <Stack paddingHorizontal={12} paddingVertical={0} gap={4}>
        {extractErrors(errors).map((value, key) => (
          <Text key={key} category='p2' appearance='alternative'>• {t(value)}</Text>
        ))}
      </Stack>
    </MessageCard>
  )
}
