import React from 'react'
import { useNavigate } from 'react-router'
import CardPressable from 'app/components/cards/CardPressable'
import ItemSummary from 'app/modules/items/components/ItemSummary'
import CardContent from 'app/components/cards/CardContent'
import { Text } from '@ui-kitten/components'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  label: {
    textTransform: 'uppercase'
  }
})

export default function ItemResultCard ({ item }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <CardPressable
      onPress={() => navigate(`/org/${item.organizationId}/items/${item.itemId}`)}
      header={() => (
        <CardContent>
          <Text category='label' style={styles.label}>{t(item.type)}</Text>
        </CardContent>
      )}
    >
      <ItemSummary item={item} numberOfLines={5} />
    </CardPressable>
  )
}
