import { createContext, useContext, useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { oauthClientId } from 'app/config'
import { requestTokenWithRefreshToken } from 'app/api/oauth-client'
import { ApiError } from 'app/api/errors'

let lastTokenRefresh = null

export const SessionContext = createContext({
  hasToken: false,
  accessToken: null,
  getToken: () => { },
  saveToken: () => { },
  deleteToken: () => { },
  refreshToken: () => { },
  logOut: () => { }
})

export const SessionProvider = ({ children }) => {
  const [hasToken, setHasToken] = useState()
  const [accessToken, setAccessToken] = useState()

  async function getToken () {
    return JSON.parse(await AsyncStorage.getItem('token'))
  }

  async function saveToken (token) {
    await AsyncStorage.setItem('token', JSON.stringify(token))
    setHasToken(true)
    setAccessToken(jwtDecode(token.accessToken))
  }

  async function deleteToken () {
    await AsyncStorage.removeItem('token')
  }

  function logOut () {
    deleteToken()
    setHasToken(false)
  }

  async function refreshToken () {
    if (lastTokenRefresh) return lastTokenRefresh

    const { refreshToken } = await getToken()
    try {
      lastTokenRefresh = requestTokenWithRefreshToken(refreshToken, oauthClientId)
        .then(newToken => saveToken(newToken))
      await lastTokenRefresh
      lastTokenRefresh = null
    } catch (err) {
      if (err instanceof ApiError && [400, 401].includes(err.status)) {
        await logOut()
      } else throw err
    }
  }

  useEffect(() => {
    (async () => {
      const token = await getToken()
      setHasToken(token !== null)
      setAccessToken(jwtDecode(token.accessToken))
    })()
  }, [])

  if (hasToken === undefined) {
    return null
  }

  return (
    <SessionContext.Provider value={{
      getToken,
      saveToken,
      deleteToken,
      refreshToken,
      logOut,
      accessToken,
      hasToken
    }}>
      {children}
    </SessionContext.Provider>
  )
}

export function useSession () {
  return useContext(SessionContext)
}
