import { StyleSheet } from 'react-native'
import TextInput from './TextInput'

const styles = StyleSheet.create({
  input: {
    marginHorizontal: -6,
    padding: 0,
    marginVertical: -4
  },
  container: {
    borderWidth: 0,
    borderRadius: 0,
    borderBottomWidth: 1,
    marginHorizontal: -2,
    marginVertical: -6,
    minHeight: 30
  }
})

export default function TextInputInline (props) {
  return (
    <TextInput style={styles.container} textStyle={styles.input} {...props} />
  )
}
