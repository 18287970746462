import ScreenContent from 'app/components/layout/ScreenContent'
import FeedList from 'app/modules/items/feed/FeedList'
import { useParams } from 'react-router-native'
import EventCard from './EventCard'

export default function EventsScreen () {
  const { organizationId } = useParams()

  return (
    <ScreenContent title='Events' description='All events'>
      <FeedList
        organizationId={organizationId}
        section='event'
        renderItem={item => <EventCard item={item} />}
      />
    </ScreenContent>
  )
}
