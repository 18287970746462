import { NotificationsClient } from 'app/notifications/client'
import { useMemberMeDevices } from 'app/api/members'
import ButtonAsync from 'app/components/buttons/ButtonAsync'
import { useNotifications } from 'app/components/notifications/NotificationsContext'
import { useTranslation } from 'react-i18next'

const client = new NotificationsClient()

export function EnablePushNotificationsButton ({ organizationId, onEnable }) {
  const { create } = useMemberMeDevices(organizationId)
  const { notify } = useNotifications()
  const { t } = useTranslation()

  const onPress = async () => {
    try {
      const granted = await client.requestPermission()

      if (granted) {
        try {
          const token = await client.getToken()
          if (token) {
            await create(token)
          }
        } catch (err) {
          if (err.status !== 409) throw err
        }
        onEnable()
      }
    } catch (err) {
      if (err.message.match(/Registration failed/)) {
        notify(
          t('Couldn\'t enable notifications'),
          t('You may need to enable Google Push Notifications in your browser or device first'),
          'danger'
        )
      } else throw err
    }
  }

  return (
    <ButtonAsync
      onPress={onPress}
    >
      {t('Enable notifications')}
    </ButtonAsync>
  )
}
