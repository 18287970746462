import SignInScreen from 'app/modules/authentication/SignInScreen'
import SignUpScreen from 'app/modules/authentication/SignUpScreen'
import SelectOrganizationScreen from 'app/modules/organizations/SelectOrganizationScreen'
import CreateOrganizationScreen from 'app/modules/organizations/CreateOrganizationScreen'
import ArticlesScreen from 'app/modules/items/articles/ArticlesScreen'
import FeedScreen from 'app/modules/items/feed/FeedScreen'
import PollsScreen from 'app/modules/items/polls/PollsScreen'
import FilesScreen from 'app/modules/items/files/FilesScreen'
import EventsScreen from 'app/modules/items/events/EventsScreen'
import FormsScreen from 'app/modules/items/forms/FormsScreen'
import OrganizationLayout from 'app/components/layout/OrganizationLayout'
import { Route, Routes, Navigate } from 'react-router'
import ProfileScreen from 'app/modules/members/profile/ProfileScreen'
import RecoverPasswordScreen from 'app/modules/authentication/RecoverPasswordScreen'
import SplitScreenLayout from 'app/components/layout/SplitScreenLayout'
import { useContext } from 'react'
import { SessionContext } from 'app/components/SessionContext'
import { MembersHomeScreen } from 'app/modules/members/MembersHomeScreen'
import CreateItemScreen from 'app/modules/items/CreateItemScreen'
import EditItemScreen from 'app/modules/items/EditItemScreen'
import ProfileSetupScreen from 'app/modules/authentication/ProfileSetupScreen'
import PublishTypeSelectionScreen from './modules/items/PublishTypeSelectionScreen'
import SignedInLayout from 'app/components/layout/SignedInLayout'
import ViewItemScreen from './modules/items/ViewItemScreen'
import SearchScreen from './modules/search/SearchScreen'
import FormSubmissionsScreen from './modules/items/forms/FormSubmissionsScreen'
import OrganizationSetupScreen from './modules/organizations/setup/OrganizationSetupScreen'
import AcceptInvitationScreen from './modules/members/invitations/AcceptInvitationScreen'
import MenuScreen from './modules/menu/MenuScreen'
import MemberScreen from './modules/members/MemberScreen'
import AuthHome from './modules/authentication/AuthHome'
import EventAttendanceScreen from './modules/items/events/EventAttendanceScreen'

export default function AppRoutes () {
  const { hasToken } = useContext(SessionContext)

  if (hasToken) {
    return (
      <Routes>
        <Route path='/org/:organizationId/*' element={<OrganizationLayout />}>
          <Route index element={<FeedScreen />} />
          <Route path='feed' element={<FeedScreen />} />
          <Route path='articles' element={<ArticlesScreen />} />
          <Route path='events' element={<EventsScreen />} />
          <Route path='files' element={<FilesScreen />} />
          <Route path='forms' element={<FormsScreen />} />
          <Route path='members/me/*' element={<ProfileScreen />} />
          <Route path='members/:userId/*' element={<MemberScreen />} />
          <Route path='members' element={<MembersHomeScreen />} />
          <Route path='polls' element={<PollsScreen />} />
          <Route path='profile' element={<ProfileScreen />} />
          <Route path='publish' element={<PublishTypeSelectionScreen />} />
          <Route path='publish/create/:type' element={<CreateItemScreen />} />
          <Route path='publish/edit/:itemId' element={<EditItemScreen />} />
          <Route path='items/:itemId' element={<ViewItemScreen />} />
          <Route path='search/:type/:query?' element={<SearchScreen />} />
          <Route path='forms/:itemId/submissions' element={<FormSubmissionsScreen />} />
          <Route path='events/:itemId/attendance' element={<EventAttendanceScreen />} />
          <Route path='setup' element={<OrganizationSetupScreen />} />
          <Route path='menu' element={<MenuScreen />} />
        </Route>
        <Route path='/join/:organizationId' element={<SplitScreenLayout />}>
          <Route index element={<AcceptInvitationScreen />} />
        </Route>
        <Route path='/user-setup' element={<SplitScreenLayout />}>
          <Route index element={<ProfileSetupScreen />} />
        </Route>
        <Route path='/*' element={<SignedInLayout />}>
          <Route path='create-organization' element={<CreateOrganizationScreen />} />
          <Route path='select-organization' element={<SelectOrganizationScreen />} />
          <Route path='*' element={<Navigate replace to='/select-organization' />} />
        </Route>
      </Routes>
    )
  } else {
    return (
      <Routes>
        <Route path='*' element={<Navigate replace to='' />} />
        <Route path='/*' element={<SplitScreenLayout />}>
          <Route index element={<AuthHome />} />
          <Route path='sign-in' element={<SignInScreen />} />
          <Route path='recover-password' element={<RecoverPasswordScreen />} />
          <Route path='sign-up' element={<SignUpScreen />} />
        </Route>
      </Routes>
    )
  }
}
