import { StyleSheet, View } from 'react-native'
import CurrentMemberInfo from 'app/components/CurrentMemberInfo'
import SearchBar from 'app/modules/search/SearchBar'
import OrganizationBlock from './OrganizationBlock'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10
  },
  left: {
    flexDirection: 'row',
    flexGrow: 1,
    gap: 10
  },
  input: {
    flexGrow: 1,
    maxWidth: 590
  }
})

export default function TopBar () {
  return (
    <View style={styles.container}>
      <OrganizationBlock />
      <SearchBar />
      <CurrentMemberInfo />
    </View>
  )
}
