import { Text } from '@ui-kitten/components'
import { useCurrentOrganization } from 'app/api/users'
import { useTranslation } from 'react-i18next'

export default function FormSubmissions ({ item }) {
  const { t } = useTranslation()
  const { organization } = useCurrentOrganization()

  return (
    <Text category='c1' appearance='hint'>
      {item.content.submissions || 0} / {organization.totalMembers} {t('forms submitted')}
    </Text>
  )
}
