import { Text } from '@ui-kitten/components'
import { StyleSheet, View } from 'react-native'
import { theme } from 'app/theme'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    padding: 4
  },
  text: {
    textTransform: 'uppercase'
  }
})

export default function Label ({ type = 'info', children }) {
  const { t } = useTranslation()
  return (
    <View style={[styles.container, { backgroundColor: theme[`color-${type}-500`] }]}>
      <Text
        category='label'
        appearance='alternative'
        style={styles.text}
      >
        {t(children)}
      </Text>
    </View>
  )
}
