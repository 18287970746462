import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import CardPressable from 'app/components/cards/CardPressable'
import CardHeader from 'app/components/cards/CardHeader'
import ItemMenu from 'app/modules/items/components/ItemMenu'
import PollContent from 'app/modules/items/view/PollContent'
import CardSummary from 'app/modules/items/components/CardSummary'
import PollsStatusLabel from './PollsStatusLabel'

export default function PollsCard ({ item }) {
  const [mouseOver, setMouseOver] = useState(false)
  const navigate = useNavigate()

  return (
    <CardPressable
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onPress={() => navigate(`/org/${item.organizationId}/items/${item.itemId}`)}
    >
      <CardHeader
        showButtonMenu={mouseOver}
        menu={<ItemMenu item={item} />}
      >
        <CardSummary item={item} statusLabel={<PollsStatusLabel isDone={item.content.voted} />} />
      </CardHeader>
      <PollContent organizationId={item.organizationId} item={item} />
    </CardPressable>
  )
}
