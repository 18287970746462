import Form from 'app/components/forms/Form'
import { EditableSection } from 'app/components/forms/EditableSection'
import EditableSectionHeader from 'app/components/forms/EditableSectionHeader'
import EditableField from 'app/components/forms/EditableField'
import TextInputInline from 'app/components/forms/TextInputInline'
import { Text } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'
import { useOrganization } from 'app/api/organizations'
import HStack from 'app/components/layout/HStack'

export default function OrganizationSetupForm ({ organizationId }) {
  const { organization, update } = useOrganization(organizationId)

  if (!organization) return null

  return (
    <Form defaultValues={organization}>
      <EditableSection>
          <EditableSectionHeader
            title='Information'
            onSave={update}
          />
        <Stack gap={12}>
          <HStack>
            <EditableField
              label='Name'
              renderView={() => <Text>{organization.name}</Text>}
              renderEdit={() => <TextInputInline name='name' />}
            />
          </HStack>
          <HStack>
            <EditableField
              label='Slogan'
              renderView={() => <Text>{organization.slogan}</Text>}
              renderEdit={() => <TextInputInline name='slogan' />}
            />
          </HStack>
        </Stack>
      </EditableSection>
    </Form>
  )
}
