import { Text } from '@ui-kitten/components'
import MarkdownText from 'app/components/editor/MarkdownText'

export default function ArticleContent ({ item: { organizationId, content } }) {
  return (
    <Text category='p2'>
      <MarkdownText
        organizationId={organizationId}
        text={content.text}
        category='p1'
      />
    </Text>
  )
}
