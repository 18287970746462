import { StyleSheet, View } from 'react-native'
import FileItemDownload from 'app/components/files/FileItemDownload'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: 12,
    gap: 12,
    flexWrap: 'wrap'
  }
})

export default function FilesContent ({ item: { content, organizationId } }) {
  return (
    <View style={styles.container}>
      {content.files.map(file => (
        <FileItemDownload key={file.fileId} organizationId={organizationId} file={file} />
      ))}
    </View>
  )
}
