import { Text } from '@ui-kitten/components'
import { Pressable, StyleSheet, View } from 'react-native'
import Avatar from 'app/components/Avatar'
import { useFileLocalUrl } from 'app/api/files'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 14
  },
  data: {
    flexDirection: 'column',
    gap: 2,
    justifyContent: 'center'
  }
})

export default function MemberInfo ({ user, member, direction = 'left', size = 'medium', onPress }) {
  const avatarLocalURL = useFileLocalUrl(member.organizationId, member.avatarFileId)

  return (
    <View style={[styles.container, { flexDirection: direction === 'left' ? 'row' : 'row-reverse' }]}>
      <Pressable onPress={onPress}>
        <Avatar source={avatarLocalURL} size={size} />
      </Pressable>
      <View style={[styles.data, { alignItems: direction === 'left' ? 'flex-start' : 'flex-end' }]}>
        <Text category={size === 'medium' ? 'h6' : 's2'}>{user.name} {size === 'medium' ? '' : user.surname}</Text>
        {member && (
          <Text category="c1" appearance='hint'>{member.title}</Text>
        )}
      </View>
    </View>
  )
}
