import { LinearGradient } from 'expo-linear-gradient'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  gradient: {
    height: 35,
    width: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 1
  }
})

export default function FadeTop () {
  return (
    <LinearGradient
      colors={['#F7F9FC', 'transparent']}
      style={styles.gradient}
    />
  )
}
