import { BottomNavigation, BottomNavigationTab } from '@ui-kitten/components'
import { useNavigate, useResolvedPath, useParams } from 'react-router'
import Icon from 'app/components/Icon'
import { useMember } from 'app/api/members'
import MemberAvatar from 'app/modules/members/components/MemberAvatar'
import { useTranslation } from 'react-i18next'
import { HasAccess } from 'app/modules/authentication/HasAccess'

const routes = [
  'feed',
  'search/items',
  'publish',
  'menu',
  'members/me'
]

export default function BottomTabNavigation () {
  const navigate = useNavigate()
  const { pathname } = useResolvedPath()
  const { organizationId } = useParams()
  const { member } = useMember(organizationId, 'me')
  const { t } = useTranslation()

  return (
    <BottomNavigation
      style={{ backgroundColor: 'white' }}
      selectedIndex={routes.findIndex(route => pathname.replace(`/org/${organizationId}/`, '').startsWith(route))}
      onSelect={index => navigate(`/org/${organizationId}/${routes[index]}`)}>
      <BottomNavigationTab
        title={t('Feed')}
        icon={<Icon name='message-square-outline' />}
      />
      <BottomNavigationTab
        title={t('Search')}
        icon={<Icon name='search-outline' />}
      />
      <HasAccess scope='items:write'>
        <BottomNavigationTab
          title={t('Publish')}
          icon={<Icon name='plus-circle-outline' />}
        />
      </HasAccess>
      <BottomNavigationTab
        title={t('More')}
        icon={<Icon name='menu-outline' />}
      />
      <BottomNavigationTab
        title={t('Profile')}
        icon={<MemberAvatar member={member} size='small' />}
      />
    </BottomNavigation>
  )
}
