import Text from 'app/i18n/Text'
import { StyleSheet, View } from 'react-native'
import Icon from 'app/components/Icon'
import { theme } from 'app/theme'

const styles = StyleSheet.create({
  container: {
    borderColor: theme['color-basic-400'],
    borderWidth: 1,
    borderRadius: 4,
    gap: 20,
    padding: 20,
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 24
  },
  text: {
    color: theme['color-basic-600']
  },
  icon: {
    fill: theme['color-basic-600']
  }
})

export default function RelatedEditEmptyBox () {
  return (
    <View style={styles.container}>
      <Icon name='link' size={40} fill={styles.icon.fill} />
      <Text category='c2' style={styles.text}>
        Write other items IDs in your content to relate them. All related items will appear here. Example: item#12
      </Text>
    </View>
  )
}
