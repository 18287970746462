import React from 'react'
import ItemMenuPin from './ItemMenuPin'
import ItemMenuRemove from './ItemMenuRemove'
import ItemMenuLink from './ItemMenuLink'

export default function ItemMenu ({ item, options, onDelete }) {
  return (
    <>
      <ItemMenuPin item={item} />
      {options}
      <ItemMenuRemove item={item} onDelete={onDelete} />
      <ItemMenuLink
        to={`/org/${item.organizationId}/publish/edit/${item.itemId}`}
        title='Edit'
        icon='edit-outline'
      />
    </>
  )
}
