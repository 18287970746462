import { Text } from '@ui-kitten/components'
import Form from 'app/components/forms/Form'
import HStack from 'app/components/layout/HStack'
import Stack from 'app/components/layout/Stack'
import { useMember } from 'app/api/members'
import MemberAvatar from 'app/modules/members/components/MemberAvatar'
import PillText from 'app/components/PillText'
import { useTranslation } from 'react-i18next'

export default function ProfileHeader ({ organizationId, userId }) {
  const { member } = useMember(organizationId, userId)
  const { t } = useTranslation()

  if (!member) return null

  return (
    <Form defaultValues={member}>
      <HStack gap={24}>
        <MemberAvatar member={member} size='giant' />
        <Stack gap={8}>
          <HStack alignItems='flex-start'>
            <Stack gap={2}>
              <Text category='s1'>{member.user.name} {member.user.surname}</Text>
              <HStack>
                <Text category='c1' appearance='hint'>{member.title}</Text>
              </HStack>
            </Stack>
          </HStack>
          <HStack>
            <PillText>{t(member.role)}</PillText>
          </HStack>
        </Stack>
      </HStack>
    </Form>
  )
}
