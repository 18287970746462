import ScreenContent from 'app/components/layout/ScreenContent'
import FeedList from 'app/modules/items/feed/FeedList'
import { useParams } from 'react-router-native'
import FilesCard from 'app/modules/items/files/FilesCard'

export default function FilesScreen () {
  const { organizationId } = useParams()

  return (
    <ScreenContent title='Files' description='All files'>
      <FeedList
        organizationId={organizationId}
        section='files'
        renderItem={item => <FilesCard item={item} />}
      />
    </ScreenContent>
  )
}
