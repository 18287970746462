import useSWRInfinite from 'swr/infinite'
import { useApi } from './api'

export function useEventAttendance (organizationId, eventId) {
  const send = useApi()
  const path = `v1/organizations/${organizationId}/events/${eventId}/attendances`

  return attending => {
    return send('POST', path, { attending })
  }
}

export function useEventAttendanceList (organizationId, eventId, take = 10) {
  const path = `v1/organizations/${organizationId}/events/${eventId}/attendances`
  const { data: attendances, ...props } = useSWRInfinite((pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null
    return `${path}?skip=${pageIndex * take}&take=${take}`
  })

  return {
    attendances,
    ...props
  }
}
