import Header from './Header'
import { useIsMobile } from './mediaQueries'
import { StyleSheet, View } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    gap: 12,
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 900,
    width: '100%',
    marginHorizontal: 'auto'
  },
  containerMobile: {
    marginTop: 15
  },
  header: {
    gap: 8
  },
  headerMobile: {
    marginHorizontal: 16
  },
  content: {
    flexGrow: 1,
    flexShrink: 1
  }
})

export default function ScreenContent ({ description, title, buttons, onAddPress, children, headerContent }) {
  const isMobile = useIsMobile()

  return (
    <View style={[styles.container, isMobile && styles.containerMobile]}>
      <View style={[styles.header, isMobile && styles.headerMobile]}>
        <Header description={description} title={title} onAddPress={onAddPress}>
          {buttons}
        </Header>
        {headerContent}
      </View>
      <View style={styles.content}>
        {children}
      </View>
    </View>
  )
}
