import { Button, Divider, Text } from '@ui-kitten/components'
import { useSession } from 'app/components/SessionContext'
import Stack from 'app/components/layout/Stack'
import Icon from 'app/components/Icon'
import HStack from 'app/components/layout/HStack'
import EditableSectionHeader from 'app/components/forms/EditableSectionHeader'
import { EditableSection } from 'app/components/forms/EditableSection'
import EditableField from 'app/components/forms/EditableField'
import Form from 'app/components/forms/Form'
import { useUser } from 'app/api/users'
import PasswordInput from 'app/components/forms/PasswordInput'
import PasswordRules from 'app/components/forms/PasswordRules'
import TextInputInline from 'app/components/forms/TextInputInline'
import NotificationsConfigSection from './notifications/NotificationsConfigSection'
import { useTranslation } from 'react-i18next'
import Select from 'app/components/forms/Select'
import MemberSelfUpdate from './MemberSelfUpdate'

const languages = { en: 'English', es: 'Español' }

export default function ProfileInformationTab ({ organizationId, userId }) {
  const { logOut } = useSession()
  const { user, update } = useUser(userId)
  const { t, i18n } = useTranslation()

  const onUserSave = async (user) => {
    await update(user)
    i18n.changeLanguage(user.language)
  }

  if (!user) return null

  return (
    <Stack gap={24}>
      <EditableSection>
        <Form defaultValues={user}>
          <EditableSectionHeader
            title='User Details'
            onSave={onUserSave}
          />
          <Stack>
            <HStack justifyContent='space-between'>
              <EditableField
                label='First name'
                renderView={() => <Text>{user.name}</Text>}
                renderEdit={() => <TextInputInline name='name' />}
              />
              <EditableField
                label='Last name'
                renderView={() => <Text>{user.surname}</Text>}
                renderEdit={() => <TextInputInline name='surname' />}
              />
            </HStack>
            <HStack justifyContent='space-between'>
              <EditableField
                label='Language'
                renderView={() => <Text>{languages[user.language] || t('not set')}</Text>}
                renderEdit={() => (
                  <Select
                    name='language'
                    values={['en', 'es']}
                    labels={languages}
                  />
                )}
              />
            </HStack>
            <Stack>
              <Text category='label' appearance='hint'>EMAIL</Text>
              <Text>{user.email}</Text>
            </Stack>
          </Stack>
        </Form>
      </EditableSection>
      <Divider />
      <MemberSelfUpdate organizationId={organizationId} userId={userId} />
      <Divider />
      <NotificationsConfigSection
        organizationId={organizationId}
        userId={userId}
      />
      <Divider />
      <EditableSection>
        <Form defaultValues={{ ...user, password: '' }}>
          <EditableSectionHeader
            title='Security'
            onSave={user => update(user)}
          />
          <HStack>
            <EditableField
              label='Password'
              renderView={() => <Text>********</Text>}
              renderEdit={() => (
                <HStack>
                  <Stack>
                    <PasswordInput
                      name='password'
                      placeholder='New password'
                      size='small'
                    />
                    <PasswordRules fieldName='password' />
                  </Stack>
                  <Stack>
                    <PasswordInput
                      name='passwordConfirmation'
                      placeholder='Confirm password'
                      size='small'
                      compareTo='password'
                    />
                  </Stack>
                </HStack>
              )}
            />
          </HStack>
        </Form>
      </EditableSection>
      <Stack alignItems='flex-end'>
        <Button
          size='small'
          appearance='outline'
          status='danger'
          onPress={(() => logOut())}
          accessoryRight={props => <Icon name='log-out-outline' {...props} />}
        >
          {t('Sign Out')}
        </Button>
      </Stack>
    </Stack>
  )
}
