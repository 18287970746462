import { Button, Popover, Text } from '@ui-kitten/components'
import SmileyIcon from 'app/components/icons/SmileyIcon'
import ButtonCircle from 'app/components/buttons/ButtonCircle'
import { popoverShadow, theme } from 'app/theme'
import { useState } from 'react'
import { emojiMap } from './emojis'
import { StyleSheet, View } from 'react-native'

const styles = StyleSheet.create({
  emojis: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 4,
    padding: 4
  },
  popover: {
    marginTop: 4,
    ...popoverShadow
  }
})

export default function ButtonAddReaction ({ onPressEmoji }) {
  const [visible, setVisible] = useState(false)

  const onPress = reaction => {
    onPressEmoji(reaction)
    setVisible(false)
  }

  return (
    <Popover
      visible={visible}
      placement='bottom start'
      style={styles.popover}
      anchor={() => (
        <View>
          <ButtonCircle
            icon={<SmileyIcon style={{ tintColor: theme['color-basic-600'] }} />}
            onPress={() => setVisible(true)}
          />
        </View>
      )}
      onBackdropPress={() => setVisible(false)}
    >
      <View style={styles.emojis}>
        {Object.entries(emojiMap).map(([reaction, emoji]) => (
          <Button
            key={reaction}
            onPress={() => onPress(reaction)}
            appearance='ghost'
            size='small'
          >
            <Text category='c1'>{emoji}</Text>
          </Button>
        ))}
      </View>
    </Popover>
  )
}
