import { useApi } from './api'

export function useGenerateMapFile (organizationId) {
  const send = useApi()
  const path = `v1/organizations/${organizationId}/maps`

  return location => {
    return send('POST', path, { location })
  }
}
