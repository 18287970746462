import ScreenContent from 'app/components/layout/ScreenContent'
import { useParams } from 'react-router'
import ScrollContent from 'app/components/layout/ScrollContent'
import ProfileCard from './profile/ProfileCard'
import Loader from 'app/components/Loader'

export default function MemberScreen () {
  const { organizationId, userId, '*': section } = useParams()

  if (!organizationId || !userId) {
    return <Loader/>
  }

  return (
    <ScreenContent
      title='Members'
      description='Edit member info'
    >
      <ScrollContent>
        <ProfileCard
          organizationId={organizationId}
          userId={userId}
          section={section}
        />
      </ScrollContent>
    </ScreenContent>
  )
}
