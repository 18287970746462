import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS
} from '@lexical/markdown'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { Text } from '@ui-kitten/components'
import EditorTextMenuPlugin from './EditorTextMenuPlugin'
import { StyleSheet, View } from 'react-native'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { AutoLinkNode } from '@lexical/link'
import { PureComponent } from 'react'
import RelatedItemsAutolLinkPlugin from 'app/modules/items/related/RelatedItemsAutoLinkPlugin'
import RelatedItemsCollectPlugin from 'app/modules/items/related/RelatedItemsCollectPlugin'
import './theme.css'

const theme = {
  // Theme styling goes here
  paragraph: 'editor-paragraph',
  quote: 'editor-quote',
  heading: {
    h3: 'editor-heading-3'
  }
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    borderWidth: 0,
    borderColor: 'color-basic-400',
    paddingLeft: 0
  },
  containerDanger: {
    borderColor: 'color-danger-500'
  },
  content: {
    outlineStyle: 'none',
    fontSize: 14,
    borderRadius: 4,
    paddingLeft: 0,
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
  },
  placeholder: {
    position: 'absolute',
    fontSize: 14,
    top: 0,
    left: 0,
    userSelect: 'none',
    pointerEvents: 'none'
  }
})

export default class RichTextEditor extends PureComponent {
  constructor (props) {
    super(props)
    this.initialConfig = {
      namespace: 'MyEditor',
      theme,
      editorState: () => $convertFromMarkdownString(props.initialValue || '', TRANSFORMERS),
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.error(err)
      },
      nodes: [
        QuoteNode,
        HeadingNode,
        AutoLinkNode
      ]
    }
  }

  onChange (editorState) {
    editorState.read(() => {
      const markdown = $convertToMarkdownString(TRANSFORMERS)
      this.props.onTextChange(markdown)
    })
  }

  render () {
    const {
      placeholder,
      status,
      multiline,
      contentStyle
    } = this.props

    return (
      <View style={[styles.container, status === 'danger' && styles.containerDanger]}>
        <LexicalComposer initialConfig={this.initialConfig}>
          <RichTextPlugin
            contentEditable={<ContentEditable style={Object.assign({}, styles.content, contentStyle, multiline && { minHeight: 200 })} />}
            placeholder={<Text appearance='hint' style={styles.placeholder}>{placeholder}</Text>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin onChange={this.onChange.bind(this)} />
          <HistoryPlugin />
          <EditorTextMenuPlugin />
          <RelatedItemsAutolLinkPlugin />
          <RelatedItemsCollectPlugin />
        </LexicalComposer>
      </View>
    )
  }
}
