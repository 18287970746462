import { useState } from 'react'
import { Text, Tooltip } from '@ui-kitten/components'
import HStack from 'app/components/layout/HStack'
import { passwordValidations } from 'app/validations'
import { useFormContext } from 'react-hook-form'
import Icon from 'app/components/Icon'

const Rule = ({ isValid, description, short }) => {
  const [visible, setVisible] = useState(false)

  return (
    <Tooltip
      anchor={() =>
        <Text status={isValid ? 'success' : 'danger'} onPress={() => setVisible(true)} category='c2'>
          {short}
        </Text>
      }
      visible={visible}
      onBackdropPress={() => setVisible(false)}
    >
      {description}
    </Tooltip>
  )
}

export default function PasswordRules ({ fieldName = '' }) {
  const { watch } = useFormContext()
  const password = watch(fieldName)

  return (
    <HStack alignItems='center' gap={5}>
      <Icon name='alert-triangle-outline' fill='#8F9BB3' />
      <Rule isValid={passwordValidations.validLength(password)} short='Min 8' description='Has a minimum of 8 characters' />
      <Rule isValid={passwordValidations.hasNumber(password)} short='123' description='Has at least one number' />
      <Rule isValid={passwordValidations.hasUpperCase(password)} short='ABC' description='Has at least one upper case letter' />
      <Rule isValid={passwordValidations.hasLowerCase(password)} short='abc' description='Has at least one lower case letter' />
      <Rule isValid={passwordValidations.hasSpecialChar(password)} short='!@#' description='Has at least one special character' />
    </HStack>
  )
}
