import InfiniteList from 'app/components/InfiniteList'
import { useMemberSearch } from 'app/api/members'
import MemberResultCard from './MemberResultCard'

export default function MembersInfiniteSearch ({ organizationId, query }) {
  const { isLoading, setSize, items, mutate, size } = useMemberSearch(organizationId, query)

  return (
    <InfiniteList
      isLoading={isLoading}
      setSize={setSize}
      mutate={mutate}
      pages={items}
      size={size}
      renderItem={member => <MemberResultCard member={member} />}
    />
  )
}
