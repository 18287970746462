import { Ellipse, Path, Svg } from 'react-native-svg'

export default function Isologo ({ size = 25 }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M12.5 -6.34733e-07C10.5266 -5.3035e-07 8.58363 0.486684 6.84324 1.41694C5.10284 2.3472 3.61873 3.69233 2.52236 5.33316C1.426 6.97399 0.751211 8.85989 0.557783 10.8238C0.364355 12.7877 0.658253 14.769 1.41345 16.5922C2.16864 18.4154 3.36181 20.0242 4.88728 21.2761C6.41275 22.528 8.22342 23.3844 10.1589 23.7694C12.0944 24.1544 14.095 24.0561 15.9834 23.4833C17.8719 22.9104 19.5899 21.8807 20.9853 20.4853L15.8941 15.3941C15.3359 15.9523 14.6487 16.3642 13.8934 16.5933C13.138 16.8225 12.3378 16.8618 11.5636 16.7078C10.7894 16.5538 10.0651 16.2112 9.45491 15.7104C8.84472 15.2097 8.36745 14.5662 8.06538 13.8369C7.7633 13.1076 7.64574 12.3151 7.72311 11.5295C7.80048 10.744 8.0704 9.9896 8.50894 9.33326C8.94749 8.67693 9.54114 8.13888 10.2373 7.76678C10.9335 7.39467 11.7106 7.2 12.5 7.2L12.5 -6.34733e-07Z" fill="#223EF1" />
      <Ellipse cx="12.5001" cy="11.9996" rx="2.64" ry="2.64" transform="rotate(-90 12.5001 11.9996)" fill="#223EF1" />
    </Svg>
  )
}
