import { Button } from 'app/i18n'
import Icon from 'app/components/Icon'
import { useNavigate } from 'react-router'
import CardSingleButton from 'app/components/cards/CardSingleButton'

export default function FormFeedContent ({ item }) {
  const navigate = useNavigate()

  return (
    <CardSingleButton>
      {(!item.content.submitted || item.content.isImmutable) && (
        <Button
          onPress={() => navigate(`/org/${item.organizationId}/items/${item.itemId}`)}
          accessoryLeft={props => <Icon name='edit-outline' {...props} />}
        >
          Fill this form
        </Button>
      )}
    </CardSingleButton>
  )
}
