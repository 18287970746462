import { StyleSheet, View } from 'react-native'
import TextInput from 'app/components/forms/TextInput'
import RichTextInput from 'app/components/editor/RichTextInput'
import { useStyleSheet } from '@ui-kitten/components'

const themedStyles = StyleSheet.create({
  header: {
    flexDirection: 'column',
    gap: 1,
    marginTop: -12
  },
  titleText: {
    fontSize: 20,
    fontWeight: 'bold',
    marginHorizontal: -6,
    padding: 0,
    marginVertical: -4
  },
  title: {
    borderWidth: 0,
    marginHorizontal: -2,
    minHeight: 0
  },
  description: {
    fontSize: 14,
    borderWidth: 0,
    color: 'color-basic-700'
  }
})

export default function ItemForm () {
  const styles = useStyleSheet(themedStyles)

  return (
    <>
      <View style={styles.header}>
        <TextInput
          textStyle={styles.titleText}
          style={styles.title}
          name='title'
          placeholder='A title'
          rules={{ required: 'Title is required' }}
        />
        <RichTextInput
          name='description'
          placeholder='Write a short description'
          rules={{ required: 'Description is required' }}
          contentStyle={styles.description}
          containerStyle={styles.description}
        />
      </View>
    </>
  )
}
