import { Text, useStyleSheet } from '@ui-kitten/components'
import Avatar from 'app/components/Avatar'
import { View, StyleSheet } from 'react-native'
import CardPressable from 'app/components/cards/CardPressable'
import { useNavigate } from 'react-router'

const themedStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 18,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  info: {
    flexGrow: 1,
    gap: 4,
    flexDirection: 'column'
  }
})

export default function MemberResultCard ({ member }) {
  const styles = useStyleSheet(themedStyles)
  const navigate = useNavigate()

  return (
    <CardPressable
      onPress={() => navigate(`/members/${member.id}`)}
    >
      <View style={styles.container}>
        <Avatar source={member.user.avatar} />
        <View style={styles.info}>
          <Text category='s1' numberOfLines={1}>{member.user.name || 'Invited'}</Text>
          <Text category='c1' appearance='hint' numberOfLines={1}>{member.title || member.user.email}</Text>
        </View>
      </View>
    </CardPressable>
  )
}
