import { Text, useStyleSheet } from '@ui-kitten/components'
import { StyleSheet, View } from 'react-native'
import ModalBox from './ModalBox'

const themedStyles = StyleSheet.create({
  header: {
    flexDirection: 'column',
    gap: 5,
    marginBottom: 20,
    maxWidth: 500
  }
})

export default function Modal ({ onDismiss, title, description, children }) {
  const styles = useStyleSheet(themedStyles)

  return (
    <ModalBox visible={true} onDismiss={onDismiss}>
      <View style={styles.header}>
        <Text category='h6'>
          {title}
        </Text>
        <Text category='p1'>
          {description}
        </Text>
      </View>
      {children}
    </ModalBox >
  )
}
