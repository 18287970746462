import { Toggle } from '@ui-kitten/components'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default function ToggleInput ({ children, name, defaultValue }) {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Toggle checked={value} onChange={onChange}>
          {t(children)}
        </Toggle>
      )}
    />
  )
}
