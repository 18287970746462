import useSWR, { useSWRConfig } from 'swr'
import useSWRInfinite from 'swr/infinite'
import { useApi } from './api'
import * as Device from 'expo-device'

export const useMemberMeDevices = (organizationId) => {
  const path = `v1/organizations/${organizationId}/members/me/devices`
  const send = useApi()
  const { data: devices, ...props } = useSWR(path)

  async function create (pushNotificationToken) {
    return send('POST', path, {
      pushNotificationToken,
      name: Device.deviceName || '',
      modelName: Device.modelName,
      osName: Device.osName,
      osVersion: Device.osVersion,
      manufacturer: Device.manufacturer
    })
  }

  return {
    devices,
    create,
    ...props
  }
}

export function useMembers (organizationId, take = 20) {
  const path = `v1/organizations/${organizationId}/members`
  const { data: members, ...props } = useSWRInfinite((pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null
    return `${path}?skip=${pageIndex * take}&take=${take}`
  })

  return {
    members,
    ...props
  }
}

export function useMemberSearch (organizationId, query, take = 20) {
  const path = `v1/organizations/${organizationId}/members/search?query=${encodeURIComponent(query)}`
  const { data: items, ...props } = useSWRInfinite((pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null
    return `${path}&skip=${pageIndex * take}&take=${take}`
  })

  return {
    items,
    ...props
  }
}

export function useMemberInvite (organizationId, language) {
  const send = useApi()
  const path = `v1/organizations/${organizationId}/members?language=${language}`

  return (emails, role) => send('POST', path, { emails, role })
}

export function useMember (organizationId, userId) {
  const path = `v1/organizations/${organizationId}/members/${userId}`
  const send = useApi()
  const { data: member, mutate, ...props } = useSWR(path)

  async function update (data) {
    return mutate(send('PATCH', path, data))
  }

  return {
    member,
    update,
    ...props
  }
}

export function useMemberRemove (organizationId) {
  const path = `v1/organizations/${organizationId}/members`
  const send = useApi()
  const { mutate } = useSWRConfig()

  return userId => {
    mutate(path, async members => {
      await send('DELETE', `${path}/${userId}`)
      return members.filter(m => m.id !== userId)
    })
  }
}
