import { Text, useStyleSheet } from '@ui-kitten/components'
import { format } from 'date-fns'
import { es, enUS as en } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

const themedStyles = StyleSheet.create({
  container: {
    padding: 18,
    backgroundColor: 'color-primary-transparent-100',
    flexDirection: 'column',
    gap: 2,
    borderRadius: 4,
    width: '100%'
  },
  label: {
    color: 'color-primary-300',
    marginBottom: 4,
    textTransform: 'uppercase'
  }
})

const locales = { es, en }

export default function DateTimeBox ({ date, label }) {
  const styles = useStyleSheet(themedStyles)
  const { t, i18n } = useTranslation()

  const locale = locales[i18n.language]

  return (
    <View style={styles.container}>
      <Text category='label' style={styles.label}>{t(label)}</Text>
      <Text category='p2'>{format(date, 'EEEE', { locale })}</Text>
      <Text category='h6'>{format(date, 'LLLL do', { locale })}</Text>
      <Text category='h6'>{format(date, 'p', { locale })}</Text>
    </View>
  )
}
