import FileItemDownload from 'app/components/files/FileItemDownload'
import MemberInfo from 'app/modules/members/components/MemberInfo'
import { TableCell, TableRow } from 'app/components/Table'
import { Text } from '@ui-kitten/components'
import useLocalDateFormat from 'app/i18n/useLocalDateFormat'

export default function FormSubmissionListItem ({ submission, fields = [], organizationId }) {
  const format = useLocalDateFormat()

  return (
    <TableRow>
      <TableCell isPrimary>
        <MemberInfo user={submission.member.user} member={submission.member} size='small' />
      </TableCell>
      {fields.map(field => (
        <TableCell key={field.id}>
          {field.type === 'file' && (
            submission.fields[field.id].answer.map(file => (
              <FileItemDownload key={file.fileId} organizationId={organizationId} file={file} />
            ))
          )}
          {field.type === 'text' && (
            <Text category='c1'>{submission.fields[field.id].answer}</Text>
          )}
          {field.type === 'select' && (
            <Text category='c1'>{submission.fields[field.id].answer.join(', ')}</Text>
          )}
        </TableCell>
      ))}
      <TableCell>
        <Text category='c1' appearance='hint'>
          {format(new Date(submission.createdAt), 'EEE LLL do y')}
        </Text>
      </TableCell>
    </TableRow>
  )
}
