import { Text, useStyleSheet } from '@ui-kitten/components'
import { Platform, StyleSheet } from 'react-native'
import ReactMarkdown from 'react-markdown'
import TextLink from 'app/components/buttons/TextLink'
import { ITEM_ID_MATCHER } from 'app/validations'

const themedStyles = StyleSheet.create({
  strong: {
    fontWeight: 'bold'
  },
  em: {
    fontStyle: 'italic'
  },
  p: {
    marginBottom: 10,
    display: Platform.OS === 'web' ? 'block' : undefined
  },
  blockquote: {
    backgroundColor: 'color-basic-200'
  },
  h3: {
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 10,
    marginTop: 10,
    display: Platform.OS === 'web' ? 'block' : undefined
  }
})

export default function MarkdownText ({ text, organizationId, category }) {
  const styles = useStyleSheet(themedStyles)

  return (
    <ReactMarkdown
      allowedElements={['p', 'strong', 'em', 'br', 'blockquote', 'h3', 'a']}
      components={{
        p: ({ node, ...props }) => <Text {...props} style={styles.p} category={category} />,
        strong: ({ node, ...props }) => <Text {...props} style={styles.strong} />,
        em: ({ node, ...props }) => <Text {...props} style={styles.em} />,
        br: ({ node, ...props }) => <Text {...props} />,
        blockquote: ({ node, ...props }) => <Text {...props} style={styles.blockquote} />,
        h3: ({ node, ...props }) => <Text {...props} style={styles.h3} />,
        a: ({ node, href, children, ...props }) => {
          const itemMatch = href.match(ITEM_ID_MATCHER)
          if (itemMatch) {
            return (
              <TextLink
                to={`/org/${organizationId}/items/${itemMatch[2]}`}
                category={category}
              >
                {children[0]}
              </TextLink>
            )
          }
          return <Text {...props} style={styles.a}>{children}</Text>
        }
      }}
    >
      {text}
    </ReactMarkdown>
  )
}
