import PasswordInput from 'app/components/forms/PasswordInput'
import FormItem from 'app/components/forms/FormItem'
import PasswordRules from 'app/components/forms/PasswordRules'
import Stack from 'app/components/layout/Stack'

export default function PasswordSetup () {
  return (
    <>
      <Stack gap={7}>
        <FormItem label='New password'>
          <PasswordInput
            placeholder='Enter password'
            name='password'
          />
        </FormItem>
        <PasswordRules fieldName='password' />
      </Stack>
      <FormItem label='Password confirmation'>
        <PasswordInput
          placeholder='Confirm password'
          name='passwordConfirmation'
          compareTo='password'
        />
      </FormItem>
    </>
  )
}
