import { FormProvider, useForm } from 'react-hook-form'

export default function Form ({ defaultValues, children }) {
  const methods = useForm({ defaultValues })

  return (
    <FormProvider {...methods}>
      {children}
    </FormProvider>
  )
}
