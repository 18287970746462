import { useItem } from 'app/api/items'
import PollResults from './PollResults'

export default function PollVoteResults ({ organizationId, itemId }) {
  const { item } = useItem(organizationId, itemId)

  if (!item) return null

  return <PollResults poll={item.content} />
}
