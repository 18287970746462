import ScreenContent from 'app/components/layout/ScreenContent'
import { useParams } from 'react-router'
import ScrollContent from 'app/components/layout/ScrollContent'
import CardFrame from 'app/components/cards/CardFrame'
import OrganizationSetupForm from './OrganizationSetupForm'
import OrganizationPlanUsage from './OrganizationPlanUsage'
import { Divider } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'

export default function OrganizationSetupScreen () {
  const { organizationId } = useParams()

  return (
    <ScreenContent
      title='Organization'
      description='Setup your organization'
    >
      <ScrollContent>
        <CardFrame>
          <Stack gap={24}>
            <OrganizationSetupForm organizationId={organizationId} />
              <Divider />
              <OrganizationPlanUsage organizationId={organizationId} />
          </Stack>
        </CardFrame>
      </ScrollContent>
    </ScreenContent>
  )
}
