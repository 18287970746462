import useSWR from 'swr'
import { useApi } from './api'

export function useOrganizations (take = 10, skip = 0) {
  const path = 'v1/organizations'
  const { data: organizations, ...props } = useSWR(path + `?take=${take}&skip=${skip}`)

  return {
    organizations,
    ...props
  }
}

export function useCreateOrganizations () {
  const path = 'v1/organizations'
  const send = useApi()

  return async (data) => send('POST', path, data)
}

export function useOrganization (id) {
  const path = 'v1/organizations'
  const send = useApi()
  const { data: organization, mutate, ...props } = useSWR(`${path}/${id}`)

  async function update (data) {
    await mutate(send('PATCH', `${path}/${id}`, data))
  }

  async function remove () {
    return send('DELETE', `${path}/${id}`)
  }

  return {
    organization,
    update,
    remove,
    ...props
  }
}
