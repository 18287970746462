import { useRef, useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'
import { useStyleSheet } from '@ui-kitten/components'
import * as DocumentPicker from 'expo-document-picker'

const themedStyles = StyleSheet.create({
  fileUploadArea: {
    flexGrow: 1
  },
  fileUploadAreaDragOver: {
    backgroundColor: 'color-basic-300'
  }
})

export default function FilesPicker ({ onFilesSelected, disabled, children, style, type }) {
  const styles = useStyleSheet(themedStyles)
  const dropTargetRef = useRef(null)
  const [isDragOver, setIsDragOver] = useState(false)

  const handleDrop = async (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length > 0) {
      await onFilesSelected(files)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    setIsDragOver(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setIsDragOver(false)
  }

  const handlePress = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      multiple: true,
      type
    })
    result.output && await onFilesSelected(result.output)
  }

  if (disabled) {
    return children
  }

  return (
    <div
      style={styles.fileUploadArea}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      ref={dropTargetRef}
    >
      <Pressable
        onPress={handlePress}
        style={[style, isDragOver && styles.fileUploadAreaDragOver]}
      >
        {children}
      </Pressable>
    </div>
  )
}
