import { Text } from '@ui-kitten/components'
import Stack from 'app/components/layout/Stack'
import ProfileSetupForm from 'app/modules/authentication/ProfileSetupForm'
import { useTranslation } from 'react-i18next'

export default function ProfileSetupScreen () {
  const { t } = useTranslation()

  return (
    <Stack gap={30}>
      <Text category='h2'>{t('Profile setup')}</Text>
      <Text>{t('Before we begin, let\'s set up your profile')}</Text>
      <ProfileSetupForm/>
    </Stack>
  )
}
