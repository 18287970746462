import { StyleSheet } from 'react-native'
import { View } from 'react-native-web'
import FadeTop from './FadeTop'
import { useIsMobile } from './mediaQueries'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flexGrow: 1,
    maxHeight: '100%',
    maxWidth: 720
  },
  content: {
    paddingTop: 16,
    flexGrow: 1,
    overflow: 'scroll',
    maxHeight: '100%',
    paddingBottom: 16
  },
  contentWithFade: {
    paddingTop: 35
  },
  contentMobile: {
    paddingHorizontal: 16
  }
})

export default function ScrollContent ({ children, fadeTop = true }) {
  const isMobile = useIsMobile()

  return (
    <View style={styles.container}>
      {fadeTop && <FadeTop />}
      <View style={[styles.content, isMobile && styles.contentMobile, fadeTop && styles.contentWithFade]}>
        {children}
      </View>
    </View>
  )
}
