import React from 'react'
import { Text } from '@ui-kitten/components'
import { readingTime } from 'reading-time-estimator'
import CardImage from 'app/components/cards/CardImage'
import useLocalDateFormat from 'app/i18n/useLocalDateFormat'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

const styles = StyleSheet.create({
  date: {
    textTransform: 'capitalize'
  },
  bottomLine: {
    flexDirection: 'row',
    gap: 4
  },
  text: {
    flexGrow: 1
  }
})

export default function ArticleCardContent ({ item }) {
  const format = useLocalDateFormat()
  const { i18n } = useTranslation()
  const estimatedReadingTime = readingTime(item.content.text, 10, i18n.language)
  const date = new Date(item.createdAt)
  const { t } = useTranslation()

  return (
    <CardImage item={item}>
      <Text category="c1" appearance='hint'>{t('Created by')} {item.createdBy.user.name} {item.createdBy.user.surname}</Text>
      <View style={styles.text}>
        <Text numberOfLines={7} category='p1'>{item.content.text} </Text>
      </View>
      <View style={styles.bottomLine}>
        <Text category="c1" appearance='hint' style={styles.date}>
          {format(date, 'MMM do')} -
        </Text>
        <Text category="c1" appearance='hint'>
          {estimatedReadingTime.text}
        </Text>
      </View>
    </CardImage>
  )
}
