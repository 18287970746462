import { useItems } from 'app/api/items'
import RelatedItemCard from './RelatedItemCard'
import Loader from 'app/components/Loader'

export default function RelatedList ({ organizationId, filter, title, EmptyPlaceholder = null }) {
  const { items } = useItems(organizationId, filter)

  if (!items) {
    return <Loader />
  }

  if (items.length === 0) {
    return EmptyPlaceholder
  }

  return items.map(item => <RelatedItemCard key={item.id} item={item} />)
}
