import { Text } from '@ui-kitten/components'
import { useContext, useState } from 'react'
import { changePassword } from 'app/api/users'
import Form from 'app/components/forms/Form'
import ButtonSubmit from 'app/components/forms/ButtonSubmit'
import Alert from 'app/components/forms/Alert'
import PinInputField from 'app/components/forms/PinInputField'
import PasswordSetupForm from 'app/modules/authentication/PasswordSetUpForm'
import FormItem from 'app/components/forms/FormItem'
import HStack from 'app/components/layout/HStack'
import Icon from 'app/components/Icon'
import { logIn } from 'app/api/oauth-client'
import { oauthClientId } from 'app/config'
import { useNavigate } from 'react-router-native'
import { SessionContext } from 'app/components/SessionContext'
import { useTranslation } from 'react-i18next'

export default function ChangePasswordForm ({ email, code = '', submitLabel }) {
  const [hasError, setHasError] = useState(false)
  const navigate = useNavigate()
  const { saveToken } = useContext(SessionContext)
  const { t } = useTranslation()

  const onSubmit = async ({ code, password }) => {
    try {
      await changePassword(email, code, password)
      const token = await logIn(email, password, oauthClientId)
      await saveToken(token)
      navigate('/select-organization')
    } catch (err) {
      if (err.status === 412) {
        setHasError(err.message)
      } else throw err
    }
  }

  return (
    <Form defaultValues={{ code: (code || '').padEnd(6, ' '), password: '' }}>
      {hasError && (
        <Alert type='danger'>{hasError}</Alert>
      )}
      {!code && (
        <FormItem label='Verification code'>
          <PinInputField name='code' rules={{ required: true }} />
          <HStack gap={5}>
            <Icon name='info-outline' fill='#8F9BB3' />
            <Text appearance='hint' category='c2'>{t('Check your email for the code')}</Text>
          </HStack>
        </FormItem>
      )}
      <PasswordSetupForm />
      <ButtonSubmit onSubmit={onSubmit}>
        {submitLabel}
      </ButtonSubmit>
    </Form >
  )
}
