import { CheckBox } from '@ui-kitten/components'
import { Controller, useFormContext } from 'react-hook-form'

export default function CheckInput ({ name, label, defaultValue, rules, ...props }) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error }
      }) => (
        <CheckBox
          checked={value === label}
          onChange={checked => {
            onChange(checked ? label : null)
          }}
          status={error ? 'danger' : 'basic'}
          {...props}
        >
          {label}
        </CheckBox>
      )}
    />
  )
}
