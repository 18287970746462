import { ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg'

export default function FormatItalicIcon ({ size = 24, color = '#fff' }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <G clipPath="url(#clip0_1397_10521)">
        <Path d="M10 4V7H12.21L8.79 15H6V18H14V15H11.79L15.21 7H18V4H10Z" fill={color} />
      </G>
      <Defs>
        <ClipPath id="clip0_1397_10521">
          <Rect width="24" height="24" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>

  )
}
