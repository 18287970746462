import { Button } from '@ui-kitten/components'
import HStack from 'app/components/layout/HStack'
import { useSectionContext } from './EditableSection'
import Icon from 'app/components/Icon'
import ButtonSubmit from './ButtonSubmit'
import { useIsMobile } from 'app/components/layout/mediaQueries'
import { useTranslation } from 'react-i18next'

export default function EditableSectionButtons ({ onSave }) {
  const { isEditing, setIsEditing } = useSectionContext()
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  const size = isMobile ? 'tiny' : 'small'

  const handleSave = (values) => {
    setIsEditing(false)
    return onSave(values)
  }

  return (
    <HStack alignItems='center'>
      {isEditing
        ? (
          <>
            <ButtonSubmit
              appearance='filled'
              status='primary'
              size={size}
              onSubmit={handleSave}
              accessoryRight={props => <Icon name='checkmark-outline' {...props} />}
            >
              {!isMobile && t('Save')}
            </ButtonSubmit>
            {!isMobile && (
              <Button
                appearance='outline'
                status='basic'
                size={size}
                onPress={() => setIsEditing(false)}
                accessoryRight={props => <Icon name='close-outline' {...props} />}
              >
                {!isMobile && t('Cancel')}
              </Button>
            )}
          </>
          )
        : (
          <Button
            appearance='outline'
            status='basic'
            size={size}
            onPress={() => setIsEditing(true)}
            accessoryRight={props => <Icon name='edit-outline' {...props} />}
          >
            {!isMobile && t('Edit')}
          </Button>
          )}
    </HStack>
  )
}
