export const items = [{
  name: 'Articles',
  icon: 'file-text-outline',
  path: 'articles'
}, {
  name: 'Polls',
  icon: 'pie-chart-outline',
  path: 'polls'
}, {
  name: 'Forms',
  icon: 'checkmark-square-2-outline',
  path: 'forms'
}, {
  name: 'Files',
  icon: 'file-outline',
  path: 'files'
}, {
  name: 'Events',
  icon: 'calendar-outline',
  path: 'events'
}]

// {
//   name: 'Members',
//   icon: 'people-outline',
//   path: 'members'
// }]
