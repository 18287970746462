import RichTextInput from 'app/components/editor/RichTextInput'
import CoverUpload from 'app/modules/items/components/CoverUpload'
import ItemForm from './ItemForm'
import CardButtons from 'app/components/cards/CardButtons'
import ButtonSubmit from 'app/components/forms/ButtonSubmit'

export default function ArticleForm ({ onSubmit, organizationId, submitLabel = 'Publish' }) {
  return (
    <>
      <CoverUpload organizationId={organizationId} />
      <ItemForm />
      <RichTextInput
        name='content.text'
        placeholder='And your content goes here'
        rules={{ required: 'Content is required' }}
        multiline={true}
      />
      <CardButtons>
        <ButtonSubmit status='primary' onSubmit={onSubmit}>
          {submitLabel}
        </ButtonSubmit>
      </CardButtons>
    </>
  )
}
