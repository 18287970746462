import { isAfter, subMinutes } from 'date-fns'
import { useSession } from 'app/components/SessionContext'
import { ApiError, checkApiResponseErrors } from './errors'
import { apiUrl } from 'app/config'

let lastTokenRefresh = null

export function useApi () {
  const { logOut, getToken, refreshToken } = useSession()

  return async function send (method, path, body) {
    const { accessTokenExpiresAt } = await getToken()
    const inFiveMinutes = subMinutes(new Date(), 10)

    if (isAfter(inFiveMinutes, new Date(accessTokenExpiresAt)) && lastTokenRefresh === null) {
      lastTokenRefresh = refreshToken()
      await lastTokenRefresh
    } else if (lastTokenRefresh) {
      await lastTokenRefresh
    }

    const { accessToken } = await getToken()
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }

    if (body instanceof FormData) {
      delete headers['Content-Type']
    } else {
      body = JSON.stringify(body)
    }

    const response = await fetch(`${apiUrl}/api/${path}`, {
      method,
      body,
      credentials: 'include',
      headers
    })

    try {
      await checkApiResponseErrors(response)
    } catch (err) {
      if (err instanceof ApiError && err.status === 401) {
        await logOut()
      } else throw err
    }

    const contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json()
    } else {
      return response
    }
  }
}

export async function sendWithoutAuthorization (method, path, data) {
  const response = await fetch(`${apiUrl}/api/${path}`, {
    method,
    body: data && JSON.stringify(data),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  await checkApiResponseErrors(response)

  return response.json()
}
