import MessageCard from 'app/components/cards/MessageCard'
import Stack from 'app/components/layout/Stack'
import FormSubmit from './FormSubmit'
import FormSubmitted from './FormSubmitted'

export default function FormContent ({ item }) {
  if (item.content.submitted && !item.content.isImmutable) {
    return (
      <Stack gap={16}>
        <MessageCard
          title='Form submitted'
          description='You have already submitted this form. You can update your answers below.'
          type='info'
        />
        <FormSubmitted item={item} />
      </Stack>
    )
  } else if (item.content.submitted && item.content.isImmutable) {
    return (
      <Stack gap={16}>
        <MessageCard
          title='Form submitted'
          description='You have already submitted this form. You can still make a new submission.'
          type='info'
        />
        <FormSubmit item={item} />
      </Stack>
    )
  }

  return (
    <FormSubmit item={item} />
  )
}
